.Map-col {
  @include media-breakpoint-up(sm) {
    @include make-col(10);
    margin-left: auto;
  }

}

.Map-wrapper {
  position: relative;
  overflow: hidden;
  padding-bottom: 9/16 * 100%;
  background-color: $color-background;
}

.Map-area {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
