.Prose {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: $paragraph-margin-bottom;
  }

  p, ul {
    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: 3rem;
    }
  }

  h2 {
    @include ms-respond(font-size, 32); // 44px
  }

  h3 {
    @include ms-respond(font-size, 27.5); // 38px
  }

  h4 {
    @include ms-respond(font-size, 22); // 32px
  }

  h5 {
    @include ms-respond(font-size, 15.5); // 26px
    line-height: $headings-line-height * 1.1;
  }

  h6 {
    @include ms-respond(font-size, 10.5); // 22px
    line-height: $headings-line-height * 1.15;
  }

  h2,
  h5 {
    font-family: $font-family-serif;
  }

  h3,
  h6 {
    font-weight: 400;
  }

  h4 {
    font-weight: 300;
  }

  // p,
  // table,
  // ul,
  // ol,
  // figure,
  // img,
  // iframe {
  //   margin-bottom: 1.2rem;
  //   @include media-breakpoint-up(sm) {
  //     margin-bottom: 1.5rem;
  //   }
  //   + h1,
  //   + h2,
  //   + h3,
  //   + h4,
  //   + h5 {
  //     @include content-spacer("margin-top");
  //   }
  // }

  // p,
  // li,
  // td,
  // th {
  //   a:not(.Button):not(.dropdown-item):not(.ArrowCta):not(.ChevronCta) {
  //     text-decoration: underline !important;
  //     &:hover {
  //       opacity: 0.7;
  //     }
  //   }
  // }

  > *:first-child {
    margin-top: 0 !important;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  table {
    width: 100% !important;
    table-layout: fixed;
    border: 0;
    border-top: 3px solid currentColor;
    border-bottom: 1px solid $table-border-color;
    color: $table-color;
    background-color: $table-bg;
    margin: 2rem 0;
    // &:first-child {
    //   border-top: none;
    //   tbody tr:first-child {
    //     td, th {
    //       padding-top: 0 !important;
    //     }
    //   }
    // }
  }

  tr {
    // display: flex;
  }

  th,
  td {
    // flex: 1 1 auto;
    padding: $table-cell-padding;
    vertical-align: top;
    border: $table-border-width solid $table-border-color;
    border-bottom: 0;
    border-right: 0;
    width: 50%;
    &:first-child {
      padding-left: 0 !important;
      border-left: 0;
      width: 25%;
      @include media-breakpoint-up(sm) {
        width: 20%;
      }
      @include media-breakpoint-up(md) {
        width: 18%;
      }
    }
    &:last-child {
      padding-right: 0 !important;
    }
    > *:last-child {
      margin-bottom: 0;
    }
    // ul:last-child, p:last-child {
    //   margin-bottom: 0;
    // }
  }

  thead th {
    vertical-align: bottom;
  }

  table.table-large {
    td, th {
      padding: 1rem;
      @include media-breakpoint-up(sm) {
        padding: 1.5rem;
      }
    }
  }

  table.table-collapse {
    @include media-breakpoint-down(xs) {
      display: block;
      tbody, tr, td, th {
        display: block;
      }
      td {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        &:not(:first-child) {
          padding-top: 0;
        }
        &:not(:last-child) {
          // padding-bottom: 1rem;
          border: none;
        }
      }
    }
  }

  img {
    display: block;
    max-width: 100%;
    height: auto !important;
    &.img-w100 {
      width: 100% !important;
    }
  }

  figcaption {
    @include media-breakpoint-up(sm) {
      width: 50%;
    }
  }

  > iframe {
    margin: 1.5rem 0;
  }

  blockquote {
    $quote-size: 1em;
    margin: 2rem 0;
    &:before {
      content: '';
      display: inline-block;
      background-image: url(svg("quote", "fill: #{$color-text};"));
      width: $quote-size;
      height: $quote-size;
      background-size: contain;
      background-repeat: no-repeat;
      margin-bottom: 1rem;
    }
    .Signature {
      // text-align: right;
      flex-direction: row-reverse;
      &:before {
        margin-right: 0;
        margin-left: 0.5em;
      }
    }
    p:not(.Signature):not(.QuoteBig) {
      @include ms-respond(font-size, 10.5); // 22px
      font-weight: 700;
      line-height: $headings-line-height;
    }
    > * {
      margin-bottom: 0;
    }
    p:not(.Signature):not(:last-child) {
      margin-bottom: 1rem;
    }
  }

}

.Prose--indent {
  @include media-breakpoint-up(md) {
    > *:not(table):not(hr) {
      margin-right: 26%;
    }
  }
}
