.FeaturedEvents-wrapper {
  @include reveal();
}

.FeaturedEvents-item {
  &[data-text-inverted] {
    color: $color-text-inverted;
  }
}

.FeaturedEvents-inner {
  // text-decoration: none !important;
  // color: currentColor !important;
  @include media-breakpoint-up(sm) {
    display: flex;
  }
  @include media-breakpoint-up(sm) {
    min-height: 50vw;
  }
}

.FeaturedEvents-media {
  padding-bottom: 100%;
  position: relative;
  background-color: $color-placeholder;
  overflow: hidden;
  @include media-breakpoint-up(sm) {
    flex: 0 0 50%;
    padding-bottom: 0;
    .FeaturedEvents-item:nth-child(even) & {
      order: 2;
      .FeaturedEvents--invertOrder & {
        order: 0;
      }
    }
    .FeaturedEvents-item:nth-child(odd) & {
      .FeaturedEvents--invertOrder & {
        order: 2;
      }
    }
  }
}

.FeaturedEvents-figure {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.FeaturedEvents-image {
  @include lazyloaded();
  @include hover-zoom(false);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: center/cover no-repeat;
}

.FeaturedEvents-content {
  padding-top: 1rem;
  padding-bottom: 1rem;
  @include media-breakpoint-up(sm) {
    flex: 0 0 50%;
    display: flex;
    padding: map_get($grid-container-paddings, sm);
  }
  @include media-breakpoint-up(md) {
    // padding: 3vw;
  }
}

.FeaturedEvents-container {
  > a {
    text-decoration: none !important;
  }
  @include media-breakpoint-up(sm) {
    display: flex;
    flex-direction: column;
  }
}

.FeaturedEvents-headline {
  @include ms-respond(font-size, 32); // 44px
  line-height: $headings-line-height;
  text-decoration: none !important;
  margin-bottom: 1rem;
  > * {
    font-size: inherit;
    line-height: inherit;
    display: inline;
    font-weight: 400;
    margin: 0;
  }
}

.FeaturedEvents-label {
  @include font-size-small();
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 0.25em;
  padding-right: 1.5em;
}

.FeaturedEvents-subtitle {
  font-style: italic;
  &:before {
    content: '- ';
  }
}

.FeaturedEvents-ctas {
  margin-top: 2rem;
  display: flex;
  .Link {
    font-weight: 700;
  }
  > *:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.FeaturedEvents-date {
  @include res-prop("padding-top", $grid-gutter-widths, 2);
  margin-top: auto;
}
