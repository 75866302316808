// form-field it's a custom style in bootstrap fashion to make label absolutes
.form-field {
  position: relative;

  [required="required"] ~ label {
    &:after {
      content: ' *';
      opacity: 0.5;
    }
  }

  label {
    position: absolute;
    left: $input-padding-x;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    transition: transform 0.1s;
    transform-origin: 0 0;
    margin: 0;
    pointer-events: none;
  }

  textarea ~ label {
    top: $input-padding-y * 2.2;
  }

  input[type="text"],
  input[type="email"],
  select,
  textarea {
    &.has-focus,
    &.has-value {
       ~ label {
        transform: translateY(-75%) scale(0.75);
      }
    }
  }
}

.form-control {
  padding-top: $input-label-required-space + $input-padding-y;
  height: add($input-line-height * 1em, add(add($input-padding-y * 2, $input-label-required-space, false), $input-height-border, false));
  &:focus {
    border-bottom: 1px solid color("text");
  }
  // &.has-validate:invalid {
  //   border-color: #e8c1c1;
  //   border-bottom-color: #b76767;
  // }
}

select.form-control {
  padding-left: 0.45rem;
}

textarea.form-control {
  resize: none;
}
