.Main {
  overflow: hidden;
  pointer-events: none;
  [data-scroll-speed] {
    @include media-breakpoint-down(sm) {
      transform: none !important;
    }
    .has-scroll-smooth & {
      will-change: transform, opacity;
    }
  }
}

.Main-container {
  pointer-events: auto;
  background-color: $color-background;
  [data-scroll-section] {
    background-color: inherit;
  }
}

.Main-footer {
}

.Main-container + .Main-container {
  display: none;
}
