.Newsletter {
  @include section-spacer(("padding-top","padding-bottom"), 0.75);
  background-color: #efefef !important;
  .row {
    align-items: center;
    justify-content: space-between;
  }
  .alert {
    margin-bottom: 0 !important;
  }
  .alert + .Newsletter-formGroup {
    margin-top: 1rem;
  }
}

.Newsletter-headlineCol,
.Newsletter-formCol {
  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(5);
  }
}

.Newsletter-headlineCol {
  @include media-breakpoint-down(xs) {
    margin-bottom: 1rem;
  }
}

.Newsletter-title {
  @include ms-respond(font-size, 24);
  margin: 0;
  font-family: $font-family-serif;
}

.Newsletter-subtitle {
  @include ms-respond(font-size, 16);
  text-transform: uppercase;
  margin: 0;
  font-weight: 300;
}

.Newsletter-formGroup {
  display: flex;
  .form-field {
    flex-grow: 1;
  }
  .Button {
    height: auto;
    @include media-breakpoint-down(sm) {
      min-width: 0;
    }
  }
}

.Newsletter-note {
  margin: 0.5rem 0 0;
  font-size: calc(1rem - 3px);
  opacity: 0.5;
  a {
    text-decoration: underline;
  }
}
