.Copyright {
  line-height: 1.2 !important;
  font-size: 12px !important;
  @include media-breakpoint-up(sm) {
    font-size: 14px !important;
  }
  .fancybox-caption & {
    color: currentColor;
    opacity: 0.7;
  }
}

.Copyright--tl,
.Copyright--tr,
.Copyright--br,
.Copyright--bl {
  position: absolute;
  z-index: 10;
  margin: 0;
  opacity: 0.5;
  max-width: 30em;
}

.Copyright--tl {
  transform: rotate(90deg) translate(0, -100%);
  transform-origin: 0 0;
  top: 1rem;
  left: 1rem;
}

.Copyright--tr {
  transform: rotate(-90deg) translate(0, -100%);
  transform-origin: 100% 0;
  top: 1rem;
  right: 1rem;
  text-align: right;
}

.Copyright--br {
  bottom: 1rem;
  right: 1rem;
  text-align: right;
}

.Copyright--bl {
  bottom: 1rem;
  left: 1rem;
}

.Copyright--gray {
  color: tint(#000, 50%);
}
.Copyright--white {
  color: color('white');
}
.Copyright--black {
  color: color('black');
}
