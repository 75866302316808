.Story {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: $zindex-story;
  overflow: hidden;
  &[data-text-inverted] {
    color: $color-text-inverted;
  }
  [data-scroll-container] & {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    &.is-ready {
      pointer-events: auto;
    }
  }
  .swiper-container {
    overflow: visible;
  }
  .swiper-pagination {
    bottom: -1rem;
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
  .swiper-pagination-bullet {
    background: currentColor !important;
  }
}

.Story-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transform: scaleY(0);
  transform-origin: 0 0;
  transition: transform 0.8s cubic-bezier("easeInOutQuart");
  .Story.is-ready & {
    transform: scaleY(1);
  }
}

.Story-header {
  @include res-prop("height", $site-header-heights);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  opacity: 0;
  .Story.is-ready & {
    opacity: 1;
    transition: opacity 0.3s;
    transition-delay: 0.4s;
  }
}

.Story-title {
  margin: 0 0 0 0.75rem;
  font-weight: 400;
  font-size: rem(20px);
}

.Story-close {
  width: 2rem;
  height: 2rem;
  padding: 0;
  font-size: 0;
  color: currentColor;
  background: none;
  apparance: none;
  border: none;
  margin-left: auto;
  position: relative;
  .Icon {
    $scale: 1.5;
    width: 1rem * $scale;
    height: 1rem * $scale;
    stroke-width: $icon-stroke-width * 1 / $scale;
  }
}

.Story-gallery {
  width: 100%;
  position: relative;
  z-index: 3;
  opacity: 0;
  transform: translateX(3rem);
  .Story.is-ready & {
    opacity: 1;
    transition: opacity 0.3s, transform 0.4s;
    transform: none;
    transition-delay: 0.5s;
  }
}

.Story-slide {
  width: auto;
  @include res-prop(("padding-left", "padding-right"), $grid-gutter-widths, (xs: 0.5, sm: 1));
}

.Story-content {
  position: relative;
  max-width: 85vw;
  opacity: 0.5;
  transition: opacity 0.3s, transform 0.6s;
  padding-top: 1rem;
  height: calc(100vh - #{map_get($site-header-heights, xs) * 2});
  @include media-breakpoint-up(sm) {
    height: calc(100vh - #{map_get($site-header-heights, sm) * 1.5});
  }
  .swiper-slide-active & {
    opacity: 1;
    transform: none !important;
  }
  .desktop & {
    transform: scale(0.9);
  }
}

.Story-placeholder {
  height: 100%;
  max-width: none;
  width: auto;
}

.Story-image {
  position: absolute;
  background: center/contain no-repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Story-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Story-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  apparance: none;
  background: none;
  border: none;
  padding: 0;
  color: #fff;
  .Icon {
    width: 4rem;
    height: 4rem;
  }
  .swiper-slide-active & {
    display: block;
  }
  .is-playing + & {
    // display: none;
  }
}

.Story-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  // pointer-events: none;
  transform: translate(-50%, -50%) rotate(-90deg);
  fill: none;
  width: 3rem;
  height: 3rem;
  @include media-breakpoint-down(xs) {
    transform: translate(-50%, -50%) rotate(-90deg) scale(0.8);
  }
  circle {
    transition: stroke-dashoffset 0.35s;
    stroke-width: 3px;
    stroke: currentColor;
    opacity: 0.3;
  }
}
