.BannerText-item {
  @include make-col-ready();
  @include make-col(12);
  margin-bottom: 2rem;
  > * {
    vertical-align: middle;
    display: inline-block;
  }

  @include media-breakpoint-up(sm) {
    @include make-col(11);
    &:nth-child(even) {
      @include make-col-offset(1);
    }
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 3rem;
    @include make-col(10);
    @include make-col-offset(1);
    &:nth-child(even) {
      @include make-col-offset(2);
    }
  }
}

.BannerText-caption {
  @include section-spacer("margin-bottom", 0.5);
  @include media-breakpoint-down(xs) {
    margin-bottom: 2rem;
  }
}

.BannerText-text {
  @include ms-respond(font-size, 45);
  font-family: $font-family-serif;
  font-weight: 900;
  margin-bottom: 0.5rem;
  margin-right: 1.5rem;
  @include media-breakpoint-down(xs) {
    margin-right: 2rem;
  }
  a {
    text-decoration: none !important;
    position: relative;
    &:after {
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      bottom: 0.45em;
      transform: translateY(25%);
      content: '';
      width: 1rem;
      height: 1rem;
      margin-left: 1.2rem;
      background-color: transparent;
      background-image: url(svg("arrow", "fill: none; stroke: #{$color-text}; stroke-width: 1.3px"));
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    @include media-breakpoint-up(sm) {
      pointer-events: none;
      &:after {
        display: none;
      }
    }
  }
}

.BannerText-linkWrapper {
  @include ms-respond(font-size, 45);
  line-height: 0.5;
  padding-bottom: 0.35em;
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.BannerText-link {
  flex-shrink: 0;
  // .BannerText-item:nth-child(even) & {
  //   margin-left: 1.5rem;
  // }
}
