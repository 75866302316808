.SiteFooter {
  @include section-spacer("margin-top");
  ul {
    list-style-type: none;
    margin-left: 0;
    padding: 0;
    display: flex;
  }
  a {
    color: currentColor;
  }
}

.SiteFooter-content {
  padding: 2rem 0 0.5rem;
  border-top: 1px solid currentColor;
  @include media-breakpoint-up(sm) {
    display: flex;
  }
}

.SiteFooter-colophon {
  display: inline-block;
  white-space: no-wrap;
  @include media-breakpoint-up(sm) {
    margin-right: 3rem;
  }
}


.SiteFooter-lists {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  @include media-breakpoint-down(xs) {
    flex-direction: column;
  }
}

.SiteFooter-social {
  @include media-breakpoint-up(sm) {
    margin-right: 3rem;
  }
}

.SiteFooter-social {
  white-space: nowrap;
  li:not(:first-child):before {
    content: "-";
    padding-left: 0.3em;
  }
}

.SiteFooter-languages {
  li.is-active {
    text-decoration: underline;
  }
  li:not(.is-active) {
    color: rgba(#000, 0.6);
  }
  li:not(:first-child) {
    margin-left: 0.5rem;
  }
}
