.Status {
  @include font-size-small();
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  padding: 0.2em 0.5rem 0.3em;
  background-color: $color-text;
  color: $color-text-inverted;
  .Icon {
    display: inline-block;
    margin-right: 0.2rem;
    position: relative;
    top: -0.075rem;
  }
}

.Status--error {
  background-color: $color-error;
}
