.HpHeader {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  &[data-text-inverted] {
    color: $color-text-inverted;
  }
}

.HpHeader-media,
.HpHeader-wrapperMedia,
.HpHeader-wrapperMedia:after,
.HpHeader-image,
.HpHeader-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.HpHeader-media {
  background-color: $color-placeholder;
  display: block;
  overflow: hidden;
}

.HpHeader-wrapperMedia {
  @include lazyloaded-wrapper();
  &:after {
    content: '';
    background-color: #000;
    opacity: 0.2;
  }
}

.HpHeader-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.HpHeader-video {
  &:not(.lazyloaded) {
    opacity: 0;
  }
  video {
    position: absolute;
  }
}

.HpHeader-wrapper {
  @include res-prop("border-top-width", $site-header-heights);
  @include section-spacer(("padding-top","padding-bottom"), 0.5);
  border-top-color: transparent;
  border-top-style: solid;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(var(--vh) * 100);
  @include media-breakpoint-down(xs) {
    justify-content: flex-end;
    padding-bottom: map_get($grid-container-paddings, xs) * 3;
  }
}

.HpHeader-container {
  @include make-container();
}

.HpHeader-titleCol {
  @include make-col(11);
  @include media-breakpoint-up(sm) {
    @include make-col(9);
    @include make-col-offset(1);
    @include section-spacer("margin-bottom", 0.5);
  }
}

.HpHeader-ctaCol {
  @include reveal();
  transition-delay: 0.25s !important;
  @include media-breakpoint-up(sm) {
    @include make-col(4);
    @include make-col-offset(2);
  }
  @include media-breakpoint-up(md) {
    @include make-col(3);
    @include make-col-offset(3);
  }
}

.HpHeader-textCol {
  @include reveal();
  transition-delay: 0.4s !important;
  @include media-breakpoint-down(xs) {
    display: none;
  }
  @include media-breakpoint-up(sm) {
    @include make-col(4);
  }
  @include media-breakpoint-up(md) {
    @include make-col(5);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(4);
  }
  // if cta is missing
  .HpHeader-titleCol + & {
    transition-delay: 0.25s !important;
    @include media-breakpoint-up(sm) {
      @include make-col-offset(2);
    }
    @include media-breakpoint-up(md) {
      @include make-col-offset(3);
    }
  }
}

.HpHeader-title {
  @include ms-respond(font-size, 60);
  @include reveal();
  @include reveal('', '> *');
  font-family: $font-family-serif;
  font-weight: 900;
  margin-bottom: 2rem;
  @include reveal-from() {
    transform: none;
  }
  @include reveal-from('', '> *:nth-child(odd)') {
    transform: translateX(-2rem);
  }
  @include reveal-from('', '> *:nth-child(even)') {
    transform: translateX(2rem);
  }
  > * {
    &:nth-child(even) {
      margin-left: 9%;
      margin-right: -9%;
      @include media-breakpoint-up(sm) {
        margin-left: 11.5%;
        margin-right: -11.5%;
      }
      @include media-breakpoint-up(md) {
        margin-left: 22.5%;
        margin-right: -22.5%;
      }
    }
  }
  // padding-left: 7.8vw;
  // text-indent: -7.8vw;
  // @include media-breakpoint-up(md) {
  //   padding-left: 15.8vw;
  //   text-indent: -15.8vw;
  // }
}
