.Gallery {
  overflow: hidden;
  .swiper-container {
    overflow: visible;
  }
  .swiper-wrapper {
    align-items: center;
  }
  .swiper-slide {
    @include res-prop("padding-right", $grid-container-paddings);
    width: auto;
    flex: 0 1 0;
    &:last-child {
      padding-right: 0;
    }
  }
}

.Gallery-container {
  @include reveal();
}

.Gallery-item {
  display: block;
}

.Gallery-link {
  display: block;
  position: relative;
  background-color: $color-placeholder;
  overflow: hidden;
  img {
    @include lazyloaded();
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.Gallery-caption {
  margin-top: 1rem;
  .Copyright {
    display: none;
  }
}

.Gallery-description {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}
