.BannerHero {
  position: relative;
}

.BannerHero-banner {
  position: relative;
}

.BannerHero-content {
  @include res-prop(("padding-top", "padding-bottom"), $grid-container-paddings);
  @include res-prop(("padding-left", "padding-right"), $grid-container-paddings, 3);
  position: relative;
  min-height: 120vw;
  @include media-breakpoint-up(sm) {
    min-height: 50vw;
  }
  .BannerHero--center & {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.BannerHero-title {
  @include ms-respond(font-size, 50);
  @include res-prop("text-indent", $grid-container-paddings, -2);
  font-weight: 900;
  font-family: $font-family-serif;
  color: $color-text-inverted;
  margin-bottom: 0.5em;
}

.BannerHero-media,
.BannerHero-wrapper,
.BannerHero-wrapper:after,
.BannerHero-image,
.BannerHero-video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.BannerHero-media {
  background-color: $color-placeholder;
  overflow: hidden;
}

.BannerHero-wrapper {
  @include lazyloaded-wrapper();
  @include when-scroll-smooth() {
    top: calc(var(--vh) * -10);
    bottom: calc(var(--vh) * -10);
  }
  &:after {
    content: '';
    background-color: #000;
    opacity: 0.2;
  }
}

.BannerHero-image {
  background: center/cover no-repeat;
}

.BannerHero-video {
  &:not(.lazyloaded) {
    opacity: 0;
  }
  video {
    position: absolute;
  }
}
