.Accordion-title {
  @include reveal('.Accordion-col');
  @include ms-respond(font-size, 32); // 44px
  font-family: $font-family-serif;
  line-height: $headings-line-height;
  margin-bottom: 2rem;
}

.Accordion-col {
  margin-left: auto;
  @include media-breakpoint-up(sm) {
    @include make-col(10);
  }
  @include media-breakpoint-up(md) {
    @include make-col(8);
  }
}

.Accordion-item {
  @include reveal('.Accordion-col');
  @include transition-delay-nthchild(10, 0.05s);
  border-bottom: 1px solid $color-text;
}

.Accordion-item-toggle {
  padding: 1.25rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  .Icon {
    margin-left: 1.5rem;
  }
  &.is-open {
    .Icon {
      transform: rotate(180deg);
    }
  }
}

.Accordion-item-title {
  @include ms-respond(font-size, 15.5); // 26px
  @include hover-text('.Accordion-item-toggle:hover &');
  font-weight: 400;
  margin: 0 auto 0 0;
}

.Accordion-item-prose {
  padding-bottom: 2rem;
}
