.alert {
  color: #fff;
  font-weight: 400;
  background: $color-success;
  border: none;
  // redeclare with important because P are overwritten in .Prose
  margin-bottom: $alert-margin-bottom !important;

  &.alert-error,
  &.alert-danger {
    background: $color-error;
  }

  // because mailchimp form uses label tags
  label {
    margin: 0;
    color: inherit;
  }
}

.alert-error + .alert-error,
.alert-danger + .alert-danger,
.alert-success + .alert-success,
.alert-warning + .alert-warning {
  padding-top: 0;
  margin-top: -$alert-margin-bottom * 1.5;
}

