.TextImage {
  &:not(.TextImage--items-center) [data-scroll-speed] {
    transform: none !important;
  }
}

.TextImage-item {
  justify-content: space-between;
  .TextImage--items-center & {
    align-items: center;
  }
  &:not(:last-child) {
    @include section-spacer("margin-bottom");
  }
}

.TextImage-media {
  @include reveal();
  transition-delay: 0.05s !important;
  @include media-breakpoint-up(sm) {
    @include make-col(6);
    .TextImage:not(.TextImage--inverted) .TextImage-item:nth-child(even) & {
      order: 2;
      transition-delay: 0.3s !important;
    }
    .TextImage--inverted .TextImage-item:nth-child(odd) & {
      order: 2;
    }
  }
}

.TextImage-content {
  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }
  @include media-breakpoint-up(md) {
    @include make-col(5);
  }
}

.TextImage-figure {
  background-color: $color-placeholder;
  position: relative;
  overflow: hidden;
  @include media-breakpoint-down(xs) {
    margin-bottom: 2rem;
  }
  @include media-breakpoint-up(sm) {
    .TextImage-item:nth-child(odd) & {
      margin-right: 0;
    }
    .TextImage-item:nth-child(even) & {
      margin-left: 0;
    }
  }
}

.TextImage-image {
  @include lazyloaded();
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: center/cover no-repeat;
}

.TextImage-image-placeholder {
  visibility: hidden;
}

.TextImage-prose {
  @include reveal('.TextImage-content');
}
