.HpSlider {
  &[data-text-inverted] {
    color: $color-text-inverted;
  }
}

.HpSlider-item {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.HpSlider-media,
.HpSlider-wrapperMedia,
.HpSlider-wrapperMedia:after,
.HpSlider-image,
.HpSlider-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.HpSlider-media {
  background-color: $color-placeholder;
  overflow: hidden;
}

.HpSlider-wrapperMedia {
  @include lazyloaded-wrapper();
  &:after {
    content: '';
    background-color: #000;
    opacity: 0.2;
  }
}

.HpSlider-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.HpSlider-video {
  &:not(.lazyloaded) {
    opacity: 0;
  }
  video {
    position: absolute;
  }
}

.HpSlider-wrapper {
  @include section-spacer(("padding-top","padding-bottom"), 0.5);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(var(--vh) * 100);
}

.HpSlider-container {
  @include make-container();
}

.HpSlider-titleCol {
  @include make-col(11);
}

.HpSlider-ctaCol {
  @include reveal-from() {
    opacity: 0;
    transition: opacity 0.1s 0.8s;
  };
  @include reveal-to('.swiper-slide-active') {
    opacity: 1;
    transition: opacity 0.8s 0.2s;
  };
  @include reveal-to('.swiper-slide-duplicate-active') {
    opacity: 1;
    transition: opacity 0.8s 0.2s;
  };

}

.HpSlider-title {
  @include ms-respond(font-size, 51);
  font-family: $font-family-serif;
  font-weight: 900;
  margin: 0 0 2rem;
  @include reveal-from() {
    opacity: 0;
    transition: opacity 0.1s 0.8s;
  };
  @include reveal-to('.swiper-slide-active') {
    opacity: 1;
    transition: opacity 0.8s 0.2s;
  };
  @include reveal-to('.swiper-slide-duplicate-active') {
    opacity: 1;
    transition: opacity 0.8s 0.2s;
  };
  @include reveal-from('','> *') {
    transition: transform 0.1s cubic-bezier("easeOutQuad") .8s;
  }
  @include reveal-to('.swiper-slide-active', '> *') {
    transform: translateX(0);
    transition: transform 0.8s cubic-bezier("easeOutQuad") 0.2s;
  };
  @include reveal-to('.swiper-slide-duplicate-active', '> *') {
    transform: translateX(0);
    transition: transform 0.8s cubic-bezier("easeOutQuad") 0.2s;
  };
  @include reveal-from('', '> *:nth-child(odd)') {
    transform: translateX(-2rem);
  }
  @include reveal-from('', '> *:nth-child(even)') {
    transform: translateX(2rem);
  }
  > * {
    &:nth-child(even) {
      margin-left: 9.5%;
      margin-right: -9.5%;
    }
  }
}

.HpSlider-nav {
  @include res-prop("right", $grid-container-paddings);
  @include res-prop("bottom", $grid-container-paddings);
  position: absolute;
  z-index: 1;
  display: none;
  .has-slides & {
    display: flex;
  }
}

.HpSlider-navItem {
  width: 2rem;
  position: relative;
  margin-left: 0.5rem;
  min-height: 3px;
  cursor: pointer;
  @include media-breakpoint-up(sm) {
    width: 10rem;
    margin-left: 1rem;
  }
  &:before {
    display: block;
    content: '';
    width: 100%;
    height: 3px;
    background-color: #fff;
    opacity: 0.4;
  }
}

.HpSlider-progress {
  height: 3px;
  background-color: #fff;
  position: absolute;
  top: 0;
  width: 100%;
  transform-origin: 0 0;
  transform: scaleX(0);
}

.HpSlider-label {
  padding-top: 0.25rem;
  font-weight: 500;
  font-size: calc(1rem - 2px);
  padding-right: 20%;
    @include media-breakpoint-down(xs) {
    display: none;
  }
}

