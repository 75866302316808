.Mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-mask;
  background-color: #fff;
  pointer-events: none;
  opacity: 0;
}
