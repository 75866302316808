.Card {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Card-media {
  position: relative;
}

.Card-figure {
  position: relative;
  padding-bottom: 75%;
  background-color: $color-placeholder;
  margin-bottom: 1rem;
  overflow: hidden;
}

.Card-image {
  @include lazyloaded();
  @include hover-zoom(false);
  background: center/cover no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Card-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > *:last-child {
    margin-bottom: 0;
  }
}

.Card-label {
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
}

.Card-label--1 {
  color: $color-category-label-1;
}

.Card-label--2 {
  color: $color-category-label-2;
}

.Card-title {
  @include ms-respond(font-size, 22);
  font-weight: 400;
  margin-bottom: 1rem;

  a {
    @include hover-text('&:hover');
    text-decoration: none;
  }
}

.Card-subtitle {
  font-style: italic;
  &:before {
    content: '- ';
  }
}

.Card-description {
  line-height: 1.5;
  font-weight: bold;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}

.Card-abstract {
  line-height: 1.5;
  color: tint(#000, 60%);
  margin-bottom: 1.5rem;
}

.Card-date {
  margin-top: auto;
}
