.Date {
  display: flex;
  font-family: $font-family-serif;
  font-weight: 700;
  white-space: nowrap;
  align-items: center;
  @include ms-respond(font-size, 22);
  &:before {
    content: '';
    display: block;
    height: 0.65em;
    border-bottom: 1px solid currentColor;
    flex: 1 1 100%;
    margin-right: 0.3em;
  }
}
