.PageHeaderLanding-container {
  @include reveal();
  // clearfix
  &:after {
    content: "";
    clear: both;
    display: table;
  }
}

.PageHeaderLanding-title {
  @include ms-respond(font-size, 52); // 83px
  font-family: $font-family-serif;
  font-weight: 900;
  margin-bottom: 2rem;
  position: relative;
  z-index: 2;
  width: 91%;
  &:after {
    @include reveal('[data-scroll]');
    transform-origin: 0 0;
    content: '';
    display: block;
    height: 6px;
    margin-top: 6rem;
    background-color: currentColor;
    width: 52%;
    @include reveal-from('[data-scroll]') {
      transition-timing-function: cubic-bezier("easeOutQuart");
      transition-delay: 0.25s;
      transform: scaleX(0);
    }
    @include media-breakpoint-up(sm) {
      margin-top: 4rem;
      width: 184%;
    }
    @include media-breakpoint-up(md) {
      width: 141%;
      margin-left: 42.5%;
    }
  }
  @include media-breakpoint-up(sm) {
    float: left;
    width: 40.5%;
    // padding-right: map_get($grid-gutter-widths, sm) * 0.5;
  }
  div {
    @include reveal('[data-scroll]');
    &:first-child {
      margin-left: 10%;
      margin-right: -10%;
      @include media-breakpoint-up(sm) {
        margin-left: 15%;
        margin-right: -15%;
      }
    }
    &:nth-child(odd) {
      @include reveal-from() {
        transform: translateY(-2rem) translateX(-2rem);
      }
    }
    &:nth-child(even) {
      @include reveal-from() {
        transition-delay: 0.25s;
        transform: translateY(-2rem)  translateX(2rem);
      }
    }
  }
}

.PageHeaderLanding-media {
  margin-bottom: 2rem;
  margin-top: -6rem;
  padding-left: 8.5%;
  @include media-breakpoint-up(sm) {
    margin-top: 0;
    float: right;
    width: 50%;
    padding-left: map_get($grid-gutter-widths, sm) * 0.5;
  }
}

.PageHeaderLanding-figure {
  position: relative;
  overflow: hidden;
  background-color: $color-placeholder;
}

.PageHeaderLanding-image {
  @include lazyloaded();
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: center/cover no-repeat;
  @include when-scroll-smooth() {
    top: calc(var(--vh) * -10);
    bottom: calc(var(--vh) * -10);
  }
}

.PageHeaderLanding-body {
  clear: left;
  @include media-breakpoint-up(sm) {
    float: left;
    width: 50%;
    padding-right: map_get($grid-gutter-widths, sm) * 0.5;
  }
  @include media-breakpoint-up(md) {
    padding-left: 17.1%;
  }
  @include media-breakpoint-up(lg) {
    padding-right: 7%;
  }
}
