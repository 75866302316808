.BannerColor {
  @include section-spacer(("padding-top","padding-bottom"), 0.75);
  background-color: currentColor;
}

.BannerColor-col {
  @include reveal();
  text-align: center;
}

.BannerColor-title {
  @include ms-respond(font-size, 27.5);
  font-family: $font-family-serif;
  font-weight: 900;
  margin: 0 auto .5em auto;
  [data-text-inverted] & {
    color: $color-text-inverted;
  }
}
