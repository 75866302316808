.CourseList-headingCol {
  @include reveal();
  margin-left: auto;
  @include media-breakpoint-up(sm) {
    @include make-col(10);
  }
  @include media-breakpoint-up(md) {
    @include make-col(4);
  }
}

.CourseList-listCol {
  margin-left: auto;
  @include media-breakpoint-up(sm) {
    @include make-col(10);
  }
  @include media-breakpoint-up(md) {
    @include make-col(8);
  }
}

.CourseList-heading {
  margin-bottom: 3rem;
}

.CourseList-list {
  @include reveal('.CourseList-listCol');
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 4px solid $black;
}

.CourseList-item {
  @include reveal('.CourseList-listCol');
  @include transition-delay-nthchild(10);
  border-bottom: 1px solid $black;
  position: relative;

  &:last-child {
    border: 0;
  }
}

a.CourseList-link {
  display: block;
  text-decoration: none;
  padding: 1rem 1.5rem 1rem 0;
  position: relative;
  overflow: hidden;
  @include media-breakpoint-up(sm) {
    padding: 1.5rem 1.5rem 1.5rem 0;
  }
  .Icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.5s;
    &:last-child {
      right: auto;
      left: -1.5rem;
    }
  }
  .desktop &:hover .Icon {
    transform: translateX(1.5rem) translateY(-50%);
  }
}

.CourseList-title {
  font-weight: 700;
}

.CourseList-title,
.CourseList-subtitle {
  line-height: 1;
  text-transform: uppercase;
  display: inline-block;
  transition: transform 0.5s;
  .desktop a:hover & {
    transform: translateX(1.5rem);
  }
}

.CourseList-subtitle {
  font-weight: normal;
}


