.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 .75rem;
  height: calc(3.2rem + 2px);
  min-width: 12rem;
  letter-spacing: 0.02em;
  text-decoration: none !important;
  font-size: calc(1rem - 2px);
  font-weight: 500;
  appearance: none;
  border: 0;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: color 0.1s linear 0.05s, padding 0.6s;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transform: translateY(102%);
    transition: transform 0.6s cubic-bezier("easeOutCubic");
    opacity: 0.15;
  }

  &:hover:before {
    transform: translateY(0);
  }
}

.Button--inverted,
[data-text-inverted] .Button {
  filter: invert(1);
}

.Button--primary {
  color: $color-background;
  background-color: $color-text;
  &:before {
    background-color: currentColor;
    opacity: 0.15;
  }
  &:hover {
    color: $color-background;
  }
  &:after {
    filter: invert(1);
  }
}

.Button--secondary {
  color: $color-text;
  background: transparent;
  box-shadow: inset 1px 1px $color-text, inset -1px -1px $color-text;
  &:hover {
    color: $color-background;
  }
  &:before {
    background: $color-text;
    opacity: 1;
  }
  &:hover:after {
    filter: invert(1);
  }
}

.Button--underline {
  // SAME AS .Button--primary down XS
  @include media-breakpoint-down(xs) {
    color: $color-background;
    background-color: $color-text;
    &:before {
      background-color: currentColor;
      opacity: 0.15;
    }
    &:hover {
      color: $color-background;
    }
    &:after {
      filter: invert(1);
    }
  }
  // underline styles for up SM
  @include media-breakpoint-up(sm) {
    padding: 0;
    color: $color-text;
    background: transparent;
    box-shadow: inset 0 -3px $color-text;
    &:before {
      background-color: $color-text;
      opacity: 1;
    }
    &:hover {
      color: $color-background;
      padding: 0 $input-padding-x;
    }
    &:hover:after {
      filter: invert(1);
    }
  }
}

.Button--right,
.Button--down {
  justify-content: space-between;
  &:after {
    content: '';
    width: 1rem;
    height: 1rem;
    margin-left: 1rem;
    background-color: transparent;
    background-image: url(svg("arrow", "fill: none; stroke: #{$color-text}; stroke-width: 1.3px"));
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition: filter 0.1s linear 0.1s;
  }
}

.Button--down {
  &:after {
    transform: rotate(90deg);
  }
}

.Button.is-loading {
  pointer-events: none;
  &:before,
  &:after {
    content: '';
    position: absolute;
  }
  &:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $color-text;
    transform: none;
    z-index: 1;
    opacity: 1;
  }
  &:after {
    background: none !important;
    z-index: 2;
    width: 1rem;
    height: 1rem;
    top: 50%;
    left: 50%;
    transform-origin: 50% 50%;
    margin-top: -0.5rem;
    margin-left: -0.5rem;
    border: 2px solid $color-text;
    border-left-color: rgba($color-text, 0.3);
    border-radius: 50%;
    border-left-color: rgba;
    animation: rotate 0.8s linear infinite;
  }
}

.Button.Button--secondary.is-loading {
  &:before {
    background-color: $color-text-inverted;
    box-shadow: inset 1px 1px $color-text, inset -1px -1px $color-text;
  }
}


// ckeditor...
.ButtonPrimary {
  @extend .Button;
  @extend .Button--primary;
}

.ButtonPrimaryRight {
  @extend .ButtonPrimary;
  @extend .Button--right;
}

.ButtonPrimaryDown {
  @extend .ButtonPrimary;
  @extend .Button--down;
}

.ButtonSecondary {
  @extend .Button;
  @extend .Button--secondary;
}

.ButtonSecondaryRight {
  @extend .ButtonSecondary;
  @extend .Button--right;
}

.ButtonSecondaryDown {
  @extend .ButtonSecondary;
  @extend .Button--down;
}

.ButtonUnderline {
  @extend .Button;
  @extend .Button--underline;
}

.ButtonUnderlineRight {
  @extend .ButtonUnderline;
  @extend .Button--right;
}

.ButtonUnderlineDown {
  @extend .ButtonUnderline;
  @extend .Button--down;
}



