/*
  COLORS
 */
/*
  GRID
 */
/*
  TYPOGRAPHY
 */
/*
  BOOTSTRAP
 */
/*
  bootstrap select
*/
/*
  COMPONENTS
 */
/*
SVG
 */
/* Font Face */
/* font smoothing */
/* Core Utility to add margin and padding, used by the spacer mixins below */
/* Responsive spacer based on grid-gutter-width values or a given spacer map */
/* Spacer mixins based on global $spacers, accept also negative values */
/* Spacer shortcut for margin bottom */
/* Spacer shortcut for padding */
/* Spacer shortcut for margin-right */
/* overwrite bootstrap caret */
@import url(../../../node_modules/@fancyapps/fancybox/src/css/core.css);
@import url(../../../node_modules/fullcalendar/main.css);
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 1440px !important; }
  .container {
    min-width: 1440px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #cccccc; }
  .table .thead-dark th {
    color: inherit;
    border-color: #cccccc; } }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Apercu", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #000;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1.5rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: currentColor;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: currentColor;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container,
.container-fluid {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 4.26667vw;
  padding-left: 4.26667vw; }
  @media (min-width: 768px) {
    .container,
    .container-fluid {
      padding-right: 3.33333vw;
      padding-left: 3.33333vw; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.13333vw;
  margin-left: -2.13333vw; }
  @media (min-width: 768px) {
    .row {
      margin-right: -1.11111vw;
      margin-left: -1.11111vw; } }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto, .col-xxxl-1, .col-xxxl-2, .col-xxxl-3, .col-xxxl-4, .col-xxxl-5, .col-xxxl-6, .col-xxxl-7, .col-xxxl-8, .col-xxxl-9, .col-xxxl-10, .col-xxxl-11, .col-xxxl-12, .col-xxxl,
.col-xxxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw; }
  @media (min-width: 768px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
    .col-xxl-auto, .col-xxxl-1, .col-xxxl-2, .col-xxxl-3, .col-xxxl-4, .col-xxxl-5, .col-xxxl-6, .col-xxxl-7, .col-xxxl-8, .col-xxxl-9, .col-xxxl-10, .col-xxxl-11, .col-xxxl-12, .col-xxxl,
    .col-xxxl-auto {
      padding-right: 1.11111vw;
      padding-left: 1.11111vw; } }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1440px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1640px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1920px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 2400px) {
  .col-xxxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxxl-first {
    order: -1; }
  .order-xxxl-last {
    order: 13; }
  .order-xxxl-0 {
    order: 0; }
  .order-xxxl-1 {
    order: 1; }
  .order-xxxl-2 {
    order: 2; }
  .order-xxxl-3 {
    order: 3; }
  .order-xxxl-4 {
    order: 4; }
  .order-xxxl-5 {
    order: 5; }
  .order-xxxl-6 {
    order: 6; }
  .order-xxxl-7 {
    order: 7; }
  .order-xxxl-8 {
    order: 8; }
  .order-xxxl-9 {
    order: 9; }
  .order-xxxl-10 {
    order: 10; }
  .order-xxxl-11 {
    order: 11; }
  .order-xxxl-12 {
    order: 12; }
  .offset-xxxl-0 {
    margin-left: 0; }
  .offset-xxxl-1 {
    margin-left: 8.33333%; }
  .offset-xxxl-2 {
    margin-left: 16.66667%; }
  .offset-xxxl-3 {
    margin-left: 25%; }
  .offset-xxxl-4 {
    margin-left: 33.33333%; }
  .offset-xxxl-5 {
    margin-left: 41.66667%; }
  .offset-xxxl-6 {
    margin-left: 50%; }
  .offset-xxxl-7 {
    margin-left: 58.33333%; }
  .offset-xxxl-8 {
    margin-left: 66.66667%; }
  .offset-xxxl-9 {
    margin-left: 75%; }
  .offset-xxxl-10 {
    margin-left: 83.33333%; }
  .offset-xxxl-11 {
    margin-left: 91.66667%; } }

.form-control, .bootstrap-select:not(.dropdown--flat) > .dropdown-toggle, .FormBuilder input[type="text"],
.FormBuilder input[type="email"],
.FormBuilder input[type="file"],
.FormBuilder textarea,
.FormBuilder select {
  display: block;
  width: 100%;
  height: calc(1.6em + 0.6rem + 2px);
  padding: 0.3rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d6d6d6;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .bootstrap-select:not(.dropdown--flat) > .dropdown-toggle, .FormBuilder input[type="text"],
    .FormBuilder input[type="email"],
    .FormBuilder input[type="file"],
    .FormBuilder textarea,
    .FormBuilder select {
      transition: none; } }
  .form-control::-ms-expand, .bootstrap-select:not(.dropdown--flat) > .dropdown-toggle::-ms-expand, .FormBuilder input[type="text"]::-ms-expand,
  .FormBuilder input[type="email"]::-ms-expand,
  .FormBuilder input[type="file"]::-ms-expand,
  .FormBuilder textarea::-ms-expand,
  .FormBuilder select::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring, .bootstrap-select:not(.dropdown--flat) > .dropdown-toggle:-moz-focusring, .FormBuilder input:-moz-focusring[type="text"],
  .FormBuilder input:-moz-focusring[type="email"],
  .FormBuilder input:-moz-focusring[type="file"],
  .FormBuilder textarea:-moz-focusring,
  .FormBuilder select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000; }
  .form-control:focus, .bootstrap-select:not(.dropdown--flat) > .dropdown-toggle:focus, .FormBuilder input:focus[type="text"],
  .FormBuilder input:focus[type="email"],
  .FormBuilder input:focus[type="file"],
  .FormBuilder textarea:focus,
  .FormBuilder select:focus {
    color: #000;
    background-color: #fff;
    border-color: #d6d6d6;
    outline: 0;
    box-shadow: none; }
  .form-control::placeholder, .bootstrap-select:not(.dropdown--flat) > .dropdown-toggle::placeholder, .FormBuilder input[type="text"]::placeholder,
  .FormBuilder input[type="email"]::placeholder,
  .FormBuilder input[type="file"]::placeholder,
  .FormBuilder textarea::placeholder,
  .FormBuilder select::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .bootstrap-select:not(.dropdown--flat) > .dropdown-toggle:disabled, .FormBuilder input:disabled[type="text"],
  .FormBuilder input:disabled[type="email"],
  .FormBuilder input:disabled[type="file"],
  .FormBuilder textarea:disabled,
  .FormBuilder select:disabled, .form-control[readonly], .bootstrap-select:not(.dropdown--flat) > .dropdown-toggle[readonly], .FormBuilder input[readonly][type="text"],
  .FormBuilder input[readonly][type="email"],
  .FormBuilder input[readonly][type="file"],
  .FormBuilder textarea[readonly],
  .FormBuilder select[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control, .bootstrap-select:not(.dropdown--flat) > input.dropdown-toggle[type="date"], .FormBuilder input[type="date"][type="text"],
.FormBuilder input[type="date"][type="email"],
.FormBuilder input[type="date"][type="file"],
input[type="time"].form-control,
.bootstrap-select:not(.dropdown--flat) > input.dropdown-toggle[type="time"],
.FormBuilder input[type="time"][type="text"],
.FormBuilder input[type="time"][type="email"],
.FormBuilder input[type="time"][type="file"],
input[type="datetime-local"].form-control,
.bootstrap-select:not(.dropdown--flat) > input.dropdown-toggle[type="datetime-local"],
.FormBuilder input[type="datetime-local"][type="text"],
.FormBuilder input[type="datetime-local"][type="email"],
.FormBuilder input[type="datetime-local"][type="file"],
input[type="month"].form-control,
.bootstrap-select:not(.dropdown--flat) > input.dropdown-toggle[type="month"],
.FormBuilder input[type="month"][type="text"],
.FormBuilder input[type="month"][type="email"],
.FormBuilder input[type="month"][type="file"] {
  appearance: none; }

select.form-control:focus::-ms-value, .bootstrap-select:not(.dropdown--flat) > select.dropdown-toggle:focus::-ms-value,
.FormBuilder select:focus::-ms-value {
  color: #000;
  background-color: #fff; }

.form-control-file,
.FormBuilder input[type="file"],
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.3rem + 1px);
  padding-bottom: calc(0.3rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.3rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.6;
  color: #000;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], .bootstrap-select:not(.dropdown--flat) > select.dropdown-toggle[size],
.FormBuilder select[size], select.form-control[multiple], .bootstrap-select:not(.dropdown--flat) > select.dropdown-toggle[multiple],
.FormBuilder select[multiple] {
  height: auto; }

textarea.form-control, .bootstrap-select:not(.dropdown--flat) > textarea.dropdown-toggle,
.FormBuilder textarea {
  height: auto; }

.form-group, .FormBuilder .Inputfield,
.FormBuilder fieldset {
  margin-bottom: 2rem; }

.form-text, .FormBuilder .input-error, .FormBuilder .notes {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check, .FormBuilder .InputfieldCheckbox .InputfieldContent label:not(.custom-control-label),
.FormBuilder .InputfieldCheckboxes .InputfieldContent label:not(.custom-control-label),
.FormBuilder .InputfieldRadios .InputfieldContent label:not(.custom-control-label) {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label, .FormBuilder .InputfieldCheckbox .InputfieldContent .form-check-input[disabled] ~ label,
  .FormBuilder .InputfieldCheckboxes .InputfieldContent .form-check-input[disabled] ~ label,
  .FormBuilder .InputfieldRadios .InputfieldContent .form-check-input[disabled] ~ label,
  .form-check-input:disabled ~ .form-check-label,
  .FormBuilder .InputfieldCheckbox .InputfieldContent .form-check-input:disabled ~ label,
  .FormBuilder .InputfieldCheckboxes .InputfieldContent .form-check-input:disabled ~ label,
  .FormBuilder .InputfieldRadios .InputfieldContent .form-check-input:disabled ~ label {
    color: #6c757d; }

.form-check-label, .FormBuilder .InputfieldCheckbox .InputfieldContent label,
.FormBuilder .InputfieldCheckboxes .InputfieldContent label,
.FormBuilder .InputfieldRadios .InputfieldContent label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: calc(1rem - 2px);
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.6;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .was-validated .bootstrap-select:not(.dropdown--flat) > .dropdown-toggle:valid, .was-validated .FormBuilder input:valid[type="text"], .FormBuilder .was-validated input:valid[type="text"],
.was-validated .FormBuilder input:valid[type="email"], .FormBuilder .was-validated input:valid[type="email"],
.was-validated .FormBuilder input:valid[type="file"], .FormBuilder .was-validated input:valid[type="file"],
.was-validated .FormBuilder textarea:valid, .FormBuilder .was-validated textarea:valid,
.was-validated .FormBuilder select:valid, .FormBuilder .was-validated select:valid, .form-control.is-valid, .bootstrap-select:not(.dropdown--flat) > .is-valid.dropdown-toggle, .FormBuilder input.is-valid[type="text"],
.FormBuilder input.is-valid[type="email"],
.FormBuilder input.is-valid[type="file"],
.FormBuilder textarea.is-valid,
.FormBuilder select.is-valid {
  border-color: #28a745;
  padding-right: calc(1.6em + 0.6rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.15rem) center;
  background-size: calc(0.8em + 0.3rem) calc(0.8em + 0.3rem); }
  .was-validated .form-control:valid:focus, .was-validated .bootstrap-select:not(.dropdown--flat) > .dropdown-toggle:valid:focus, .was-validated .FormBuilder input:valid:focus[type="text"], .FormBuilder .was-validated input:valid:focus[type="text"],
  .was-validated .FormBuilder input:valid:focus[type="email"], .FormBuilder .was-validated input:valid:focus[type="email"],
  .was-validated .FormBuilder input:valid:focus[type="file"], .FormBuilder .was-validated input:valid:focus[type="file"],
  .was-validated .FormBuilder textarea:valid:focus, .FormBuilder .was-validated textarea:valid:focus,
  .was-validated .FormBuilder select:valid:focus, .FormBuilder .was-validated select:valid:focus, .form-control.is-valid:focus, .bootstrap-select:not(.dropdown--flat) > .is-valid.dropdown-toggle:focus, .FormBuilder input.is-valid:focus[type="text"],
  .FormBuilder input.is-valid:focus[type="email"],
  .FormBuilder input.is-valid:focus[type="file"],
  .FormBuilder textarea.is-valid:focus,
  .FormBuilder select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, .was-validated .bootstrap-select:not(.dropdown--flat) > textarea.dropdown-toggle:valid,
.was-validated .FormBuilder textarea:valid, .FormBuilder .was-validated textarea:valid, textarea.form-control.is-valid, .bootstrap-select:not(.dropdown--flat) > textarea.is-valid.dropdown-toggle,
.FormBuilder textarea.is-valid {
  padding-right: calc(1.6em + 0.6rem);
  background-position: top calc(0.4em + 0.15rem) right calc(0.4em + 0.15rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.8em + 0.3rem) calc(0.8em + 0.3rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent .form-check-input:valid ~ label, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated .form-check-input:valid ~ label,
.was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent .form-check-input:valid ~ label, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated .form-check-input:valid ~ label,
.was-validated .FormBuilder .InputfieldRadios .InputfieldContent .form-check-input:valid ~ label, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated .form-check-input:valid ~ label, .form-check-input.is-valid ~ .form-check-label, .FormBuilder .InputfieldCheckbox .InputfieldContent .form-check-input.is-valid ~ label,
.FormBuilder .InputfieldCheckboxes .InputfieldContent .form-check-input.is-valid ~ label,
.FormBuilder .InputfieldRadios .InputfieldContent .form-check-input.is-valid ~ label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent input:valid ~ .custom-control-label, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated input:valid ~ .custom-control-label, .was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent input:valid ~ .custom-control-label, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated input:valid ~ .custom-control-label, .was-validated .FormBuilder .InputfieldRadios .InputfieldContent input:valid ~ .custom-control-label, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label, .FormBuilder .InputfieldCheckbox .InputfieldContent input.is-valid ~ .custom-control-label, .FormBuilder .InputfieldCheckboxes .InputfieldContent input.is-valid ~ .custom-control-label, .FormBuilder .InputfieldRadios .InputfieldContent input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent input:valid ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated input:valid ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent input:valid ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated input:valid ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldRadios .InputfieldContent input:valid ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent input.is-valid ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent input.is-valid ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent input:valid:checked ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated input:valid:checked ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent input:valid:checked ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated input:valid:checked ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldRadios .InputfieldContent input:valid:checked ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent input.is-valid:checked ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent input.is-valid:checked ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent input:valid:focus ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated input:valid:focus ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent input:valid:focus ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated input:valid:focus ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldRadios .InputfieldContent input:valid:focus ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent input.is-valid:focus ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent input.is-valid:focus ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent input:valid:focus:not(:checked) ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated input:valid:focus:not(:checked) ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent input:valid:focus:not(:checked) ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated input:valid:focus:not(:checked) ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldRadios .InputfieldContent input:valid:focus:not(:checked) ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent input.is-valid:focus:not(:checked) ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent input.is-valid:focus:not(:checked) ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: calc(1rem - 2px);
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.6;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .was-validated .bootstrap-select:not(.dropdown--flat) > .dropdown-toggle:invalid, .was-validated .FormBuilder input:invalid[type="text"], .FormBuilder .was-validated input:invalid[type="text"],
.was-validated .FormBuilder input:invalid[type="email"], .FormBuilder .was-validated input:invalid[type="email"],
.was-validated .FormBuilder input:invalid[type="file"], .FormBuilder .was-validated input:invalid[type="file"],
.was-validated .FormBuilder textarea:invalid, .FormBuilder .was-validated textarea:invalid,
.was-validated .FormBuilder select:invalid, .FormBuilder .was-validated select:invalid, .form-control.is-invalid, .bootstrap-select:not(.dropdown--flat) > .is-invalid.dropdown-toggle, .FormBuilder input.is-invalid[type="text"],
.FormBuilder input.is-invalid[type="email"],
.FormBuilder input.is-invalid[type="file"],
.FormBuilder textarea.is-invalid,
.FormBuilder select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.6em + 0.6rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.15rem) center;
  background-size: calc(0.8em + 0.3rem) calc(0.8em + 0.3rem); }
  .was-validated .form-control:invalid:focus, .was-validated .bootstrap-select:not(.dropdown--flat) > .dropdown-toggle:invalid:focus, .was-validated .FormBuilder input:invalid:focus[type="text"], .FormBuilder .was-validated input:invalid:focus[type="text"],
  .was-validated .FormBuilder input:invalid:focus[type="email"], .FormBuilder .was-validated input:invalid:focus[type="email"],
  .was-validated .FormBuilder input:invalid:focus[type="file"], .FormBuilder .was-validated input:invalid:focus[type="file"],
  .was-validated .FormBuilder textarea:invalid:focus, .FormBuilder .was-validated textarea:invalid:focus,
  .was-validated .FormBuilder select:invalid:focus, .FormBuilder .was-validated select:invalid:focus, .form-control.is-invalid:focus, .bootstrap-select:not(.dropdown--flat) > .is-invalid.dropdown-toggle:focus, .FormBuilder input.is-invalid:focus[type="text"],
  .FormBuilder input.is-invalid:focus[type="email"],
  .FormBuilder input.is-invalid:focus[type="file"],
  .FormBuilder textarea.is-invalid:focus,
  .FormBuilder select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, .was-validated .bootstrap-select:not(.dropdown--flat) > textarea.dropdown-toggle:invalid,
.was-validated .FormBuilder textarea:invalid, .FormBuilder .was-validated textarea:invalid, textarea.form-control.is-invalid, .bootstrap-select:not(.dropdown--flat) > textarea.is-invalid.dropdown-toggle,
.FormBuilder textarea.is-invalid {
  padding-right: calc(1.6em + 0.6rem);
  background-position: top calc(0.4em + 0.15rem) right calc(0.4em + 0.15rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.8em + 0.3rem) calc(0.8em + 0.3rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent .form-check-input:invalid ~ label, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated .form-check-input:invalid ~ label,
.was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent .form-check-input:invalid ~ label, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated .form-check-input:invalid ~ label,
.was-validated .FormBuilder .InputfieldRadios .InputfieldContent .form-check-input:invalid ~ label, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated .form-check-input:invalid ~ label, .form-check-input.is-invalid ~ .form-check-label, .FormBuilder .InputfieldCheckbox .InputfieldContent .form-check-input.is-invalid ~ label,
.FormBuilder .InputfieldCheckboxes .InputfieldContent .form-check-input.is-invalid ~ label,
.FormBuilder .InputfieldRadios .InputfieldContent .form-check-input.is-invalid ~ label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent input:invalid ~ .custom-control-label, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated input:invalid ~ .custom-control-label, .was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent input:invalid ~ .custom-control-label, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated input:invalid ~ .custom-control-label, .was-validated .FormBuilder .InputfieldRadios .InputfieldContent input:invalid ~ .custom-control-label, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label, .FormBuilder .InputfieldCheckbox .InputfieldContent input.is-invalid ~ .custom-control-label, .FormBuilder .InputfieldCheckboxes .InputfieldContent input.is-invalid ~ .custom-control-label, .FormBuilder .InputfieldRadios .InputfieldContent input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent input:invalid ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated input:invalid ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent input:invalid ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated input:invalid ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldRadios .InputfieldContent input:invalid ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent input.is-invalid ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent input.is-invalid ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent input:invalid:checked ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated input:invalid:checked ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent input:invalid:checked ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated input:invalid:checked ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldRadios .InputfieldContent input:invalid:checked ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent input.is-invalid:checked ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent input.is-invalid:checked ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent input:invalid:focus ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated input:invalid:focus ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent input:invalid:focus ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated input:invalid:focus ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldRadios .InputfieldContent input:invalid:focus ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent input.is-invalid:focus ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent input.is-invalid:focus ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent input:invalid:focus:not(:checked) ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated input:invalid:focus:not(:checked) ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent input:invalid:focus:not(:checked) ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated input:invalid:focus:not(:checked) ~ .custom-control-label::before, .was-validated .FormBuilder .InputfieldRadios .InputfieldContent input:invalid:focus:not(:checked) ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent input.is-invalid:focus:not(:checked) ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent input.is-invalid:focus:not(:checked) ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check, .form-inline .FormBuilder .InputfieldCheckbox .InputfieldContent label:not(.custom-control-label), .FormBuilder .InputfieldCheckbox .InputfieldContent .form-inline label:not(.custom-control-label),
  .form-inline .FormBuilder .InputfieldCheckboxes .InputfieldContent label:not(.custom-control-label), .FormBuilder .InputfieldCheckboxes .InputfieldContent .form-inline label:not(.custom-control-label),
  .form-inline .FormBuilder .InputfieldRadios .InputfieldContent label:not(.custom-control-label), .FormBuilder .InputfieldRadios .InputfieldContent .form-inline label:not(.custom-control-label) {
    width: 100%; }
  @media (min-width: 768px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group, .form-inline .FormBuilder .Inputfield, .FormBuilder .form-inline .Inputfield,
    .form-inline .FormBuilder fieldset, .FormBuilder .form-inline fieldset {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .form-inline .bootstrap-select:not(.dropdown--flat) > .dropdown-toggle, .form-inline .FormBuilder input[type="text"], .FormBuilder .form-inline input[type="text"],
    .form-inline .FormBuilder input[type="email"], .FormBuilder .form-inline input[type="email"],
    .form-inline .FormBuilder input[type="file"], .FormBuilder .form-inline input[type="file"],
    .form-inline .FormBuilder textarea, .FormBuilder .form-inline textarea,
    .form-inline .FormBuilder select, .FormBuilder .form-inline select {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check, .form-inline .FormBuilder .InputfieldCheckbox .InputfieldContent label:not(.custom-control-label), .FormBuilder .InputfieldCheckbox .InputfieldContent .form-inline label:not(.custom-control-label),
    .form-inline .FormBuilder .InputfieldCheckboxes .InputfieldContent label:not(.custom-control-label), .FormBuilder .InputfieldCheckboxes .InputfieldContent .form-inline label:not(.custom-control-label),
    .form-inline .FormBuilder .InputfieldRadios .InputfieldContent label:not(.custom-control-label), .FormBuilder .InputfieldRadios .InputfieldContent .form-inline label:not(.custom-control-label) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    flex-shrink: 0;
    display: inline-block;
    margin-left: 0.255em;
    position: relative;
    top: 0.1em;
    vertical-align: 0.255em;
    content: "";
    width: 0.875rem;
    height: 0.8em;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14px' height='8px' viewBox='0 0 14 8' style='fill: none; stroke: %23000; stroke-width: 1.5'%3E%3Cpolyline points='1,1 7,7 13,1'%3E%3C/polyline%3E%3C/svg%3E");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d6d6d6;
  border-radius: 0; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 768px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1440px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1640px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1920px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

@media (min-width: 2400px) {
  .dropdown-menu-xxxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  flex-shrink: 0;
  display: inline-block;
  margin-left: 0.255em;
  position: relative;
  top: 0.1em;
  vertical-align: 0.255em;
  content: "";
  width: 0.875rem;
  height: 0.8em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14px' height='8px' viewBox='0 0 14 8' style='fill: none; stroke: %23000; stroke-width: 1.5'%3E%3Cpolyline points='1,1 7,7 13,1'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(-180deg); }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  flex-shrink: 0;
  display: inline-block;
  margin-left: 0.255em;
  position: relative;
  top: 0.1em;
  vertical-align: 0.255em;
  content: "";
  width: 0.875rem;
  height: 0.8em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14px' height='8px' viewBox='0 0 14 8' style='fill: none; stroke: %23000; stroke-width: 1.5'%3E%3Cpolyline points='1,1 7,7 13,1'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(90deg); }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  flex-shrink: 0;
  display: inline-block;
  margin-left: 0.255em;
  position: relative;
  top: 0.1em;
  vertical-align: 0.255em;
  content: "";
  width: 0.875rem;
  height: 0.8em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14px' height='8px' viewBox='0 0 14 8' style='fill: none; stroke: %23000; stroke-width: 1.5'%3E%3Cpolyline points='1,1 7,7 13,1'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  flex-shrink: 0;
  display: inline-block;
  margin-right: 0.255em;
  content: "";
  width: 0.875rem;
  height: 0.8em;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14px' height='8px' viewBox='0 0 14 8' style='fill: none; stroke: %23000; stroke-width: 1.5'%3E%3Cpolyline points='1,1 7,7 13,1'%3E%3C/polyline%3E%3C/svg%3E");
  transform: rotate(-90deg); }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: currentColor;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: currentColor;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #000 !important;
    text-decoration: none;
    background-color: #eee; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: currentColor; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.6rem;
  padding-left: 1.5rem;
  color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input, .FormBuilder .InputfieldCheckbox .InputfieldContent input,
.FormBuilder .InputfieldCheckboxes .InputfieldContent input,
.FormBuilder .InputfieldRadios .InputfieldContent input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.3rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent input:checked ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent input:checked ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent input:checked ~ .custom-control-label::before {
    color: #000;
    border-color: #d6d6d6;
    background-color: #fff; }
  .custom-control-input:focus ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent input:focus ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent input:focus ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent input:focus ~ .custom-control-label::before {
    box-shadow: none; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent input:focus:not(:checked) ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent input:focus:not(:checked) ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #d6d6d6; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent input:not(:disabled):active ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent input:not(:disabled):active ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #d6d6d6;
    border-color: #d6d6d6; }
  .custom-control-input[disabled] ~ .custom-control-label, .FormBuilder .InputfieldCheckbox .InputfieldContent input[disabled] ~ .custom-control-label, .FormBuilder .InputfieldCheckboxes .InputfieldContent input[disabled] ~ .custom-control-label, .FormBuilder .InputfieldRadios .InputfieldContent input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label, .FormBuilder .InputfieldCheckbox .InputfieldContent input:disabled ~ .custom-control-label, .FormBuilder .InputfieldCheckboxes .InputfieldContent input:disabled ~ .custom-control-label, .FormBuilder .InputfieldRadios .InputfieldContent input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent input[disabled] ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent input[disabled] ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent input:disabled ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent input:disabled ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.3rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #d6d6d6 solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.3rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 100%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after, .custom-checkbox .FormBuilder .InputfieldCheckbox .InputfieldContent input:checked ~ .custom-control-label::after, .FormBuilder .InputfieldCheckbox .InputfieldContent .custom-checkbox input:checked ~ .custom-control-label::after, .custom-checkbox .FormBuilder .InputfieldCheckboxes .InputfieldContent input:checked ~ .custom-control-label::after, .FormBuilder .InputfieldCheckboxes .InputfieldContent .custom-checkbox input:checked ~ .custom-control-label::after, .custom-checkbox .FormBuilder .InputfieldRadios .InputfieldContent input:checked ~ .custom-control-label::after, .FormBuilder .InputfieldRadios .InputfieldContent .custom-checkbox input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3crect fill='%23000' width='8px' height='8px' /%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before, .custom-checkbox .FormBuilder .InputfieldCheckbox .InputfieldContent input:indeterminate ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent .custom-checkbox input:indeterminate ~ .custom-control-label::before, .custom-checkbox .FormBuilder .InputfieldCheckboxes .InputfieldContent input:indeterminate ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent .custom-checkbox input:indeterminate ~ .custom-control-label::before, .custom-checkbox .FormBuilder .InputfieldRadios .InputfieldContent input:indeterminate ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent .custom-checkbox input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after, .custom-checkbox .FormBuilder .InputfieldCheckbox .InputfieldContent input:indeterminate ~ .custom-control-label::after, .FormBuilder .InputfieldCheckbox .InputfieldContent .custom-checkbox input:indeterminate ~ .custom-control-label::after, .custom-checkbox .FormBuilder .InputfieldCheckboxes .InputfieldContent input:indeterminate ~ .custom-control-label::after, .FormBuilder .InputfieldCheckboxes .InputfieldContent .custom-checkbox input:indeterminate ~ .custom-control-label::after, .custom-checkbox .FormBuilder .InputfieldRadios .InputfieldContent input:indeterminate ~ .custom-control-label::after, .FormBuilder .InputfieldRadios .InputfieldContent .custom-checkbox input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23000' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before, .custom-checkbox .FormBuilder .InputfieldCheckbox .InputfieldContent input:disabled:checked ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent .custom-checkbox input:disabled:checked ~ .custom-control-label::before, .custom-checkbox .FormBuilder .InputfieldCheckboxes .InputfieldContent input:disabled:checked ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent .custom-checkbox input:disabled:checked ~ .custom-control-label::before, .custom-checkbox .FormBuilder .InputfieldRadios .InputfieldContent input:disabled:checked ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent .custom-checkbox input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before, .custom-checkbox .FormBuilder .InputfieldCheckbox .InputfieldContent input:disabled:indeterminate ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent .custom-checkbox input:disabled:indeterminate ~ .custom-control-label::before, .custom-checkbox .FormBuilder .InputfieldCheckboxes .InputfieldContent input:disabled:indeterminate ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent .custom-checkbox input:disabled:indeterminate ~ .custom-control-label::before, .custom-checkbox .FormBuilder .InputfieldRadios .InputfieldContent input:disabled:indeterminate ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent .custom-checkbox input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after, .custom-radio .FormBuilder .InputfieldCheckbox .InputfieldContent input:checked ~ .custom-control-label::after, .FormBuilder .InputfieldCheckbox .InputfieldContent .custom-radio input:checked ~ .custom-control-label::after, .custom-radio .FormBuilder .InputfieldCheckboxes .InputfieldContent input:checked ~ .custom-control-label::after, .FormBuilder .InputfieldCheckboxes .InputfieldContent .custom-radio input:checked ~ .custom-control-label::after, .custom-radio .FormBuilder .InputfieldRadios .InputfieldContent input:checked ~ .custom-control-label::after, .FormBuilder .InputfieldRadios .InputfieldContent .custom-radio input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23000'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before, .custom-radio .FormBuilder .InputfieldCheckbox .InputfieldContent input:disabled:checked ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent .custom-radio input:disabled:checked ~ .custom-control-label::before, .custom-radio .FormBuilder .InputfieldCheckboxes .InputfieldContent input:disabled:checked ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent .custom-radio input:disabled:checked ~ .custom-control-label::before, .custom-radio .FormBuilder .InputfieldRadios .InputfieldContent input:disabled:checked ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent .custom-radio input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.3rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #d6d6d6;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after, .custom-switch .FormBuilder .InputfieldCheckbox .InputfieldContent input:checked ~ .custom-control-label::after, .FormBuilder .InputfieldCheckbox .InputfieldContent .custom-switch input:checked ~ .custom-control-label::after, .custom-switch .FormBuilder .InputfieldCheckboxes .InputfieldContent input:checked ~ .custom-control-label::after, .FormBuilder .InputfieldCheckboxes .InputfieldContent .custom-switch input:checked ~ .custom-control-label::after, .custom-switch .FormBuilder .InputfieldRadios .InputfieldContent input:checked ~ .custom-control-label::after, .FormBuilder .InputfieldRadios .InputfieldContent .custom-switch input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before, .custom-switch .FormBuilder .InputfieldCheckbox .InputfieldContent input:disabled:checked ~ .custom-control-label::before, .FormBuilder .InputfieldCheckbox .InputfieldContent .custom-switch input:disabled:checked ~ .custom-control-label::before, .custom-switch .FormBuilder .InputfieldCheckboxes .InputfieldContent input:disabled:checked ~ .custom-control-label::before, .FormBuilder .InputfieldCheckboxes .InputfieldContent .custom-switch input:disabled:checked ~ .custom-control-label::before, .custom-switch .FormBuilder .InputfieldRadios .InputfieldContent input:disabled:checked ~ .custom-control-label::before, .FormBuilder .InputfieldRadios .InputfieldContent .custom-switch input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 0.6rem + 2px);
  padding: 0.3rem 1.75rem 0.3rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #000;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #d6d6d6;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #d6d6d6;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #000;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 0.6rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.6em + 0.6rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #d6d6d6;
    box-shadow: none; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.6em + 0.6rem + 2px);
  padding: 0.3rem 0.75rem;
  font-weight: 400;
  line-height: 1.6;
  color: #000;
  background-color: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 0; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.6em + 0.6rem);
    padding: 0.3rem 0.75rem;
    line-height: 1.6;
    color: #000;
    content: "Browse";
    background-color: #eee;
    border-left: inherit;
    border-radius: 0 0 0 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, none; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, none; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-error hr {
    border-top-color: #f1b0b7; }
  .alert-error .alert-link {
    color: #491217; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1440px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1640px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1920px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 2400px) {
  .d-xxxl-none {
    display: none !important; }
  .d-xxxl-inline {
    display: inline !important; }
  .d-xxxl-inline-block {
    display: inline-block !important; }
  .d-xxxl-block {
    display: block !important; }
  .d-xxxl-table {
    display: table !important; }
  .d-xxxl-table-row {
    display: table-row !important; }
  .d-xxxl-table-cell {
    display: table-cell !important; }
  .d-xxxl-flex {
    display: flex !important; }
  .d-xxxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1440px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1640px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1920px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

@media (min-width: 2400px) {
  .m-xxxl-0 {
    margin: 0 !important; }
  .mt-xxxl-0,
  .my-xxxl-0 {
    margin-top: 0 !important; }
  .mr-xxxl-0,
  .mx-xxxl-0 {
    margin-right: 0 !important; }
  .mb-xxxl-0,
  .my-xxxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxxl-0,
  .mx-xxxl-0 {
    margin-left: 0 !important; }
  .m-xxxl-1 {
    margin: 0.25rem !important; }
  .mt-xxxl-1,
  .my-xxxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxxl-1,
  .mx-xxxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxxl-1,
  .my-xxxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxxl-1,
  .mx-xxxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxxl-2 {
    margin: 0.5rem !important; }
  .mt-xxxl-2,
  .my-xxxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxxl-2,
  .mx-xxxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxxl-2,
  .my-xxxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxxl-2,
  .mx-xxxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxxl-3 {
    margin: 1rem !important; }
  .mt-xxxl-3,
  .my-xxxl-3 {
    margin-top: 1rem !important; }
  .mr-xxxl-3,
  .mx-xxxl-3 {
    margin-right: 1rem !important; }
  .mb-xxxl-3,
  .my-xxxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxxl-3,
  .mx-xxxl-3 {
    margin-left: 1rem !important; }
  .m-xxxl-4 {
    margin: 1.5rem !important; }
  .mt-xxxl-4,
  .my-xxxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxxl-4,
  .mx-xxxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxxl-4,
  .my-xxxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxxl-4,
  .mx-xxxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxxl-5 {
    margin: 3rem !important; }
  .mt-xxxl-5,
  .my-xxxl-5 {
    margin-top: 3rem !important; }
  .mr-xxxl-5,
  .mx-xxxl-5 {
    margin-right: 3rem !important; }
  .mb-xxxl-5,
  .my-xxxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxxl-5,
  .mx-xxxl-5 {
    margin-left: 3rem !important; }
  .p-xxxl-0 {
    padding: 0 !important; }
  .pt-xxxl-0,
  .py-xxxl-0 {
    padding-top: 0 !important; }
  .pr-xxxl-0,
  .px-xxxl-0 {
    padding-right: 0 !important; }
  .pb-xxxl-0,
  .py-xxxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxxl-0,
  .px-xxxl-0 {
    padding-left: 0 !important; }
  .p-xxxl-1 {
    padding: 0.25rem !important; }
  .pt-xxxl-1,
  .py-xxxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxxl-1,
  .px-xxxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxxl-1,
  .py-xxxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxxl-1,
  .px-xxxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxxl-2 {
    padding: 0.5rem !important; }
  .pt-xxxl-2,
  .py-xxxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxxl-2,
  .px-xxxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxxl-2,
  .py-xxxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxxl-2,
  .px-xxxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxxl-3 {
    padding: 1rem !important; }
  .pt-xxxl-3,
  .py-xxxl-3 {
    padding-top: 1rem !important; }
  .pr-xxxl-3,
  .px-xxxl-3 {
    padding-right: 1rem !important; }
  .pb-xxxl-3,
  .py-xxxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxxl-3,
  .px-xxxl-3 {
    padding-left: 1rem !important; }
  .p-xxxl-4 {
    padding: 1.5rem !important; }
  .pt-xxxl-4,
  .py-xxxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxxl-4,
  .px-xxxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxxl-4,
  .py-xxxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxxl-4,
  .px-xxxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxxl-5 {
    padding: 3rem !important; }
  .pt-xxxl-5,
  .py-xxxl-5 {
    padding-top: 3rem !important; }
  .pr-xxxl-5,
  .px-xxxl-5 {
    padding-right: 3rem !important; }
  .pb-xxxl-5,
  .py-xxxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxxl-5,
  .px-xxxl-5 {
    padding-left: 3rem !important; }
  .m-xxxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxxl-n1,
  .my-xxxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxxl-n1,
  .mx-xxxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxxl-n1,
  .my-xxxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxxl-n1,
  .mx-xxxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxxl-n2,
  .my-xxxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxxl-n2,
  .mx-xxxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxxl-n2,
  .my-xxxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxxl-n2,
  .mx-xxxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxxl-n3 {
    margin: -1rem !important; }
  .mt-xxxl-n3,
  .my-xxxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxxl-n3,
  .mx-xxxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxxl-n3,
  .my-xxxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxxl-n3,
  .mx-xxxl-n3 {
    margin-left: -1rem !important; }
  .m-xxxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxxl-n4,
  .my-xxxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxxl-n4,
  .mx-xxxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxxl-n4,
  .my-xxxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxxl-n4,
  .mx-xxxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxxl-n5 {
    margin: -3rem !important; }
  .mt-xxxl-n5,
  .my-xxxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxxl-n5,
  .mx-xxxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxxl-n5,
  .my-xxxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxxl-n5,
  .mx-xxxl-n5 {
    margin-left: -3rem !important; }
  .m-xxxl-auto {
    margin: auto !important; }
  .mt-xxxl-auto,
  .my-xxxl-auto {
    margin-top: auto !important; }
  .mr-xxxl-auto,
  .mx-xxxl-auto {
    margin-right: auto !important; }
  .mb-xxxl-auto,
  .my-xxxl-auto {
    margin-bottom: auto !important; }
  .ml-xxxl-auto,
  .mx-xxxl-auto {
    margin-left: auto !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1440px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1640px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1920px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

@media (min-width: 2400px) {
  .flex-xxxl-row {
    flex-direction: row !important; }
  .flex-xxxl-column {
    flex-direction: column !important; }
  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxxl-center {
    justify-content: center !important; }
  .justify-content-xxxl-between {
    justify-content: space-between !important; }
  .justify-content-xxxl-around {
    justify-content: space-around !important; }
  .align-items-xxxl-start {
    align-items: flex-start !important; }
  .align-items-xxxl-end {
    align-items: flex-end !important; }
  .align-items-xxxl-center {
    align-items: center !important; }
  .align-items-xxxl-baseline {
    align-items: baseline !important; }
  .align-items-xxxl-stretch {
    align-items: stretch !important; }
  .align-content-xxxl-start {
    align-content: flex-start !important; }
  .align-content-xxxl-end {
    align-content: flex-end !important; }
  .align-content-xxxl-center {
    align-content: center !important; }
  .align-content-xxxl-between {
    align-content: space-between !important; }
  .align-content-xxxl-around {
    align-content: space-around !important; }
  .align-content-xxxl-stretch {
    align-content: stretch !important; }
  .align-self-xxxl-auto {
    align-self: auto !important; }
  .align-self-xxxl-start {
    align-self: flex-start !important; }
  .align-self-xxxl-end {
    align-self: flex-end !important; }
  .align-self-xxxl-center {
    align-self: center !important; }
  .align-self-xxxl-baseline {
    align-self: baseline !important; }
  .align-self-xxxl-stretch {
    align-self: stretch !important; } }

html.has-scroll-smooth {
  overflow: hidden; }

html.has-scroll-dragging {
  user-select: none; }

.has-scroll-smooth body {
  overflow: hidden; }

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh; }

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap; }

[data-scroll-direction="horizontal"] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%; }

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0; }
  .c-scrollbar:hover {
    transform: scaleX(1.45); }
  .c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
    opacity: 1; }
  [data-scroll-direction="horizontal"] .c-scrollbar {
    width: 100%;
    height: 10px;
    top: auto;
    bottom: 0;
    transform: scaleY(1); }
    [data-scroll-direction="horizontal"] .c-scrollbar:hover {
      transform: scaleY(1.3); }

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: grab; }
  .has-scroll-dragging .c-scrollbar_thumb {
    cursor: grabbing; }
  [data-scroll-direction="horizontal"] .c-scrollbar_thumb {
    right: auto;
    bottom: 0; }

:root {
  --swiper-theme-color: #000; }

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1; }

.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap; }

.swiper-container-multirow-column > .swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: column; }

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform; }

.swiper-slide-invisible-blank {
  visibility: hidden; }

/* Auto Height */
.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height; }

/* 3D Effects */
.swiper-container-3d {
  perspective: 1200px; }
  .swiper-container-3d .swiper-wrapper, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-cube-shadow {
    transform-style: preserve-3d; }
  .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10; }
  .swiper-container-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-top {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

/* CSS Mode */
.swiper-container-css-mode > .swiper-wrapper {
  overflow: auto;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */ }
  .swiper-container-css-mode > .swiper-wrapper::-webkit-scrollbar {
    display: none; }

.swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: start start; }

.swiper-container-horizontal.swiper-container-css-mode > .swiper-wrapper {
  scroll-snap-type: x mandatory; }

.swiper-container-vertical.swiper-container-css-mode > .swiper-wrapper {
  scroll-snap-type: y mandatory; }

:root {
  /*
  --swiper-pagination-color: var(--swiper-theme-color);
  */ }

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10; }
  .swiper-pagination.swiper-pagination-hidden {
    opacity: 0; }

/* Common Styles */
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

/* Bullets */
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0; }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transform: scale(0.33);
    position: relative; }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
    transform: scale(1); }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    transform: scale(1); }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    transform: scale(0.66); }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    transform: scale(0.33); }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(0.66); }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    transform: scale(0.33); }

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2; }
  button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none; }
  .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--swiper-pagination-color, var(--swiper-theme-color)); }

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0); }
  .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 6px 0;
    display: block; }
  .swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px; }
    .swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
      display: inline-block;
      transition: 200ms transform, 200ms top; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px; }

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap; }
  .swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: 200ms transform, 200ms left; }

.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: 200ms transform, 200ms right; }

/* Progress */
.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute; }
  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transform-origin: left top; }
  .swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    transform-origin: right top; }
  .swiper-container-horizontal > .swiper-pagination-progressbar,
  .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0; }
  .swiper-container-vertical > .swiper-pagination-progressbar,
  .swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0; }

.swiper-pagination-white {
  --swiper-pagination-color: #fff; }

.swiper-pagination-4755c {
  --swiper-pagination-color: #d7c4b7; }

.swiper-pagination-5655c {
  --swiper-pagination-color: #b0bdb0; }

.swiper-pagination-621c {
  --swiper-pagination-color: #d1e0d7; }

.swiper-pagination-726c {
  --swiper-pagination-color: #e0c09f; }

.swiper-pagination-7514c {
  --swiper-pagination-color: #d5a286; }

.swiper-pagination-black {
  --swiper-pagination-color: #000; }

.swiper-pagination-warmgray1c {
  --swiper-pagination-color: #d7d2cb; }

.swiper-pagination-gray-95 {
  --swiper-pagination-color: #f2f2f2; }

.swiper-pagination-lock {
  display: none; }

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity; }
  .swiper-container-fade .swiper-slide .swiper-slide {
    pointer-events: none; }

.swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

@keyframes bs-notify-fadeOut {
  0% {
    opacity: 0.9; }
  100% {
    opacity: 0; } }

select.bs-select-hidden,
.bootstrap-select > select.bs-select-hidden,
select.selectpicker {
  display: none !important; }

.bootstrap-select {
  width: 220px \0;
  /*IE9 and below*/
  vertical-align: middle; }
  .bootstrap-select > .dropdown-toggle {
    position: relative;
    width: 100%;
    text-align: right;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: space-between; }
    .bootstrap-select > .dropdown-toggle:after {
      margin-top: -1px; }
    .bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
      color: #999; }
    .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:active {
      color: rgba(255, 255, 255, 0.5); }
  .bootstrap-select > select {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    display: block !important;
    width: 0.5px !important;
    height: 100% !important;
    padding: 0 !important;
    opacity: 0 !important;
    border: none;
    z-index: 0 !important; }
    .bootstrap-select > select.mobile-device {
      top: 0;
      left: 0;
      display: block !important;
      width: 100% !important;
      z-index: 2 !important; }
  .has-error .bootstrap-select .dropdown-toggle,
  .error .bootstrap-select .dropdown-toggle,
  .bootstrap-select.is-invalid .dropdown-toggle,
  .was-validated .bootstrap-select select:invalid + .dropdown-toggle {
    border-color: #b94a48; }
  .bootstrap-select.is-valid .dropdown-toggle,
  .was-validated .bootstrap-select select:valid + .dropdown-toggle {
    border-color: #28a745; }
  .bootstrap-select.fit-width {
    width: auto !important; }
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%; }
  .bootstrap-select > select.mobile-device:focus + .dropdown-toggle,
  .bootstrap-select .dropdown-toggle:focus {
    outline: thin dotted #333333 !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px; }

.bootstrap-select.form-control, .bootstrap-select:not(.dropdown--flat) > .bootstrap-select.dropdown-toggle, .FormBuilder input.bootstrap-select[type="text"],
.FormBuilder input.bootstrap-select[type="email"],
.FormBuilder input.bootstrap-select[type="file"],
.FormBuilder textarea.bootstrap-select,
.FormBuilder select.bootstrap-select {
  margin-bottom: 0;
  padding: 0;
  border: none;
  height: auto; }
  :not(.input-group) > .bootstrap-select.form-control:not([class*="col-"]), .bootstrap-select:not(.dropdown--flat):not(.input-group) > .bootstrap-select.dropdown-toggle:not([class*="col-"]), .FormBuilder :not(.input-group) > input.bootstrap-select:not([class*="col-"])[type="text"],
  .FormBuilder :not(.input-group) > input.bootstrap-select:not([class*="col-"])[type="email"],
  .FormBuilder :not(.input-group) > input.bootstrap-select:not([class*="col-"])[type="file"],
  .FormBuilder :not(.input-group) > textarea.bootstrap-select:not([class*="col-"]),
  .FormBuilder :not(.input-group) > select.bootstrap-select:not([class*="col-"]) {
    width: 100%; }
  .bootstrap-select.form-control.input-group-btn, .bootstrap-select:not(.dropdown--flat) > .bootstrap-select.input-group-btn.dropdown-toggle, .FormBuilder input.bootstrap-select.input-group-btn[type="text"],
  .FormBuilder input.bootstrap-select.input-group-btn[type="email"],
  .FormBuilder input.bootstrap-select.input-group-btn[type="file"],
  .FormBuilder textarea.bootstrap-select.input-group-btn,
  .FormBuilder select.bootstrap-select.input-group-btn {
    float: none;
    z-index: auto; }

.form-inline .bootstrap-select,
.form-inline .bootstrap-select.form-control:not([class*="col-"]),
.form-inline .bootstrap-select:not(.dropdown--flat) > .bootstrap-select.dropdown-toggle:not([class*="col-"]),
.form-inline .FormBuilder input.bootstrap-select:not([class*="col-"])[type="text"],
.FormBuilder .form-inline input.bootstrap-select:not([class*="col-"])[type="text"],
.form-inline .FormBuilder input.bootstrap-select:not([class*="col-"])[type="email"],
.FormBuilder .form-inline input.bootstrap-select:not([class*="col-"])[type="email"],
.form-inline .FormBuilder input.bootstrap-select:not([class*="col-"])[type="file"],
.FormBuilder .form-inline input.bootstrap-select:not([class*="col-"])[type="file"],
.form-inline .FormBuilder textarea.bootstrap-select:not([class*="col-"]),
.FormBuilder .form-inline textarea.bootstrap-select:not([class*="col-"]),
.form-inline .FormBuilder select.bootstrap-select:not([class*="col-"]),
.FormBuilder .form-inline select.bootstrap-select:not([class*="col-"]) {
  width: auto; }

.bootstrap-select:not(.input-group-btn), .bootstrap-select[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0; }

.bootstrap-select.dropdown-menu-right, .bootstrap-select[class*="col-"].dropdown-menu-right,
.row .bootstrap-select[class*="col-"].dropdown-menu-right {
  float: right; }

.form-inline .bootstrap-select,
.form-horizontal .bootstrap-select,
.form-group .bootstrap-select,
.FormBuilder .Inputfield .bootstrap-select,
.FormBuilder fieldset .bootstrap-select {
  margin-bottom: 0; }

.form-group-lg .bootstrap-select.form-control, .form-group-lg .bootstrap-select:not(.dropdown--flat) > .bootstrap-select.dropdown-toggle, .form-group-lg .FormBuilder input.bootstrap-select[type="text"], .FormBuilder .form-group-lg input.bootstrap-select[type="text"],
.form-group-lg .FormBuilder input.bootstrap-select[type="email"], .FormBuilder .form-group-lg input.bootstrap-select[type="email"],
.form-group-lg .FormBuilder input.bootstrap-select[type="file"], .FormBuilder .form-group-lg input.bootstrap-select[type="file"],
.form-group-lg .FormBuilder textarea.bootstrap-select, .FormBuilder .form-group-lg textarea.bootstrap-select,
.form-group-lg .FormBuilder select.bootstrap-select, .FormBuilder .form-group-lg select.bootstrap-select,
.form-group-sm .bootstrap-select.form-control,
.form-group-sm .bootstrap-select:not(.dropdown--flat) > .bootstrap-select.dropdown-toggle,
.form-group-sm .FormBuilder input.bootstrap-select[type="text"],
.FormBuilder .form-group-sm input.bootstrap-select[type="text"],
.form-group-sm .FormBuilder input.bootstrap-select[type="email"],
.FormBuilder .form-group-sm input.bootstrap-select[type="email"],
.form-group-sm .FormBuilder input.bootstrap-select[type="file"],
.FormBuilder .form-group-sm input.bootstrap-select[type="file"],
.form-group-sm .FormBuilder textarea.bootstrap-select,
.FormBuilder .form-group-sm textarea.bootstrap-select,
.form-group-sm .FormBuilder select.bootstrap-select,
.FormBuilder .form-group-sm select.bootstrap-select {
  padding: 0; }
  .form-group-lg .bootstrap-select.form-control .dropdown-toggle, .form-group-lg .bootstrap-select:not(.dropdown--flat) > .bootstrap-select.dropdown-toggle .dropdown-toggle, .form-group-lg .FormBuilder input.bootstrap-select[type="text"] .dropdown-toggle, .FormBuilder .form-group-lg input.bootstrap-select[type="text"] .dropdown-toggle, .form-group-lg .FormBuilder input.bootstrap-select[type="email"] .dropdown-toggle, .FormBuilder .form-group-lg input.bootstrap-select[type="email"] .dropdown-toggle, .form-group-lg .FormBuilder input.bootstrap-select[type="file"] .dropdown-toggle, .FormBuilder .form-group-lg input.bootstrap-select[type="file"] .dropdown-toggle, .form-group-lg .FormBuilder textarea.bootstrap-select .dropdown-toggle, .FormBuilder .form-group-lg textarea.bootstrap-select .dropdown-toggle, .form-group-lg .FormBuilder select.bootstrap-select .dropdown-toggle, .FormBuilder .form-group-lg select.bootstrap-select .dropdown-toggle,
  .form-group-sm .bootstrap-select.form-control .dropdown-toggle,
  .form-group-sm .bootstrap-select:not(.dropdown--flat) > .bootstrap-select.dropdown-toggle .dropdown-toggle,
  .form-group-sm .FormBuilder input.bootstrap-select[type="text"] .dropdown-toggle,
  .FormBuilder .form-group-sm input.bootstrap-select[type="text"] .dropdown-toggle,
  .form-group-sm .FormBuilder input.bootstrap-select[type="email"] .dropdown-toggle,
  .FormBuilder .form-group-sm input.bootstrap-select[type="email"] .dropdown-toggle,
  .form-group-sm .FormBuilder input.bootstrap-select[type="file"] .dropdown-toggle,
  .FormBuilder .form-group-sm input.bootstrap-select[type="file"] .dropdown-toggle,
  .form-group-sm .FormBuilder textarea.bootstrap-select .dropdown-toggle,
  .FormBuilder .form-group-sm textarea.bootstrap-select .dropdown-toggle,
  .form-group-sm .FormBuilder select.bootstrap-select .dropdown-toggle,
  .FormBuilder .form-group-sm select.bootstrap-select .dropdown-toggle {
    height: 100%;
    font-size: inherit;
    line-height: inherit;
    border-radius: inherit; }

.bootstrap-select.form-control-sm .dropdown-toggle,
.bootstrap-select.form-control-lg .dropdown-toggle {
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit; }

.bootstrap-select.form-control-sm .dropdown-toggle {
  padding: 0.25rem 0.5rem; }

.bootstrap-select.form-control-lg .dropdown-toggle {
  padding: 0.5rem 1rem; }

.form-inline .bootstrap-select .form-control, .form-inline .bootstrap-select:not(.dropdown--flat) > .dropdown-toggle, .form-inline .bootstrap-select .FormBuilder input[type="text"], .FormBuilder .form-inline .bootstrap-select input[type="text"],
.form-inline .bootstrap-select .FormBuilder input[type="email"], .FormBuilder .form-inline .bootstrap-select input[type="email"],
.form-inline .bootstrap-select .FormBuilder input[type="file"], .FormBuilder .form-inline .bootstrap-select input[type="file"],
.form-inline .bootstrap-select .FormBuilder textarea, .FormBuilder .form-inline .bootstrap-select textarea,
.form-inline .bootstrap-select .FormBuilder select, .FormBuilder .form-inline .bootstrap-select select {
  width: 100%; }

.bootstrap-select.disabled,
.bootstrap-select > .disabled {
  cursor: not-allowed; }
  .bootstrap-select.disabled:focus,
  .bootstrap-select > .disabled:focus {
    outline: none !important; }

.bootstrap-select.bs-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 0 !important;
  padding: 0 !important; }
  .bootstrap-select.bs-container .dropdown-menu {
    z-index: 1060; }

.bootstrap-select .dropdown-toggle .filter-option {
  position: static;
  top: 0;
  left: 0;
  float: left;
  height: 100%;
  width: 100%;
  text-align: left;
  overflow: hidden;
  flex: 0 1 auto; }
  .bs3.bootstrap-select .dropdown-toggle .filter-option {
    padding-right: inherit; }
  .input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option {
    position: absolute;
    padding-top: inherit;
    padding-bottom: inherit;
    padding-left: inherit;
    float: none; }
    .input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option .filter-option-inner {
      padding-right: inherit; }

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden; }

.bootstrap-select .dropdown-toggle .filter-expand {
  width: 0 !important;
  float: left;
  opacity: 0 !important;
  overflow: hidden; }

.bootstrap-select .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle; }

.input-group .bootstrap-select.form-control .dropdown-toggle, .input-group .bootstrap-select:not(.dropdown--flat) > .bootstrap-select.dropdown-toggle .dropdown-toggle, .input-group .FormBuilder input.bootstrap-select[type="text"] .dropdown-toggle, .FormBuilder .input-group input.bootstrap-select[type="text"] .dropdown-toggle, .input-group .FormBuilder input.bootstrap-select[type="email"] .dropdown-toggle, .FormBuilder .input-group input.bootstrap-select[type="email"] .dropdown-toggle, .input-group .FormBuilder input.bootstrap-select[type="file"] .dropdown-toggle, .FormBuilder .input-group input.bootstrap-select[type="file"] .dropdown-toggle, .input-group .FormBuilder textarea.bootstrap-select .dropdown-toggle, .FormBuilder .input-group textarea.bootstrap-select .dropdown-toggle, .input-group .FormBuilder select.bootstrap-select .dropdown-toggle, .FormBuilder .input-group select.bootstrap-select .dropdown-toggle {
  border-radius: inherit; }

.bootstrap-select[class*="col-"] .dropdown-toggle {
  width: 100%; }

.bootstrap-select .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .bootstrap-select .dropdown-menu > .inner:focus {
    outline: none !important; }
  .bootstrap-select .dropdown-menu.inner {
    position: static;
    float: none;
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    box-shadow: none; }
  .bootstrap-select .dropdown-menu li {
    position: relative; }
    .bootstrap-select .dropdown-menu li.active small {
      color: rgba(255, 255, 255, 0.5) !important; }
    .bootstrap-select .dropdown-menu li.disabled a {
      cursor: not-allowed; }
    .bootstrap-select .dropdown-menu li a {
      cursor: pointer;
      user-select: none; }
      .bootstrap-select .dropdown-menu li a.opt {
        position: relative;
        padding-left: 2.25em; }
      .bootstrap-select .dropdown-menu li a span.check-mark {
        display: none; }
      .bootstrap-select .dropdown-menu li a span.text {
        display: inline-block; }
    .bootstrap-select .dropdown-menu li small {
      padding-left: 0.5em; }
  .bootstrap-select .dropdown-menu .notify {
    position: absolute;
    bottom: 5px;
    width: 96%;
    margin: 0 2%;
    min-height: 26px;
    padding: 3px 5px;
    background: whitesmoke;
    border: 1px solid #e3e3e3;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    pointer-events: none;
    opacity: 0.9;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .bootstrap-select .dropdown-menu .notify.fadeOut {
      animation: 300ms linear 750ms forwards bs-notify-fadeOut; }

.bootstrap-select .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option {
  position: static;
  display: inline;
  padding: 0; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner,
.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner-inner {
  display: inline; }

.bootstrap-select.fit-width .dropdown-toggle .bs-caret:before {
  content: '\00a0'; }

.bootstrap-select.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px; }

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 5px; }

.bootstrap-select.show-tick .dropdown-menu li a span.text {
  margin-right: 34px; }

.bootstrap-select .bs-ok-default:after {
  content: '';
  display: block;
  width: 0.5em;
  height: 1em;
  border-style: solid;
  border-width: 0 0.26em 0.26em 0;
  transform-style: preserve-3d;
  transform: rotate(45deg); }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle {
  z-index: 1061; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:before {
  bottom: auto;
  top: -4px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:after {
  bottom: auto;
  top: -4px;
  border-top: 6px solid white;
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:before {
  right: 12px;
  left: auto; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:after {
  right: 13px;
  left: auto; }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:before, .bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:after,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:after {
  display: block; }

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px; }

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .bs-actionsbox .btn-group button {
    width: 50%; }

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .bs-donebutton .btn-group button {
    width: 100%; }

.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px; }

.bs-searchbox .form-control, .bs-searchbox .bootstrap-select:not(.dropdown--flat) > .dropdown-toggle, .bs-searchbox .FormBuilder input[type="text"], .FormBuilder .bs-searchbox input[type="text"],
.bs-searchbox .FormBuilder input[type="email"], .FormBuilder .bs-searchbox input[type="email"],
.bs-searchbox .FormBuilder input[type="file"], .FormBuilder .bs-searchbox input[type="file"],
.bs-searchbox .FormBuilder textarea, .FormBuilder .bs-searchbox textarea,
.bs-searchbox .FormBuilder select, .FormBuilder .bs-searchbox select {
  margin-bottom: 0;
  width: 100%;
  float: none; }

@font-face {
  font-family: "Apercu";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/ApercuPro-Light.woff2") format("woff2"), url("../fonts/ApercuPro-Light.woff") format("woff"), url("../fonts/ApercuPro-Light.ttf") format("truetype"); }

@font-face {
  font-family: "Apercu";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/ApercuPro-LightItalic.woff2") format("woff2"), url("../fonts/ApercuPro-LightItalic.woff") format("woff"), url("../fonts/ApercuPro-LightItalic.ttf") format("truetype"); }

@font-face {
  font-family: "Apercu";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/ApercuPro-Regular.woff2") format("woff2"), url("../fonts/ApercuPro-Regular.woff") format("woff"), url("../fonts/ApercuPro-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Apercu";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/ApercuPro-Italic.woff2") format("woff2"), url("../fonts/ApercuPro-Italic.woff") format("woff"), url("../fonts/ApercuPro-Italic.ttf") format("truetype"); }

@font-face {
  font-family: "Apercu";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/ApercuPro-Medium.woff2") format("woff2"), url("../fonts/ApercuPro-Medium.woff") format("woff"), url("../fonts/ApercuPro-Medium.ttf") format("truetype"); }

@font-face {
  font-family: "Apercu";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/ApercuPro-MediumItalic.woff2") format("woff2"), url("../fonts/ApercuPro-MediumItalic.woff") format("woff"), url("../fonts/ApercuPro-MediumItalic.ttf") format("truetype"); }

@font-face {
  font-family: "Apercu";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/ApercuPro-Bold.woff2") format("woff2"), url("../fonts/ApercuPro-Bold.woff") format("woff"), url("../fonts/ApercuPro-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "Apercu";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/ApercuPro-BoldItalic.woff2") format("woff2"), url("../fonts/ApercuPro-BoldItalic.woff") format("woff"), url("../fonts/ApercuPro-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: "NoeDisplay";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/NoeDisplay-Regular.woff2") format("woff2"), url("../fonts/NoeDisplay-Regular.woff") format("woff"), url("../fonts/NoeDisplay-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "NoeDisplay";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/NoeDisplay-Italic.woff2") format("woff2"), url("../fonts/NoeDisplay-Italic.woff") format("woff"), url("../fonts/NoeDisplay-Italic.ttf") format("truetype"); }

@font-face {
  font-family: "NoeDisplay";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/NoeDisplay-Medium.woff2") format("woff2"), url("../fonts/NoeDisplay-Medium.woff") format("woff"), url("../fonts/NoeDisplay-Medium.ttf") format("truetype"); }

@font-face {
  font-family: "NoeDisplay";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/NoeDisplay-MediumItalic.woff2") format("woff2"), url("../fonts/NoeDisplay-MediumItalic.woff") format("woff"), url("../fonts/NoeDisplay-MediumItalic.ttf") format("truetype"); }

@font-face {
  font-family: "NoeDisplay";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/NoeDisplay-Bold.woff2") format("woff2"), url("../fonts/NoeDisplay-Bold.woff") format("woff"), url("../fonts/NoeDisplay-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "NoeDisplay";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/NoeDisplay-BoldItalic.woff2") format("woff2"), url("../fonts/NoeDisplay-BoldItalic.woff") format("woff"), url("../fonts/NoeDisplay-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: "NoeDisplay";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/NoeDisplay-Black.woff2") format("woff2"), url("../fonts/NoeDisplay-Black.woff") format("woff"), url("../fonts/NoeDisplay-Black.ttf") format("truetype"); }

@font-face {
  font-family: "NoeDisplay";
  font-style: italic;
  font-weight: 900;
  src: url("../fonts/NoeDisplay-BlackItalic.woff2") format("woff2"), url("../fonts/NoeDisplay-BlackItalic.woff") format("woff"), url("../fonts/NoeDisplay-BlackItalic.ttf") format("truetype"); }

:root {
  --vh: 1vh; }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.2); }
  @media (min-width: 768px) {
    html {
      font-size: calc(0.22936vw + 14.23853px); } }
  @media (min-width: 1640px) {
    html {
      font-size: calc(0.71429vw + 6.28571px); } }
  @media (min-width: 1920px) {
    html {
      font-size: 20px; } }
  @media (max-width: 767.98px) {
    html {
      font-size: 16px; } }
  html.page-transitioning-out {
    overflow: hidden; }
  html.page-transitioning * {
    pointer-events: none;
    cursor: progress !important; }
  html.has-scroll-smooth {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
    html.has-scroll-smooth [data-scroll-speed] {
      will-change: transform; }

body {
  overscroll-behavior-y: none; }
  html:not(.is-first-run) body {
    opacity: 0; }

figure {
  margin: 0; }

img {
  width: 100%;
  max-width: 100%; }

a {
  transition: color 0.3s; }

a[href^="tel:"] {
  text-decoration: none !important; }

legend {
  margin-bottom: 1.5rem;
  font-size: 20.61537px;
  line-height: 1.265; }
  @media (min-width: 320px) and (max-width: 768px) {
    legend {
      font-size: calc( 20.61537px + 1.7683 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    legend {
      font-size: calc( 22.38367px + 0.66704 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    legend {
      font-size: calc( 23.05072px + 3.06619 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    legend {
      font-size: calc( 26.11691px + 0.56843 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    legend {
      font-size: calc( 26.68534px + 1.09983 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    legend {
      font-size: 27.78516px; } }

label {
  font-size: 0.875rem; }
  label:not(.custom-control-label) {
    font-weight: 700; }
  label a {
    font-weight: 500;
    text-decoration: underline;
    color: currentColor; }

h1, h2, h3, h4, h5, h6 {
  line-height: 1.15; }
  h1 sup,
  h2 sup,
  h3 sup,
  h4 sup,
  h5 sup,
  h6 sup {
    font-family: "Apercu", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 300;
    font-size: 65%;
    transform: translateY(-20%); }

hr {
  margin: 4rem 0 0.5rem;
  border: 0;
  border-top: 4px solid currentColor; }
  @media (min-width: 768px) {
    hr {
      margin: 4rem 0 1rem; } }

@keyframes rotate {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.text-uppercase {
  text-transform: uppercase; }

p.Lead {
  font-size: 20.61537px;
  font-weight: 700;
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.265; }
  @media (min-width: 320px) and (max-width: 768px) {
    p.Lead {
      font-size: calc( 20.61537px + 1.7683 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    p.Lead {
      font-size: calc( 22.38367px + 0.66704 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    p.Lead {
      font-size: calc( 23.05072px + 3.06619 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    p.Lead {
      font-size: calc( 26.11691px + 0.56843 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    p.Lead {
      font-size: calc( 26.68534px + 1.09983 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    p.Lead {
      font-size: 27.78516px; } }

.alert {
  color: #fff;
  font-weight: 400;
  background: #82ada2;
  border: none;
  margin-bottom: 1rem !important; }
  .alert.alert-error, .alert.alert-danger {
    background: #a34e5a; }
  .alert label {
    margin: 0;
    color: inherit; }

.alert-error + .alert-error,
.alert-danger + .alert-danger,
.alert-success + .alert-success,
.alert-warning + .alert-warning {
  padding-top: 0;
  margin-top: -1.5rem; }

.bootstrap-select > .dropdown-toggle:after {
  margin-top: 0; }

.bootstrap-select > .dropdown-toggle.bs-placeholder {
  color: currentColor !important; }

.bootstrap-select > select.mobile-device:focus + .dropdown-toggle,
.bootstrap-select .dropdown-toggle:focus {
  outline: none !important; }

.bootstrap-select .dropdown-menu {
  margin: -1px 0 0;
  border-top-color: #000; }

.custom-control-label:before, .custom-control-label:after {
  top: .2rem; }

.custom-control-input:checked ~ .custom-control-label:after, .FormBuilder .InputfieldCheckbox .InputfieldContent input:checked ~ .custom-control-label:after, .FormBuilder .InputfieldCheckboxes .InputfieldContent input:checked ~ .custom-control-label:after, .FormBuilder .InputfieldRadios .InputfieldContent input:checked ~ .custom-control-label:after {
  transform: scale(0.5);
  background: #000 !important; }

.custom-radio .custom-control-label:after {
  border-radius: 50%; }

.custom-file-input {
  cursor: pointer; }

.custom-file,
.custom-file-label {
  height: calc(1.6em + 1.4rem + 2px); }

.custom-file-label {
  padding-top: 1.1rem;
  font-size: 0.875rem;
  font-weight: 400 !important; }
  .custom-file-label:after {
    height: 100%;
    display: flex;
    align-items: center; }

.custom-file-input:focus + .custom-file-label {
  border-bottom: 1px solid; }

.dropdown-toggle {
  padding: 0;
  background-color: transparent;
  border: none;
  color: inherit;
  font-weight: inherit;
  display: flex !important;
  align-items: center; }
  .dropdown-toggle:focus {
    outline: none; }
  .dropdown.show .dropdown-toggle:after {
    transform: rotate(-180deg); }

.dropdown-item {
  font-weight: inherit; }

.dropdown-menu {
  font-size: inherit;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }
  .dropdown--flat .dropdown-menu {
    padding: 0;
    border: none;
    border-radius: 0; }

.fancybox-bg {
  background: #000; }
  .fancybox-is-open .fancybox-bg {
    opacity: 1; }

.fancybox-slide--image {
  padding: 8.53333vw ; }
  @media (min-width: 768px) {
    .fancybox-slide--image {
      padding: 6.66667vw ; } }

.fancybox-caption {
  padding: 4.26667vw ;
  padding-bottom: 1rem !important;
  font-size: 1rem; }
  @media (min-width: 768px) {
    .fancybox-caption {
      padding: 3.33333vw ; } }

.fancybox-button {
  width: 2rem;
  height: 2rem;
  padding: 0;
  font-size: 0;
  color: #fff;
  background-color: transparent; }
  .fancybox-button .Icon {
    width: 1.5rem;
    height: 1.5rem;
    stroke-width: 0.86667px; }

.fancybox-button--close {
  right: 2.13333vw ;
  top: 2.13333vw ;
  position: absolute; }
  @media (min-width: 768px) {
    .fancybox-button--close {
      right: 3.33333vw ; } }
  @media (min-width: 768px) {
    .fancybox-button--close {
      top: 1.66667vw ; } }

.fancybox-navigation {
  display: none; }
  .desktop .fancybox-navigation {
    display: block; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 4.26667vw ; }
  @media (min-width: 768px) {
    .fancybox-navigation .fancybox-button--arrow_left {
      left: 3.33333vw ; } }
  .fancybox-navigation .fancybox-button--arrow_left svg {
    transform: rotate(180deg); }

.fancybox-navigation .fancybox-button--arrow_right {
  right: 4.26667vw ; }
  @media (min-width: 768px) {
    .fancybox-navigation .fancybox-button--arrow_right {
      right: 3.33333vw ; } }

.fancybox-button[disabled] {
  opacity: 0 !important; }

.js-focus-visible :focus:not(.focus-visible),
.js-focus-visible button:focus:not(.focus-visible) {
  outline: none; }

.focus-visible, .bootstrap-select > select.mobile-device:focus + .dropdown-toggle.focus-visible,
.bootstrap-select .dropdown-toggle.focus-visible:focus,
.focus-visible + .custom-control-label:after,
.bootstrap-select > select.mobile-device:focus + .dropdown-toggle.focus-visible + .custom-control-label:after,
.bootstrap-select .dropdown-toggle.focus-visible:focus + .custom-control-label:after {
  outline: 2px solid rgba(127, 127, 127, 0.5) !important;
  outline-offset: 4px !important; }

input[type="text"].focus-visible, .bootstrap-select > select.mobile-device:focus + input.dropdown-toggle.focus-visible[type="text"],
.bootstrap-select input.dropdown-toggle.focus-visible[type="text"]:focus,
input[type="email"].focus-visible,
.bootstrap-select > select.mobile-device:focus + input.dropdown-toggle.focus-visible[type="email"],
.bootstrap-select input.dropdown-toggle.focus-visible[type="email"]:focus,
input[type="search"].focus-visible,
.bootstrap-select > select.mobile-device:focus + input.dropdown-toggle.focus-visible[type="search"],
.bootstrap-select input.dropdown-toggle.focus-visible[type="search"]:focus,
input[type="password"].focus-visible,
.bootstrap-select > select.mobile-device:focus + input.dropdown-toggle.focus-visible[type="password"],
.bootstrap-select input.dropdown-toggle.focus-visible[type="password"]:focus,
textarea.focus-visible,
.bootstrap-select > select.mobile-device:focus + textarea.dropdown-toggle.focus-visible,
.bootstrap-select textarea.dropdown-toggle.focus-visible:focus,
select.focus-visible,
.bootstrap-select > select.mobile-device:focus + select.dropdown-toggle.focus-visible,
.bootstrap-select select.dropdown-toggle.focus-visible:focus,
.bootstrap-select *.focus-visible,
.bootstrap-select > select.mobile-device:focus + *.dropdown-toggle.focus-visible,
.bootstrap-select *.dropdown-toggle.focus-visible:focus {
  outline: none !important; }

.form-field {
  position: relative; }
  .form-field [required="required"] ~ label:after {
    content: ' *';
    opacity: 0.5; }
  .form-field label {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    transition: transform 0.1s;
    transform-origin: 0 0;
    margin: 0;
    pointer-events: none; }
  .form-field textarea ~ label {
    top: 0.66rem; }
  .form-field input[type="text"].has-focus ~ label, .form-field input[type="text"].has-value ~ label,
  .form-field input[type="email"].has-focus ~ label,
  .form-field input[type="email"].has-value ~ label,
  .form-field select.has-focus ~ label,
  .form-field select.has-value ~ label,
  .form-field textarea.has-focus ~ label,
  .form-field textarea.has-value ~ label {
    transform: translateY(-75%) scale(0.75); }

.form-control, .bootstrap-select:not(.dropdown--flat) > .dropdown-toggle, .FormBuilder input[type="text"],
.FormBuilder input[type="email"],
.FormBuilder input[type="file"],
.FormBuilder textarea,
.FormBuilder select {
  padding-top: 1.1rem;
  height: calc(1.6em + 1.4rem + 2px); }
  .form-control:focus, .bootstrap-select:not(.dropdown--flat) > .dropdown-toggle:focus, .FormBuilder input:focus[type="text"],
  .FormBuilder input:focus[type="email"],
  .FormBuilder input:focus[type="file"],
  .FormBuilder textarea:focus,
  .FormBuilder select:focus {
    border-bottom: 1px solid; }

select.form-control, .bootstrap-select:not(.dropdown--flat) > select.dropdown-toggle,
.FormBuilder select {
  padding-left: 0.45rem; }

textarea.form-control, .bootstrap-select:not(.dropdown--flat) > textarea.dropdown-toggle,
.FormBuilder textarea {
  resize: none; }

.c-scrollbar {
  z-index: 1130; }

.c-scrollbar_thumb {
  border-radius: 0;
  margin: 0;
  background-color: #808080; }

.Accordion-title {
  font-size: 27px;
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.15;
  margin-bottom: 2rem; }
  [data-scroll-container] .Accordion-col .Accordion-title {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .Accordion-col.is-inview .Accordion-title {
    opacity: 1;
    transform: none !important; }
  @media (min-width: 320px) and (max-width: 768px) {
    .Accordion-title {
      font-size: calc( 27px + 5 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Accordion-title {
      font-size: calc( 32px + 2 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .Accordion-title {
      font-size: calc( 34px + 10 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .Accordion-title {
      font-size: calc( 44px + 2 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Accordion-title {
      font-size: calc( 46px + 4 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Accordion-title {
      font-size: 50px; } }

.Accordion-col {
  margin-left: auto; }
  @media (min-width: 768px) {
    .Accordion-col {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; } }
  @media (min-width: 992px) {
    .Accordion-col {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; } }

.Accordion-item {
  border-bottom: 1px solid #000; }
  [data-scroll-container] .Accordion-col .Accordion-item {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .Accordion-col.is-inview .Accordion-item {
    opacity: 1;
    transform: none !important; }
  .Accordion-item:nth-child(n+1) {
    transition-delay: 0.15s !important; }
  .Accordion-item:nth-child(n+2) {
    transition-delay: 0.25s !important; }
  .Accordion-item:nth-child(n+3) {
    transition-delay: 0.35s !important; }
  .Accordion-item:nth-child(n+4) {
    transition-delay: 0.45s !important; }
  .Accordion-item:nth-child(n+5) {
    transition-delay: 0.55s !important; }
  .Accordion-item:nth-child(n+6) {
    transition-delay: 0.65s !important; }
  .Accordion-item:nth-child(n+7) {
    transition-delay: 0.75s !important; }
  .Accordion-item:nth-child(n+8) {
    transition-delay: 0.85s !important; }
  .Accordion-item:nth-child(n+9) {
    transition-delay: 0.95s !important; }
  .Accordion-item:nth-child(n+10) {
    transition-delay: 1.05s !important; }

.Accordion-item-toggle {
  padding: 1.25rem 0;
  display: flex;
  align-items: center;
  cursor: pointer; }
  .Accordion-item-toggle .Icon {
    margin-left: 1.5rem; }
  .Accordion-item-toggle.is-open .Icon {
    transform: rotate(180deg); }

.Accordion-item-title {
  font-size: 20.61537px;
  transition: opacity .3s;
  font-weight: 400;
  margin: 0 auto 0 0; }
  @media (min-width: 320px) and (max-width: 768px) {
    .Accordion-item-title {
      font-size: calc( 20.61537px + 1.7683 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Accordion-item-title {
      font-size: calc( 22.38367px + 0.66704 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .Accordion-item-title {
      font-size: calc( 23.05072px + 3.06619 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .Accordion-item-title {
      font-size: calc( 26.11691px + 0.56843 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Accordion-item-title {
      font-size: calc( 26.68534px + 1.09983 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Accordion-item-title {
      font-size: 27.78516px; } }
  .desktop .Accordion-item-toggle:hover .Accordion-item-title {
    opacity: 0.7; }

.Accordion-item-prose {
  padding-bottom: 2rem; }

.ActionBar {
  border-top: 1px solid #000;
  background-color: #fff;
  z-index: 10;
  transition: 0.3s transform; }
  [data-scroll-container] .ActionBar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%); }
    [data-scroll-container] .ActionBar.is-active {
      transform: translateY(0); }
  .show-menu .ActionBar {
    transform: translateY(100%) !important; }

.ActionBar-wrapper {
  padding: .75rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.ActionBar-content {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  flex-grow: 0; }

.ActionBar-title {
  font-weight: 700;
  margin-right: 1rem; }

.ActionBar-subtitle {
  margin-right: 2rem; }

.ActionBar-button.Button, .ActionBar-button.ButtonPrimary, .ActionBar-button.ButtonPrimaryRight, .ActionBar-button.ButtonPrimaryDown, .ActionBar-button.ButtonSecondary, .ActionBar-button.ButtonSecondaryRight, .ActionBar-button.ButtonSecondaryDown, .ActionBar-button.ButtonUnderline, .ActionBar-button.ButtonUnderlineRight, .ActionBar-button.ButtonUnderlineDown, .FormBuilder button.ActionBar-button:not(.dropdown-toggle) {
  white-space: nowrap; }
  @media (max-width: 767.98px) {
    .ActionBar-button.Button, .ActionBar-button.ButtonPrimary, .ActionBar-button.ButtonPrimaryRight, .ActionBar-button.ButtonPrimaryDown, .ActionBar-button.ButtonSecondary, .ActionBar-button.ButtonSecondaryRight, .ActionBar-button.ButtonSecondaryDown, .ActionBar-button.ButtonUnderline, .ActionBar-button.ButtonUnderlineRight, .ActionBar-button.ButtonUnderlineDown, .FormBuilder button.ActionBar-button:not(.dropdown-toggle) {
      min-width: 100px;
      justify-content: center; }
      .ActionBar-button.Button::after, .ActionBar-button.ButtonPrimary::after, .ActionBar-button.ButtonPrimaryRight::after, .ActionBar-button.ButtonPrimaryDown::after, .ActionBar-button.ButtonSecondary::after, .ActionBar-button.ButtonSecondaryRight::after, .ActionBar-button.ButtonSecondaryDown::after, .ActionBar-button.ButtonUnderline::after, .ActionBar-button.ButtonUnderlineRight::after, .ActionBar-button.ButtonUnderlineDown::after, .FormBuilder button.ActionBar-button:not(.dropdown-toggle)::after {
        display: none; } }

.BannerColor {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: currentColor; }
  @media (min-width: 768px) {
    .BannerColor {
      padding-top: 8.33333vw ;
      padding-bottom: 8.33333vw ; } }

.BannerColor-col {
  text-align: center; }
  [data-scroll-container] .BannerColor-col {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .BannerColor-col.is-inview {
    opacity: 1;
    transform: none !important; }

.BannerColor-title {
  font-size: 25.08462px;
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 900;
  margin: 0 auto .5em auto; }
  @media (min-width: 320px) and (max-width: 768px) {
    .BannerColor-title {
      font-size: calc( 25.08462px + 3.94341 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .BannerColor-title {
      font-size: calc( 29.02803px + 1.55243 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .BannerColor-title {
      font-size: calc( 30.58046px + 7.58509 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .BannerColor-title {
      font-size: calc( 38.16555px + 1.48616 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .BannerColor-title {
      font-size: calc( 39.65171px + 2.94556 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .BannerColor-title {
      font-size: 42.59727px; } }
  [data-text-inverted] .BannerColor-title {
    color: #fff; }

.BannerHero {
  position: relative; }

.BannerHero-banner {
  position: relative; }

.BannerHero-content {
  padding-top: 4.26667vw ;
  padding-bottom: 4.26667vw ;
  padding-left: 12.8vw ;
  padding-right: 12.8vw ;
  position: relative;
  min-height: 120vw; }
  @media (min-width: 768px) {
    .BannerHero-content {
      padding-top: 3.33333vw ;
      padding-bottom: 3.33333vw ; } }
  @media (min-width: 768px) {
    .BannerHero-content {
      padding-left: 10vw ;
      padding-right: 10vw ; } }
  @media (min-width: 768px) {
    .BannerHero-content {
      min-height: 50vw; } }
  .BannerHero--center .BannerHero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }

.BannerHero-title {
  font-size: 36.24002px;
  text-indent: -8.53333vw ;
  font-weight: 900;
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #fff;
  margin-bottom: 0.5em; }
  @media (min-width: 320px) and (max-width: 768px) {
    .BannerHero-title {
      font-size: calc( 36.24002px + 11.01842 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .BannerHero-title {
      font-size: calc( 47.25844px + 4.69548 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .BannerHero-title {
      font-size: calc( 51.95392px + 25.77405 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .BannerHero-title {
      font-size: calc( 77.72797px + 5.59057 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .BannerHero-title {
      font-size: calc( 83.31853px + 11.59391 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .BannerHero-title {
      font-size: 94.91244px; } }
  @media (min-width: 768px) {
    .BannerHero-title {
      text-indent: -6.66667vw ; } }

.BannerHero-media,
.BannerHero-wrapper,
.BannerHero-wrapper:after,
.BannerHero-image,
.BannerHero-video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.BannerHero-media {
  background-color: rgba(0, 0, 0, 0.06);
  overflow: hidden; }

.BannerHero-wrapper {
  transition: opacity 0.3s; }
  .BannerHero-wrapper > * {
    transition: transform 3s cubic-bezier(0.23, 1, 0.32, 1); }
  .Main .BannerHero-wrapper {
    opacity: 0; }
    .Main .BannerHero-wrapper > * {
      transform: scale3d(1.05, 1.05, 1.05); }
  .Main .BannerHero-wrapper.is-inview.lazyloaded {
    opacity: 1; }
    .Main .BannerHero-wrapper.is-inview.lazyloaded > * {
      transform: scale3d(1, 1, 1); }
  .has-scroll-smooth .BannerHero-wrapper {
    top: calc(var(--vh) * -10);
    bottom: calc(var(--vh) * -10); }
  .BannerHero-wrapper:after {
    content: '';
    background-color: #000;
    opacity: 0.2; }

.BannerHero-image {
  background: center/cover no-repeat; }

.BannerHero-video:not(.lazyloaded) {
  opacity: 0; }

.BannerHero-video video {
  position: absolute; }

.BannerSlides-containerPoster {
  display: none;
  height: calc(var(--vh) * 90);
  position: relative; }
  .desktop .BannerSlides-containerPoster {
    display: block; }

.BannerSlides-linkWrapper {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.BannerSlides-link {
  color: #fff !important;
  text-decoration: none !important;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  border-right: 1px solid currentColor; }
  .BannerSlides-link:nth-child(1) {
    align-items: flex-end;
    justify-content: flex-start; }
    .BannerSlides-link:nth-child(1) .BannerSlides-title {
      text-indent: 1em;
      transform: translateY(2em);
      max-width: 90%; }
      .BannerSlides-link:nth-child(1) .BannerSlides-title:first-line {
        text-align: right; }
  .BannerSlides-link:nth-child(2) {
    justify-content: flex-end; }
    .BannerSlides-link:nth-child(2) .BannerSlides-title {
      text-indent: 1em;
      transform: translateY(-2em); }
  .BannerSlides-link:nth-child(3) {
    border-right: 0;
    justify-content: center; }
    .BannerSlides-link:nth-child(3) .BannerSlides-title {
      text-indent: -1em;
      padding-left: 1em;
      transform: translateY(-2em); }

.BannerSlides-title {
  font-size: 29.3002px;
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  opacity: 0.6;
  color: currentColor;
  transition: 0.3s opacity;
  margin: 0;
  will-change: opacity; }
  @media (min-width: 320px) and (max-width: 768px) {
    .BannerSlides-title {
      font-size: calc( 29.3002px + 6.36017 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .BannerSlides-title {
      font-size: calc( 35.66038px + 2.58939 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .BannerSlides-title {
      font-size: calc( 38.24976px + 13.28478 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .BannerSlides-title {
      font-size: calc( 51.53454px + 2.71799 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .BannerSlides-title {
      font-size: calc( 54.25253px + 5.49092 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .BannerSlides-title {
      font-size: 59.74345px; } }
  a:hover .BannerSlides-title {
    opacity: 1; }

.BannerSlides-image,
.BannerSlides-figure {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.BannerSlides-figure {
  background-color: rgba(0, 0, 0, 0.06);
  overflow: hidden;
  opacity: 0;
  transition: 0.6s opacity;
  pointer-events: none;
  will-change: opacity; }
  .BannerSlides-figure:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3); }
  .BannerSlides-figure.is-active {
    opacity: 1; }

.BannerSlides-image {
  transition: opacity 0.3s, transform 1.8s cubic-bezier(0.23, 1, 0.32, 1);
  background: center/cover no-repeat; }
  .Main .BannerSlides-image {
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1.05); }
  .Main .is-inview .BannerSlides-image.lazyloaded {
    opacity: 1;
    transform: scale3d(1, 1, 1); }

.BannerSlides-containerCards {
  overflow: hidden; }
  [data-scroll-container] .BannerSlides-containerCards {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .BannerSlides-containerCards.is-inview {
    opacity: 1;
    transform: none !important; }
  .desktop .BannerSlides-containerCards {
    display: none; }
  .BannerSlides-containerCards .swiper-container {
    overflow: visible; }
  .BannerSlides-containerCards .swiper-wrapper {
    align-items: center; }
  .BannerSlides-containerCards .swiper-slide {
    margin-right: 4.26667vw ;
    width: 80%; }
    @media (min-width: 768px) {
      .BannerSlides-containerCards .swiper-slide {
        margin-right: 3.33333vw ; } }
    @media (min-width: 768px) {
      .BannerSlides-containerCards .swiper-slide {
        width: 40%; } }
    .BannerSlides-containerCards .swiper-slide:last-child {
      margin-right: 0; }

.BannerSlides-card {
  text-decoration: none !important; }

.BannerSlides-cardFigure {
  display: block;
  position: relative;
  background-color: rgba(0, 0, 0, 0.06);
  padding-bottom: 75%;
  overflow: hidden; }

.BannerSlides-cardImage {
  transition: opacity 0.3s, transform 1.8s cubic-bezier(0.23, 1, 0.32, 1);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: center/cover no-repeat; }
  .Main .BannerSlides-cardImage {
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1.05); }
  .Main .is-inview .BannerSlides-cardImage.lazyloaded {
    opacity: 1;
    transform: scale3d(1, 1, 1); }

.BannerSlides-cardTitle {
  margin: 1rem 0;
  font-size: 26.1313px;
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
  @media (min-width: 320px) and (max-width: 768px) {
    .BannerSlides-cardTitle {
      font-size: calc( 26.1313px + 4.512 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .BannerSlides-cardTitle {
      font-size: calc( 30.6433px + 1.79207 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .BannerSlides-cardTitle {
      font-size: calc( 32.43538px + 8.86884 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .BannerSlides-cardTitle {
      font-size: calc( 41.30422px + 1.75766 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .BannerSlides-cardTitle {
      font-size: calc( 43.06188px + 3.50122 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .BannerSlides-cardTitle {
      font-size: 46.5631px; } }
  .BannerSlides-cardTitle a {
    text-decoration: none; }

.BannerText-item {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 2rem; }
  @media (min-width: 768px) {
    .BannerText-item {
      padding-right: 1.11111vw;
      padding-left: 1.11111vw; } }
  .BannerText-item > * {
    vertical-align: middle;
    display: inline-block; }
  @media (min-width: 768px) {
    .BannerText-item {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
      .BannerText-item:nth-child(even) {
        margin-left: 8.33333%; } }
  @media (min-width: 992px) {
    .BannerText-item {
      margin-bottom: 3rem;
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
      margin-left: 8.33333%; }
      .BannerText-item:nth-child(even) {
        margin-left: 16.66667%; } }

.BannerText-caption {
  margin-bottom: 6rem; }
  @media (min-width: 768px) {
    .BannerText-caption {
      margin-bottom: 5.55556vw ; } }
  @media (max-width: 767.98px) {
    .BannerText-caption {
      margin-bottom: 2rem; } }

.BannerText-text {
  font-size: 33.39501px;
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 900;
  margin-bottom: 0.5rem;
  margin-right: 1.5rem; }
  @media (min-width: 320px) and (max-width: 768px) {
    .BannerText-text {
      font-size: calc( 33.39501px + 9.01256 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .BannerText-text {
      font-size: calc( 42.40757px + 3.77398 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .BannerText-text {
      font-size: calc( 46.18155px + 20.1823 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .BannerText-text {
      font-size: calc( 66.36385px + 4.28083 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .BannerText-text {
      font-size: calc( 70.64468px + 8.78866 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .BannerText-text {
      font-size: 79.43334px; } }
  @media (max-width: 767.98px) {
    .BannerText-text {
      margin-right: 2rem; } }
  .BannerText-text a {
    text-decoration: none !important;
    position: relative; }
    .BannerText-text a:after {
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      bottom: 0.45em;
      transform: translateY(25%);
      content: '';
      width: 1rem;
      height: 1rem;
      margin-left: 1.2rem;
      background-color: transparent;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewbox='0 0 16 16' style='fill: none; stroke: %23000; stroke-width: 1.3px'%3E%3Cpath d='M9.1 3.1L14 8l-4.9 4.9M2 8h12'/%3E%3C/svg%3E");
      background-size: 100% 100%;
      background-repeat: no-repeat; }
    @media (min-width: 768px) {
      .BannerText-text a {
        pointer-events: none; }
        .BannerText-text a:after {
          display: none; } }

.BannerText-linkWrapper {
  font-size: 33.39501px;
  line-height: 0.5;
  padding-bottom: 0.35em; }
  @media (min-width: 320px) and (max-width: 768px) {
    .BannerText-linkWrapper {
      font-size: calc( 33.39501px + 9.01256 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .BannerText-linkWrapper {
      font-size: calc( 42.40757px + 3.77398 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .BannerText-linkWrapper {
      font-size: calc( 46.18155px + 20.1823 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .BannerText-linkWrapper {
      font-size: calc( 66.36385px + 4.28083 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .BannerText-linkWrapper {
      font-size: calc( 70.64468px + 8.78866 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .BannerText-linkWrapper {
      font-size: 79.43334px; } }
  @media (max-width: 767.98px) {
    .BannerText-linkWrapper {
      display: none; } }

.BannerText-link {
  flex-shrink: 0; }

.Button, .ButtonPrimary, .ButtonPrimaryRight, .ButtonPrimaryDown, .ButtonSecondary, .ButtonSecondaryRight, .ButtonSecondaryDown, .ButtonUnderline, .ButtonUnderlineRight, .ButtonUnderlineDown, .FormBuilder button:not(.dropdown-toggle) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 .75rem;
  height: calc(3.2rem + 2px);
  min-width: 12rem;
  letter-spacing: 0.02em;
  text-decoration: none !important;
  font-size: calc(1rem - 2px);
  font-weight: 500;
  appearance: none;
  border: 0;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: color 0.1s linear 0.05s, padding 0.6s; }
  .Button:before, .ButtonPrimary:before, .ButtonPrimaryRight:before, .ButtonPrimaryDown:before, .ButtonSecondary:before, .ButtonSecondaryRight:before, .ButtonSecondaryDown:before, .ButtonUnderline:before, .ButtonUnderlineRight:before, .ButtonUnderlineDown:before, .FormBuilder button:not(.dropdown-toggle):before {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transform: translateY(102%);
    transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0.15; }
  .Button:hover:before, .ButtonPrimary:hover:before, .ButtonPrimaryRight:hover:before, .ButtonPrimaryDown:hover:before, .ButtonSecondary:hover:before, .ButtonSecondaryRight:hover:before, .ButtonSecondaryDown:hover:before, .ButtonUnderline:hover:before, .ButtonUnderlineRight:hover:before, .ButtonUnderlineDown:hover:before, .FormBuilder button:not(.dropdown-toggle):hover:before {
    transform: translateY(0); }

.Button--inverted,
[data-text-inverted] .Button,
[data-text-inverted] .ButtonPrimary,
[data-text-inverted] .ButtonPrimaryRight,
[data-text-inverted] .ButtonPrimaryDown,
[data-text-inverted] .ButtonSecondary,
[data-text-inverted] .ButtonSecondaryRight,
[data-text-inverted] .ButtonSecondaryDown,
[data-text-inverted] .ButtonUnderline,
[data-text-inverted] .ButtonUnderlineRight,
[data-text-inverted] .ButtonUnderlineDown,
[data-text-inverted] .FormBuilder button:not(.dropdown-toggle),
.FormBuilder [data-text-inverted] button:not(.dropdown-toggle) {
  filter: invert(1); }

.Button--primary, .ButtonPrimary, .ButtonPrimaryRight, .ButtonPrimaryDown, .FormBuilder button:not(.dropdown-toggle) {
  color: #fff;
  background-color: #000; }
  .Button--primary:before, .ButtonPrimary:before, .ButtonPrimaryRight:before, .ButtonPrimaryDown:before, .FormBuilder button:not(.dropdown-toggle):before {
    background-color: currentColor;
    opacity: 0.15; }
  .Button--primary:hover, .ButtonPrimary:hover, .ButtonPrimaryRight:hover, .ButtonPrimaryDown:hover, .FormBuilder button:hover:not(.dropdown-toggle) {
    color: #fff; }
  .Button--primary:after, .ButtonPrimary:after, .ButtonPrimaryRight:after, .ButtonPrimaryDown:after, .FormBuilder button:not(.dropdown-toggle):after {
    filter: invert(1); }

.Button--secondary, .ButtonSecondary, .ButtonSecondaryRight, .ButtonSecondaryDown {
  color: #000;
  background: transparent;
  box-shadow: inset 1px 1px #000, inset -1px -1px #000; }
  .Button--secondary:hover, .ButtonSecondary:hover, .ButtonSecondaryRight:hover, .ButtonSecondaryDown:hover {
    color: #fff; }
  .Button--secondary:before, .ButtonSecondary:before, .ButtonSecondaryRight:before, .ButtonSecondaryDown:before {
    background: #000;
    opacity: 1; }
  .Button--secondary:hover:after, .ButtonSecondary:hover:after, .ButtonSecondaryRight:hover:after, .ButtonSecondaryDown:hover:after {
    filter: invert(1); }

@media (max-width: 767.98px) {
  .Button--underline, .ButtonUnderline, .ButtonUnderlineRight, .ButtonUnderlineDown {
    color: #fff;
    background-color: #000; }
    .Button--underline:before, .ButtonUnderline:before, .ButtonUnderlineRight:before, .ButtonUnderlineDown:before {
      background-color: currentColor;
      opacity: 0.15; }
    .Button--underline:hover, .ButtonUnderline:hover, .ButtonUnderlineRight:hover, .ButtonUnderlineDown:hover {
      color: #fff; }
    .Button--underline:after, .ButtonUnderline:after, .ButtonUnderlineRight:after, .ButtonUnderlineDown:after {
      filter: invert(1); } }

@media (min-width: 768px) {
  .Button--underline, .ButtonUnderline, .ButtonUnderlineRight, .ButtonUnderlineDown {
    padding: 0;
    color: #000;
    background: transparent;
    box-shadow: inset 0 -3px #000; }
    .Button--underline:before, .ButtonUnderline:before, .ButtonUnderlineRight:before, .ButtonUnderlineDown:before {
      background-color: #000;
      opacity: 1; }
    .Button--underline:hover, .ButtonUnderline:hover, .ButtonUnderlineRight:hover, .ButtonUnderlineDown:hover {
      color: #fff;
      padding: 0 0.75rem; }
    .Button--underline:hover:after, .ButtonUnderline:hover:after, .ButtonUnderlineRight:hover:after, .ButtonUnderlineDown:hover:after {
      filter: invert(1); } }

.Button--right, .ButtonPrimaryRight, .ButtonSecondaryRight, .ButtonUnderlineRight, .FormBuilder button:not(.dropdown-toggle),
.Button--down,
.ButtonPrimaryDown,
.ButtonSecondaryDown,
.ButtonUnderlineDown {
  justify-content: space-between; }
  .Button--right:after, .ButtonPrimaryRight:after, .ButtonSecondaryRight:after, .ButtonUnderlineRight:after, .FormBuilder button:not(.dropdown-toggle):after,
  .Button--down:after,
  .ButtonPrimaryDown:after,
  .ButtonSecondaryDown:after,
  .ButtonUnderlineDown:after {
    content: '';
    width: 1rem;
    height: 1rem;
    margin-left: 1rem;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewbox='0 0 16 16' style='fill: none; stroke: %23000; stroke-width: 1.3px'%3E%3Cpath d='M9.1 3.1L14 8l-4.9 4.9M2 8h12'/%3E%3C/svg%3E");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition: filter 0.1s linear 0.1s; }


.Button--down:after,
.ButtonPrimaryDown:after,
.ButtonSecondaryDown:after,
.ButtonUnderlineDown:after {
  transform: rotate(90deg); }

.Button.is-loading, .is-loading.ButtonPrimary, .is-loading.ButtonPrimaryRight, .is-loading.ButtonPrimaryDown, .is-loading.ButtonSecondary, .is-loading.ButtonSecondaryRight, .is-loading.ButtonSecondaryDown, .is-loading.ButtonUnderline, .is-loading.ButtonUnderlineRight, .is-loading.ButtonUnderlineDown, .FormBuilder button.is-loading:not(.dropdown-toggle) {
  pointer-events: none; }
  .Button.is-loading:before, .is-loading.ButtonPrimary:before, .is-loading.ButtonPrimaryRight:before, .is-loading.ButtonPrimaryDown:before, .is-loading.ButtonSecondary:before, .is-loading.ButtonSecondaryRight:before, .is-loading.ButtonSecondaryDown:before, .is-loading.ButtonUnderline:before, .is-loading.ButtonUnderlineRight:before, .is-loading.ButtonUnderlineDown:before, .FormBuilder button.is-loading:not(.dropdown-toggle):before, .Button.is-loading:after, .is-loading.ButtonPrimary:after, .is-loading.ButtonPrimaryRight:after, .is-loading.ButtonPrimaryDown:after, .is-loading.ButtonSecondary:after, .is-loading.ButtonSecondaryRight:after, .is-loading.ButtonSecondaryDown:after, .is-loading.ButtonUnderline:after, .is-loading.ButtonUnderlineRight:after, .is-loading.ButtonUnderlineDown:after, .FormBuilder button.is-loading:not(.dropdown-toggle):after {
    content: '';
    position: absolute; }
  .Button.is-loading:before, .is-loading.ButtonPrimary:before, .is-loading.ButtonPrimaryRight:before, .is-loading.ButtonPrimaryDown:before, .is-loading.ButtonSecondary:before, .is-loading.ButtonSecondaryRight:before, .is-loading.ButtonSecondaryDown:before, .is-loading.ButtonUnderline:before, .is-loading.ButtonUnderlineRight:before, .is-loading.ButtonUnderlineDown:before, .FormBuilder button.is-loading:not(.dropdown-toggle):before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    transform: none;
    z-index: 1;
    opacity: 1; }
  .Button.is-loading:after, .is-loading.ButtonPrimary:after, .is-loading.ButtonPrimaryRight:after, .is-loading.ButtonPrimaryDown:after, .is-loading.ButtonSecondary:after, .is-loading.ButtonSecondaryRight:after, .is-loading.ButtonSecondaryDown:after, .is-loading.ButtonUnderline:after, .is-loading.ButtonUnderlineRight:after, .is-loading.ButtonUnderlineDown:after, .FormBuilder button.is-loading:not(.dropdown-toggle):after {
    background: none !important;
    z-index: 2;
    width: 1rem;
    height: 1rem;
    top: 50%;
    left: 50%;
    transform-origin: 50% 50%;
    margin-top: -0.5rem;
    margin-left: -0.5rem;
    border: 2px solid #000;
    border-left-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border-left-color: rgba;
    animation: rotate 0.8s linear infinite; }

.Button.Button--secondary.is-loading:before, .Button--secondary.is-loading.ButtonPrimary:before, .Button--secondary.is-loading.ButtonPrimaryRight:before, .Button--secondary.is-loading.ButtonPrimaryDown:before, .is-loading.ButtonSecondary:before, .is-loading.ButtonSecondaryRight:before, .is-loading.ButtonSecondaryDown:before, .Button--secondary.is-loading.ButtonUnderline:before, .Button--secondary.is-loading.ButtonUnderlineRight:before, .Button--secondary.is-loading.ButtonUnderlineDown:before, .FormBuilder button.Button--secondary.is-loading:not(.dropdown-toggle):before {
  background-color: #fff;
  box-shadow: inset 1px 1px #000, inset -1px -1px #000; }

.Calendar {
  --fc-small-font-size: calc(1rem - 2px);
  --fc-event-text-color: #000;
  --fc-event-bg-color: #ddd;
  --fc-button-border-color: #000;
  --fc-button-bg-color: #000;
  --fc-button-hover-bg-color: #333;
  --fc-button-hover-border-color: #333;
  --calendar-intensive: #d6a288;
  --calendar-mw-detail: #e7dcd4;
  --calendar-residence: #c3d5cb;
  --calendar-teacher-training: #ffc380;
  --calendar-event: #bdd0db; }
  [data-scroll-container] .Calendar > .container {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .Calendar > .container.is-inview {
    opacity: 1;
    transform: none !important; }

@media (min-width: 768px) {
  .Calendar-aside {
    flex: 0 0 25%;
    max-width: 25%; } }

@media (max-width: 767.98px) {
  .Calendar-aside {
    order: 2;
    margin-top: 2rem; } }

@media (min-width: 768px) {
  .Calendar-main {
    flex: 0 0 75%;
    max-width: 75%; } }

.Calendar-legendList {
  list-style: none;
  margin: 0;
  padding: 0; }

.Calendar-legend {
  display: flex;
  align-items: center; }
  .Calendar-legend:before {
    content: '';
    width: 0.75em;
    height: 0.75em;
    border-radius: 50%;
    background: #000;
    margin-right: 0.5em; }
  .Calendar-legend.--intensive:before {
    background: var(--calendar-intensive); }
  .Calendar-legend.--mw-detail:before {
    background: var(--calendar-mw-detail); }
  .Calendar-legend.--residence:before {
    background: var(--calendar-residence); }
  .Calendar-legend.--teacher-training:before {
    background: var(--calendar-teacher-training); }
  .Calendar-legend.--event:before {
    background: var(--calendar-event); }

.fc-toolbar-title:first-letter {
  text-transform: uppercase; }

.fc-daygrid-day-number {
  font-size: calc(1rem - 2px);
  line-height: 1.5; }

.fc-daygrid-event {
  border-radius: 0;
  border: 0; }

.fc-daygrid-block-event .fc-event-time {
  font-weight: 400; }

.fc-daygrid-block-event .fc-event-title {
  font-weight: 700; }
  .fc-daygrid-block-event .fc-event-title:first-child {
    padding-left: 0.2em; }

.fc .fc-daygrid-day.fc-day-today {
  background: #f2f2f2; }

.tpl-intensive .fc-list-event-dot {
  border-color: var(--calendar-intensive); }

.tpl-mw-detail .fc-list-event-dot {
  border-color: var(--calendar-mw-detail); }

.tpl-residence .fc-list-event-dot {
  border-color: var(--calendar-residence); }

.tpl-teacher-training .fc-list-event-dot {
  border-color: var(--calendar-teacher-training); }

.tpl-event .fc-list-event-dot {
  border-color: var(--calendar-event); }

.fc-daygrid-dot-event:hover {
  background: none; }

.fc-h-event {
  padding: 2px 0; }
  .fc-h-event.tpl-intensive {
    background-color: var(--calendar-intensive); }
  .fc-h-event.tpl-mw-detail {
    background-color: var(--calendar-mw-detail); }
  .fc-h-event.tpl-residence {
    background-color: var(--calendar-residence); }
  .fc-h-event.tpl-teacher-training {
    background-color: var(--calendar-teacher-training); }
  .fc-h-event.tpl-event {
    background-color: var(--calendar-event); }

.fc .fc-toolbar-title {
  font-size: 22.92715px; }
  @media (min-width: 320px) and (max-width: 768px) {
    .fc .fc-toolbar-title {
      font-size: calc( 22.92715px + 2.84069 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .fc .fc-toolbar-title {
      font-size: calc( 25.76785px + 1.09668 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .fc .fc-toolbar-title {
      font-size: calc( 26.86453px + 5.21007 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .fc .fc-toolbar-title {
      font-size: calc( 32.0746px + 0.99535 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .fc .fc-toolbar-title {
      font-size: calc( 33.06995px + 1.95112 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .fc .fc-toolbar-title {
      font-size: 35.02107px; } }

.fc .fc-today-button {
  font-size: 19.46868px;
  text-transform: lowercase;
  color: #000 !important;
  background: none !important;
  border: 0 !important;
  padding: 0;
  border-radius: 0;
  box-shadow: none !important; }
  @media (min-width: 320px) and (max-width: 768px) {
    .fc .fc-today-button {
      font-size: calc( 19.46868px + 1.28076 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .fc .fc-today-button {
      font-size: calc( 20.74943px + 0.47713 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .fc .fc-today-button {
      font-size: calc( 21.22656px + 2.1548 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .fc .fc-today-button {
      font-size: calc( 23.38136px + 0.39302 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .fc .fc-today-button {
      font-size: calc( 23.77438px + 0.75512 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .fc .fc-today-button {
      font-size: 24.5295px; } }

.fc .fc-button {
  border-radius: 0; }

.fc .fc-button.fc-prev-button,
.fc .fc-button.fc-next-button {
  padding: 0;
  font-size: 0;
  width: 2.5rem;
  height: 2.5rem;
  box-shadow: none !important; }

.fc .fc-button .fc-icon-chevron-right,
.fc .fc-button .fc-icon-chevron-left {
  width: 1rem;
  height: 1rem; }
  .fc .fc-button .fc-icon-chevron-right:before,
  .fc .fc-button .fc-icon-chevron-left:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewbox='0 0 16 16' style='fill: none; stroke: %23fff; stroke-width: 1.3px'%3E%3Cpath d='M9.1 3.1L14 8l-4.9 4.9M2 8h12'/%3E%3C/svg%3E");
    background-size: 100% 100%;
    background-repeat: no-repeat; }

.fc .fc-button .fc-icon-chevron-left:before {
  transform: rotate(180deg); }

a.fc-col-header-cell-cushion,
a.fc-daygrid-day-number {
  text-decoration: none !important; }

.Card {
  width: 100%;
  display: flex;
  flex-direction: column; }

.Card-media {
  position: relative; }

.Card-figure {
  position: relative;
  padding-bottom: 75%;
  background-color: rgba(0, 0, 0, 0.06);
  margin-bottom: 1rem;
  overflow: hidden; }

.Card-image {
  transition: opacity 0.3s, transform 1.8s cubic-bezier(0.23, 1, 0.32, 1);
  background: center/cover no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .Main .Card-image {
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1.05); }
  .Main .is-inview .Card-image.lazyloaded {
    opacity: 1;
    transform: scale3d(1, 1, 1); }
  .desktop a:hover .Card-image {
    transition-duration: 6s;
    transform: scale(1.1) !important; }

.Card-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1; }
  .Card-content > *:last-child {
    margin-bottom: 0; }

.Card-label {
  text-transform: uppercase;
  font-weight: 500;
  margin: 0; }

.Card-label--1 {
  color: #e0c09f; }

.Card-label--2 {
  color: #d5a286; }

.Card-title {
  font-size: 22.92715px;
  font-weight: 400;
  margin-bottom: 1rem; }
  @media (min-width: 320px) and (max-width: 768px) {
    .Card-title {
      font-size: calc( 22.92715px + 2.84069 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Card-title {
      font-size: calc( 25.76785px + 1.09668 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .Card-title {
      font-size: calc( 26.86453px + 5.21007 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .Card-title {
      font-size: calc( 32.0746px + 0.99535 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Card-title {
      font-size: calc( 33.06995px + 1.95112 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Card-title {
      font-size: 35.02107px; } }
  .Card-title a {
    transition: opacity .3s;
    text-decoration: none; }
    .desktop .Card-title a:hover {
      opacity: 0.7; }

.Card-subtitle {
  font-style: italic; }
  .Card-subtitle:before {
    content: '- '; }

.Card-description {
  line-height: 1.5;
  font-weight: bold;
  margin-top: -0.5rem;
  margin-bottom: 1rem; }

.Card-abstract {
  line-height: 1.5;
  color: #999999;
  margin-bottom: 1.5rem; }

.Card-date {
  margin-top: auto; }

.CardList-container > .row {
  justify-content: center; }

.CardList-nav {
  font-size: 19.46868px;
  margin-bottom: 3rem; }
  [data-scroll-container] .CardList-nav {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .CardList-nav.is-inview {
    opacity: 1;
    transform: none !important; }
  @media (min-width: 320px) and (max-width: 768px) {
    .CardList-nav {
      font-size: calc( 19.46868px + 1.28076 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .CardList-nav {
      font-size: calc( 20.74943px + 0.47713 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .CardList-nav {
      font-size: calc( 21.22656px + 2.1548 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .CardList-nav {
      font-size: calc( 23.38136px + 0.39302 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .CardList-nav {
      font-size: calc( 23.77438px + 0.75512 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .CardList-nav {
      font-size: 24.5295px; } }
  .CardList-nav ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0; }
  .CardList-nav li.is-active {
    position: relative; }
    .CardList-nav li.is-active:before, .CardList-nav li.is-active:after {
      content: '';
      position: absolute;
      width: 0.7em;
      height: 2px;
      background-color: currentColor;
      left: 0; }
    .CardList-nav li.is-active:before {
      top: 0.1em; }
    .CardList-nav li.is-active:after {
      bottom: 0em; }
  .CardList-nav a:not(.dropdown-item) {
    text-decoration: none;
    opacity: 0.5; }

.CardList-select {
  display: inline-block;
  width: auto !important;
  vertical-align: baseline; }
  .CardList-select:not(.bootstrap-select) {
    display: none; }
  .CardList-select .dropdown-menu {
    margin-left: -1.5em;
    max-height: 7.28em;
    overflow-y: auto; }
  .CardList-select .dropdown-item {
    padding: 0.5em 1.5em;
    display: flex;
    line-height: 1.3; }

.CardList-sectionTitle {
  font-size: 20.44751px;
  margin-top: 6rem;
  margin-bottom: 2rem; }
  [data-scroll-container] .CardList-sectionTitle {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .CardList-sectionTitle.is-inview {
    opacity: 1;
    transform: none !important; }
  @media (min-width: 320px) and (max-width: 768px) {
    .CardList-sectionTitle {
      font-size: calc( 20.44751px + 1.69504 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .CardList-sectionTitle {
      font-size: calc( 22.14256px + 0.63827 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .CardList-sectionTitle {
      font-size: calc( 22.78083px + 2.92652 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .CardList-sectionTitle {
      font-size: calc( 25.70734px + 0.54128 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .CardList-sectionTitle {
      font-size: calc( 26.24862px + 1.04624 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .CardList-sectionTitle {
      font-size: 27.29486px; } }
  @media (min-width: 768px) {
    .CardList-sectionTitle {
      margin-top: 11.11111vw ; } }
  .CardList-sectionTitle:nth-child(1) {
    margin-top: 0; }

@media (min-width: 992px) {
  .CardList-list {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; } }

.CardList-list > .row {
  justify-content: space-between; }

.CardList-item {
  margin-top: 6rem;
  display: flex; }
  [data-scroll-container] .CardList-item {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .CardList-item.is-inview {
    opacity: 1;
    transform: none !important; }
  @media (min-width: 768px) {
    .CardList-item {
      margin-top: 11.11111vw ; } }
  @media (min-width: 768px) {
    .CardList-item {
      flex: 0 0 50%;
      max-width: 50%; }
      .CardList-item:nth-child(2) {
        margin-top: 0; }
      .CardList-item:nth-child(even) {
        transition-delay: 0.35s !important; } }
  @media (min-width: 992px) {
    .CardList-item:nth-child(even) {
      margin-right: -2.22222vw; }
    .CardList-item:nth-child(odd) {
      margin-left: -2.22222vw; } }
  .CardList-item:nth-child(1) {
    margin-top: 0; }

.CardSlider {
  overflow: hidden; }
  .CardSlider .swiper-container {
    overflow: visible; }
  .CardSlider .swiper-slide {
    --width: 90%;
    --padding-right: 4.26667vw;
    box-sizing: content-box;
    width: var(--width);
    padding-right: var(--padding-right);
    height: auto;
    display: flex; }
    @media (min-width: 768px) {
      .CardSlider .swiper-slide {
        --width: calc(45.8% - 1.11111vw);
        --padding-right: 2.22222vw; } }
    @media (min-width: 992px) {
      .CardSlider .swiper-slide {
        --width: calc(41.66667% - 1.11111vw);
        --padding-right: 6.66667vw; } }
    .CardSlider .swiper-slide:last-child {
      padding-right: 0; }

.CardSlider-heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 3rem; }

.CardSlider-cta {
  margin: 0 0 0 1rem;
  flex-shrink: 0; }
  .CardSlider-cta a {
    text-decoration: underline; }

.CardSlider-title {
  font-size: 27px;
  font-weight: 500;
  margin: 0; }
  @media (min-width: 320px) and (max-width: 768px) {
    .CardSlider-title {
      font-size: calc( 27px + 5 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .CardSlider-title {
      font-size: calc( 32px + 2 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .CardSlider-title {
      font-size: calc( 34px + 10 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .CardSlider-title {
      font-size: calc( 44px + 2 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .CardSlider-title {
      font-size: calc( 46px + 4 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .CardSlider-title {
      font-size: 50px; } }

.CardSlider-item {
  width: 100%;
  display: flex; }

.Copyright {
  line-height: 1.2 !important;
  font-size: 12px !important; }
  @media (min-width: 768px) {
    .Copyright {
      font-size: 14px !important; } }
  .fancybox-caption .Copyright {
    color: currentColor;
    opacity: 0.7; }

.Copyright--tl,
.Copyright--tr,
.Copyright--br,
.Copyright--bl {
  position: absolute;
  z-index: 10;
  margin: 0;
  opacity: 0.5;
  max-width: 30em; }

.Copyright--tl {
  transform: rotate(90deg) translate(0, -100%);
  transform-origin: 0 0;
  top: 1rem;
  left: 1rem; }

.Copyright--tr {
  transform: rotate(-90deg) translate(0, -100%);
  transform-origin: 100% 0;
  top: 1rem;
  right: 1rem;
  text-align: right; }

.Copyright--br {
  bottom: 1rem;
  right: 1rem;
  text-align: right; }

.Copyright--bl {
  bottom: 1rem;
  left: 1rem; }

.Copyright--gray {
  color: gray; }

.Copyright--white {
  color: #fff; }

.Copyright--black {
  color: #000; }

.CourseList-headingCol {
  margin-left: auto; }
  [data-scroll-container] .CourseList-headingCol {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .CourseList-headingCol.is-inview {
    opacity: 1;
    transform: none !important; }
  @media (min-width: 768px) {
    .CourseList-headingCol {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; } }
  @media (min-width: 992px) {
    .CourseList-headingCol {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }

.CourseList-listCol {
  margin-left: auto; }
  @media (min-width: 768px) {
    .CourseList-listCol {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; } }
  @media (min-width: 992px) {
    .CourseList-listCol {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; } }

.CourseList-heading {
  margin-bottom: 3rem; }

.CourseList-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 4px solid #000; }
  [data-scroll-container] .CourseList-listCol .CourseList-list {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .CourseList-listCol.is-inview .CourseList-list {
    opacity: 1;
    transform: none !important; }

.CourseList-item {
  border-bottom: 1px solid #000;
  position: relative; }
  [data-scroll-container] .CourseList-listCol .CourseList-item {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .CourseList-listCol.is-inview .CourseList-item {
    opacity: 1;
    transform: none !important; }
  .CourseList-item:nth-child(n+1) {
    transition-delay: 0.1s !important; }
  .CourseList-item:nth-child(n+2) {
    transition-delay: 0.2s !important; }
  .CourseList-item:nth-child(n+3) {
    transition-delay: 0.3s !important; }
  .CourseList-item:nth-child(n+4) {
    transition-delay: 0.4s !important; }
  .CourseList-item:nth-child(n+5) {
    transition-delay: 0.5s !important; }
  .CourseList-item:nth-child(n+6) {
    transition-delay: 0.6s !important; }
  .CourseList-item:nth-child(n+7) {
    transition-delay: 0.7s !important; }
  .CourseList-item:nth-child(n+8) {
    transition-delay: 0.8s !important; }
  .CourseList-item:nth-child(n+9) {
    transition-delay: 0.9s !important; }
  .CourseList-item:nth-child(n+10) {
    transition-delay: 1s !important; }
  .CourseList-item:last-child {
    border: 0; }

a.CourseList-link {
  display: block;
  text-decoration: none;
  padding: 1rem 1.5rem 1rem 0;
  position: relative;
  overflow: hidden; }
  @media (min-width: 768px) {
    a.CourseList-link {
      padding: 1.5rem 1.5rem 1.5rem 0; } }
  a.CourseList-link .Icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.5s; }
    a.CourseList-link .Icon:last-child {
      right: auto;
      left: -1.5rem; }
  .desktop a.CourseList-link:hover .Icon {
    transform: translateX(1.5rem) translateY(-50%); }

.CourseList-title {
  font-weight: 700; }

.CourseList-title,
.CourseList-subtitle {
  line-height: 1;
  text-transform: uppercase;
  display: inline-block;
  transition: transform 0.5s; }
  .desktop a:hover .CourseList-title, .desktop a:hover
  .CourseList-subtitle {
    transform: translateX(1.5rem); }

.CourseList-subtitle {
  font-weight: normal; }

.Date {
  display: flex;
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  white-space: nowrap;
  align-items: center;
  font-size: 22.92715px; }
  @media (min-width: 320px) and (max-width: 768px) {
    .Date {
      font-size: calc( 22.92715px + 2.84069 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Date {
      font-size: calc( 25.76785px + 1.09668 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .Date {
      font-size: calc( 26.86453px + 5.21007 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .Date {
      font-size: calc( 32.0746px + 0.99535 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Date {
      font-size: calc( 33.06995px + 1.95112 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Date {
      font-size: 35.02107px; } }
  .Date:before {
    content: '';
    display: block;
    height: 0.65em;
    border-bottom: 1px solid currentColor;
    flex: 1 1 100%;
    margin-right: 0.3em; }

[data-scroll-container] .FeaturedEvents-wrapper {
  opacity: 0;
  transform: translateY(2rem);
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  transition-property: transform, opacity; }

[data-scroll-container] .FeaturedEvents-wrapper.is-inview {
  opacity: 1;
  transform: none !important; }

.FeaturedEvents-item[data-text-inverted] {
  color: #fff; }

@media (min-width: 768px) {
  .FeaturedEvents-inner {
    display: flex; } }

@media (min-width: 768px) {
  .FeaturedEvents-inner {
    min-height: 50vw; } }

.FeaturedEvents-media {
  padding-bottom: 100%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.06);
  overflow: hidden; }
  @media (min-width: 768px) {
    .FeaturedEvents-media {
      flex: 0 0 50%;
      padding-bottom: 0; }
      .FeaturedEvents-item:nth-child(even) .FeaturedEvents-media {
        order: 2; }
        .FeaturedEvents--invertOrder .FeaturedEvents-item:nth-child(even) .FeaturedEvents-media {
          order: 0; }
      .FeaturedEvents--invertOrder .FeaturedEvents-item:nth-child(odd) .FeaturedEvents-media {
        order: 2; } }

.FeaturedEvents-figure {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; }

.FeaturedEvents-image {
  transition: opacity 0.3s, transform 1.8s cubic-bezier(0.23, 1, 0.32, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: center/cover no-repeat; }
  .Main .FeaturedEvents-image {
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1.05); }
  .Main .is-inview .FeaturedEvents-image.lazyloaded {
    opacity: 1;
    transform: scale3d(1, 1, 1); }
  .desktop a:hover .FeaturedEvents-image {
    transition-duration: 6s;
    transform: scale(1.1) !important; }

.FeaturedEvents-content {
  padding-top: 1rem;
  padding-bottom: 1rem; }
  @media (min-width: 768px) {
    .FeaturedEvents-content {
      flex: 0 0 50%;
      display: flex;
      padding: 3.33333vw; } }

.FeaturedEvents-container > a {
  text-decoration: none !important; }

@media (min-width: 768px) {
  .FeaturedEvents-container {
    display: flex;
    flex-direction: column; } }

.FeaturedEvents-headline {
  font-size: 27px;
  line-height: 1.15;
  text-decoration: none !important;
  margin-bottom: 1rem; }
  @media (min-width: 320px) and (max-width: 768px) {
    .FeaturedEvents-headline {
      font-size: calc( 27px + 5 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .FeaturedEvents-headline {
      font-size: calc( 32px + 2 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .FeaturedEvents-headline {
      font-size: calc( 34px + 10 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .FeaturedEvents-headline {
      font-size: calc( 44px + 2 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .FeaturedEvents-headline {
      font-size: calc( 46px + 4 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .FeaturedEvents-headline {
      font-size: 50px; } }
  .FeaturedEvents-headline > * {
    font-size: inherit;
    line-height: inherit;
    display: inline;
    font-weight: 400;
    margin: 0; }

.FeaturedEvents-label {
  font-size: calc(1rem - 2px);
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 0.25em;
  padding-right: 1.5em; }

.FeaturedEvents-subtitle {
  font-style: italic; }
  .FeaturedEvents-subtitle:before {
    content: '- '; }

.FeaturedEvents-ctas {
  margin-top: 2rem;
  display: flex; }
  .FeaturedEvents-ctas .Link {
    font-weight: 700; }
  .FeaturedEvents-ctas > *:not(:last-child) {
    margin-right: 1.5rem; }

.FeaturedEvents-date {
  padding-top: 8.53333vw ;
  margin-top: auto; }
  @media (min-width: 768px) {
    .FeaturedEvents-date {
      padding-top: 4.44444vw ; } }

.FeaturedList-item {
  margin-bottom: 6rem;
  justify-content: space-between; }
  [data-scroll-container] .FeaturedList-item {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .FeaturedList-item.is-inview {
    opacity: 1;
    transform: none !important; }
  @media (min-width: 768px) {
    .FeaturedList-item {
      margin-bottom: 11.11111vw ; } }
  .FeaturedList-item:last-child {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .FeaturedList-item:nth-child(odd) {
      flex-direction: row-reverse; }
    .FeaturedList-item:nth-child(even) {
      justify-content: flex-start; } }
  .FeaturedList--reverse .FeaturedList-item {
    flex-direction: row-reverse; }
    @media (min-width: 768px) {
      .FeaturedList--reverse .FeaturedList-item:nth-child(odd) {
        flex-direction: row;
        justify-content: flex-start; }
      .FeaturedList--reverse .FeaturedList-item:nth-child(even) {
        flex-direction: row-reverse;
        justify-content: space-between; } }

.FeaturedList-contentCol {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw;
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 1rem; }
  @media (min-width: 768px) {
    .FeaturedList-contentCol {
      padding-right: 1.11111vw;
      padding-left: 1.11111vw; } }
  @media (min-width: 768px) {
    .FeaturedList-contentCol {
      margin-top: 0;
      flex: 0 0 41.66667%;
      max-width: 41.66667%; } }
  @media (min-width: 992px) {
    .FeaturedList-contentCol {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }

.FeaturedList-mediaCol {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw;
  flex: 0 0 100%;
  max-width: 100%; }
  @media (min-width: 768px) {
    .FeaturedList-mediaCol {
      padding-right: 1.11111vw;
      padding-left: 1.11111vw; } }
  @media (min-width: 768px) {
    .FeaturedList-mediaCol {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; } }

.FeaturedList-title {
  font-size: 33.39501px;
  font-weight: 900;
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-align: left;
  margin-bottom: 1.5rem; }
  @media (min-width: 320px) and (max-width: 768px) {
    .FeaturedList-title {
      font-size: calc( 33.39501px + 9.01256 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .FeaturedList-title {
      font-size: calc( 42.40757px + 3.77398 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .FeaturedList-title {
      font-size: calc( 46.18155px + 20.1823 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .FeaturedList-title {
      font-size: calc( 66.36385px + 4.28083 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .FeaturedList-title {
      font-size: calc( 70.64468px + 8.78866 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .FeaturedList-title {
      font-size: 79.43334px; } }
  @media (max-width: 767.98px) {
    .FeaturedList-title {
      margin-bottom: 1rem; }
      .FeaturedList-title br {
        content: ' '; }
      .FeaturedList-title br:after {
        content: ' '; } }

@media (min-width: 768px) {
  .FeaturedList-text,
  .FeaturedList-link {
    margin-left: 21%; } }

@media (min-width: 992px) {
  .FeaturedList-text,
  .FeaturedList-link {
    margin-left: 27%; } }

.FeaturedList-figure {
  position: relative;
  padding-bottom: 75%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.06);
  overflow: hidden; }

.FeaturedList-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.FeaturedList-image {
  transition: opacity 0.3s, transform 1.8s cubic-bezier(0.23, 1, 0.32, 1);
  background: center/cover no-repeat; }
  .Main .FeaturedList-image {
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1.05); }
  .Main .is-inview .FeaturedList-image.lazyloaded {
    opacity: 1;
    transform: scale3d(1, 1, 1); }
  .desktop a:hover .FeaturedList-image {
    transition-duration: 6s;
    transform: scale(1.1) !important; }

.js-formBuilder {
  margin-bottom: 4rem; }

.FormBuilder .Inputfield_referral {
  display: none !important; }

.FormBuilder input[type="text"],
.FormBuilder input[type="email"],
.FormBuilder input[type="file"],
.FormBuilder textarea,
.FormBuilder select {
  box-shadow: none  !important; }

.FormBuilder textarea {
  height: auto; }

.FormBuilder input[type="file"] {
  opacity: 0; }

.FormBuilder .InputfieldHidden {
  display: none; }

.FormBuilder .InputfieldStateError input[type="text"],
.FormBuilder .InputfieldStateError input[type="email"],
.FormBuilder .InputfieldStateError textarea,
.FormBuilder .InputfieldStateError select,
.FormBuilder .InputfieldStateError .dropdown-toggle {
  border-color: #a34e5a; }
  .FormBuilder .InputfieldStateError input[type="text"]:focus,
  .FormBuilder .InputfieldStateError input[type="email"]:focus,
  .FormBuilder .InputfieldStateError textarea:focus,
  .FormBuilder .InputfieldStateError select:focus,
  .FormBuilder .InputfieldStateError .dropdown-toggle:focus {
    border-color: #a34e5a;
    box-shadow: 0 0 0 0.2rem rgba(163, 78, 90, 0.3); }

.FormBuilder .InputfieldStateError .InputfieldContent label {
  color: #a34e5a; }

.FormBuilder .input-error {
  font-size: calc(1rem - 2px);
  line-height: 1.5;
  font-weight: 700;
  color: #a34e5a; }

.FormBuilder .notes {
  font-size: calc(1rem - 2px);
  line-height: 1.5;
  color: #6c757d;
  margin-bottom: 0; }

.FormBuilder .Inputfields {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.13333vw;
  margin-left: -2.13333vw; }
  @media (min-width: 768px) {
    .FormBuilder .Inputfields {
      margin-right: -1.11111vw;
      margin-left: -1.11111vw; } }
  .FormBuilder .Inputfields > *:last-child {
    margin-bottom: 0; }

.FormBuilder .Inputfield,
.FormBuilder fieldset {
  padding-right: 2.13333vw;
  padding-left: 2.13333vw;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw; }
  @media (min-width: 768px) {
    .FormBuilder .Inputfield,
    .FormBuilder fieldset {
      padding-right: 1.11111vw;
      padding-left: 1.11111vw; } }
  @media (min-width: 768px) {
    .FormBuilder .Inputfield,
    .FormBuilder fieldset {
      padding-right: 1.11111vw;
      padding-left: 1.11111vw; } }
  @media (max-width: 767.98px) {
    .FormBuilder .Inputfield,
    .FormBuilder fieldset {
      width: 100% !important; } }

.FormBuilder fieldset {
  margin-bottom: 1rem !important; }

.FormBuilder .InputfieldSelect .dropdown-toggle:after {
  top: -0.3rem; }

.FormBuilder .InputfieldStateRequired label.InputfieldHeader:after,
.FormBuilder .InputfieldStateRequired label .pw-no-select:after {
  content: ' *';
  font-size: 0.875rem;
  text-transform: none;
  letter-spacing: 0;
  color: inherit; }

.FormBuilder .InputfieldCheckbox .InputfieldHeader {
  display: none; }

.FormBuilder .Inputfield_file li + li {
  margin-top: 2rem; }

.FormBuilder .InputfieldContent {
  position: relative; }
  .FormBuilder .InputfieldContent .description {
    display: none; }
  .FormBuilder .InputfieldContent ul {
    list-style: none;
    margin: 0;
    padding: 0; }

.FormBuilder .InputfieldHeader {
  z-index: 2;
  position: absolute;
  display: block;
  transform: translate(0.75rem, 0.8rem);
  transition: transform 0.1s;
  transform-origin: 0 0;
  pointer-events: none; }

.FormBuilder .Inputfield_radio_buttons .InputfieldHeader,
.FormBuilder .InputfieldCheckboxes .InputfieldHeader {
  position: static;
  transform: none;
  pointer-events: auto; }

.FormBuilder .Inputfield.Inputfield_text_with_placeholder .InputfieldHeader, .FormBuilder .Inputfield.has-focus .InputfieldHeader, .FormBuilder .Inputfield.has-value .InputfieldHeader {
  transform: translate(0.75rem, 0.35rem) scale(0.75); }

.FormBuilder .InputfieldSubmit {
  margin: 2rem 0 4rem;
  display: flex;
  justify-content: center; }

.Gallery {
  overflow: hidden; }
  .Gallery .swiper-container {
    overflow: visible; }
  .Gallery .swiper-wrapper {
    align-items: center; }
  .Gallery .swiper-slide {
    padding-right: 4.26667vw ;
    width: auto;
    flex: 0 1 0; }
    @media (min-width: 768px) {
      .Gallery .swiper-slide {
        padding-right: 3.33333vw ; } }
    .Gallery .swiper-slide:last-child {
      padding-right: 0; }

[data-scroll-container] .Gallery-container {
  opacity: 0;
  transform: translateY(2rem);
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  transition-property: transform, opacity; }

[data-scroll-container] .Gallery-container.is-inview {
  opacity: 1;
  transform: none !important; }

.Gallery-item {
  display: block; }

.Gallery-link {
  display: block;
  position: relative;
  background-color: rgba(0, 0, 0, 0.06);
  overflow: hidden; }
  .Gallery-link img {
    transition: opacity 0.3s, transform 1.8s cubic-bezier(0.23, 1, 0.32, 1);
    position: absolute;
    width: 100%;
    height: 100%; }
    .Main .Gallery-link img {
      opacity: 0;
      transform: scale3d(1.05, 1.05, 1.05); }
    .Main .is-inview .Gallery-link img.lazyloaded {
      opacity: 1;
      transform: scale3d(1, 1, 1); }

.Gallery-caption {
  margin-top: 1rem; }
  .Gallery-caption .Copyright {
    display: none; }

@media (max-width: 767.98px) {
  .Gallery-description {
    display: none; } }

.HpHeader {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column; }
  .HpHeader[data-text-inverted] {
    color: #fff; }

.HpHeader-media,
.HpHeader-wrapperMedia,
.HpHeader-wrapperMedia:after,
.HpHeader-image,
.HpHeader-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.HpHeader-media {
  background-color: rgba(0, 0, 0, 0.06);
  display: block;
  overflow: hidden; }

.HpHeader-wrapperMedia {
  transition: opacity 0.3s; }
  .HpHeader-wrapperMedia > * {
    transition: transform 3s cubic-bezier(0.23, 1, 0.32, 1); }
  .Main .HpHeader-wrapperMedia {
    opacity: 0; }
    .Main .HpHeader-wrapperMedia > * {
      transform: scale3d(1.05, 1.05, 1.05); }
  .Main .HpHeader-wrapperMedia.is-inview.lazyloaded {
    opacity: 1; }
    .Main .HpHeader-wrapperMedia.is-inview.lazyloaded > * {
      transform: scale3d(1, 1, 1); }
  .HpHeader-wrapperMedia:after {
    content: '';
    background-color: #000;
    opacity: 0.2; }

.HpHeader-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.HpHeader-video:not(.lazyloaded) {
  opacity: 0; }

.HpHeader-video video {
  position: absolute; }

.HpHeader-wrapper {
  border-top-width: 3.4375rem ;
  padding-top: 6rem;
  padding-bottom: 6rem;
  border-top-color: transparent;
  border-top-style: solid;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(var(--vh) * 100); }
  @media (min-width: 768px) {
    .HpHeader-wrapper {
      border-top-width: 6.875rem ; } }
  @media (min-width: 768px) {
    .HpHeader-wrapper {
      padding-top: 5.55556vw ;
      padding-bottom: 5.55556vw ; } }
  @media (max-width: 767.98px) {
    .HpHeader-wrapper {
      justify-content: flex-end;
      padding-bottom: 12.8vw; } }

.HpHeader-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 4.26667vw;
  padding-left: 4.26667vw; }
  @media (min-width: 768px) {
    .HpHeader-container {
      padding-right: 3.33333vw;
      padding-left: 3.33333vw; } }

.HpHeader-titleCol {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }
  @media (min-width: 768px) {
    .HpHeader-titleCol {
      flex: 0 0 75%;
      max-width: 75%;
      margin-left: 8.33333%;
      margin-bottom: 6rem; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .HpHeader-titleCol {
      margin-bottom: 5.55556vw ; } }

.HpHeader-ctaCol {
  transition-delay: 0.25s !important; }
  [data-scroll-container] .HpHeader-ctaCol {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .HpHeader-ctaCol.is-inview {
    opacity: 1;
    transform: none !important; }
  @media (min-width: 768px) {
    .HpHeader-ctaCol {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      margin-left: 16.66667%; } }
  @media (min-width: 992px) {
    .HpHeader-ctaCol {
      flex: 0 0 25%;
      max-width: 25%;
      margin-left: 25%; } }

.HpHeader-textCol {
  transition-delay: 0.4s !important; }
  [data-scroll-container] .HpHeader-textCol {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .HpHeader-textCol.is-inview {
    opacity: 1;
    transform: none !important; }
  @media (max-width: 767.98px) {
    .HpHeader-textCol {
      display: none; } }
  @media (min-width: 768px) {
    .HpHeader-textCol {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }
  @media (min-width: 992px) {
    .HpHeader-textCol {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; } }
  @media (min-width: 1440px) {
    .HpHeader-textCol {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }
  .HpHeader-titleCol + .HpHeader-textCol {
    transition-delay: 0.25s !important; }
    @media (min-width: 768px) {
      .HpHeader-titleCol + .HpHeader-textCol {
        margin-left: 16.66667%; } }
    @media (min-width: 992px) {
      .HpHeader-titleCol + .HpHeader-textCol {
        margin-left: 25%; } }

.HpHeader-title {
  font-size: 42.6778px;
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 900;
  margin-bottom: 2rem; }
  @media (min-width: 320px) and (max-width: 768px) {
    .HpHeader-title {
      font-size: calc( 42.6778px + 16.01045 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .HpHeader-title {
      font-size: calc( 58.68826px + 7.06511 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .HpHeader-title {
      font-size: calc( 65.75337px + 40.87403 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .HpHeader-title {
      font-size: calc( 106.62739px + 9.26794 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .HpHeader-title {
      font-size: calc( 115.89533px + 19.6123 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .HpHeader-title {
      font-size: 135.50764px; } }
  [data-scroll-container] .HpHeader-title {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .HpHeader-title.is-inview {
    opacity: 1;
    transform: none !important; }
  [data-scroll-container] .HpHeader-title > * {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .HpHeader-title.is-inview > * {
    opacity: 1;
    transform: none !important; }
  [data-scroll-container] .HpHeader-title {
    transform: none; }
  [data-scroll-container] .HpHeader-title > *:nth-child(odd) {
    transform: translateX(-2rem); }
  [data-scroll-container] .HpHeader-title > *:nth-child(even) {
    transform: translateX(2rem); }
  .HpHeader-title > *:nth-child(even) {
    margin-left: 9%;
    margin-right: -9%; }
    @media (min-width: 768px) {
      .HpHeader-title > *:nth-child(even) {
        margin-left: 11.5%;
        margin-right: -11.5%; } }
    @media (min-width: 992px) {
      .HpHeader-title > *:nth-child(even) {
        margin-left: 22.5%;
        margin-right: -22.5%; } }

.HpSlider[data-text-inverted] {
  color: #fff; }

.HpSlider-item {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column; }

.HpSlider-media,
.HpSlider-wrapperMedia,
.HpSlider-wrapperMedia:after,
.HpSlider-image,
.HpSlider-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.HpSlider-media {
  background-color: rgba(0, 0, 0, 0.06);
  overflow: hidden; }

.HpSlider-wrapperMedia {
  transition: opacity 0.3s; }
  .HpSlider-wrapperMedia > * {
    transition: transform 3s cubic-bezier(0.23, 1, 0.32, 1); }
  .Main .HpSlider-wrapperMedia {
    opacity: 0; }
    .Main .HpSlider-wrapperMedia > * {
      transform: scale3d(1.05, 1.05, 1.05); }
  .Main .HpSlider-wrapperMedia.is-inview.lazyloaded {
    opacity: 1; }
    .Main .HpSlider-wrapperMedia.is-inview.lazyloaded > * {
      transform: scale3d(1, 1, 1); }
  .HpSlider-wrapperMedia:after {
    content: '';
    background-color: #000;
    opacity: 0.2; }

.HpSlider-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.HpSlider-video:not(.lazyloaded) {
  opacity: 0; }

.HpSlider-video video {
  position: absolute; }

.HpSlider-wrapper {
  padding-top: 6rem;
  padding-bottom: 6rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(var(--vh) * 100); }
  @media (min-width: 768px) {
    .HpSlider-wrapper {
      padding-top: 5.55556vw ;
      padding-bottom: 5.55556vw ; } }

.HpSlider-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 4.26667vw;
  padding-left: 4.26667vw; }
  @media (min-width: 768px) {
    .HpSlider-container {
      padding-right: 3.33333vw;
      padding-left: 3.33333vw; } }

.HpSlider-titleCol {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

[data-scroll-container] .HpSlider-ctaCol {
  opacity: 0;
  transition: opacity 0.1s 0.8s; }

[data-scroll-container] .swiper-slide-active.is-inview .HpSlider-ctaCol {
  opacity: 1;
  transition: opacity 0.8s 0.2s; }

[data-scroll-container] .swiper-slide-duplicate-active.is-inview .HpSlider-ctaCol {
  opacity: 1;
  transition: opacity 0.8s 0.2s; }

.HpSlider-title {
  font-size: 36.83747px;
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 900;
  margin: 0 0 2rem; }
  @media (min-width: 320px) and (max-width: 768px) {
    .HpSlider-title {
      font-size: calc( 36.83747px + 11.45579 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .HpSlider-title {
      font-size: calc( 48.29326px + 4.89898 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .HpSlider-title {
      font-size: calc( 53.19224px + 27.03216 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .HpSlider-title {
      font-size: calc( 80.2244px + 5.88966 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .HpSlider-title {
      font-size: calc( 86.11406px + 12.23885 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .HpSlider-title {
      font-size: 98.35291px; } }
  [data-scroll-container] .HpSlider-title {
    opacity: 0;
    transition: opacity 0.1s 0.8s; }
  [data-scroll-container] .swiper-slide-active.is-inview .HpSlider-title {
    opacity: 1;
    transition: opacity 0.8s 0.2s; }
  [data-scroll-container] .swiper-slide-duplicate-active.is-inview .HpSlider-title {
    opacity: 1;
    transition: opacity 0.8s 0.2s; }
  [data-scroll-container] .HpSlider-title > * {
    transition: transform 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s; }
  [data-scroll-container] .swiper-slide-active.is-inview .HpSlider-title > * {
    transform: translateX(0);
    transition: transform 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s; }
  [data-scroll-container] .swiper-slide-duplicate-active.is-inview .HpSlider-title > * {
    transform: translateX(0);
    transition: transform 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s; }
  [data-scroll-container] .HpSlider-title > *:nth-child(odd) {
    transform: translateX(-2rem); }
  [data-scroll-container] .HpSlider-title > *:nth-child(even) {
    transform: translateX(2rem); }
  .HpSlider-title > *:nth-child(even) {
    margin-left: 9.5%;
    margin-right: -9.5%; }

.HpSlider-nav {
  right: 4.26667vw ;
  bottom: 4.26667vw ;
  position: absolute;
  z-index: 1;
  display: none; }
  @media (min-width: 768px) {
    .HpSlider-nav {
      right: 3.33333vw ; } }
  @media (min-width: 768px) {
    .HpSlider-nav {
      bottom: 3.33333vw ; } }
  .has-slides .HpSlider-nav {
    display: flex; }

.HpSlider-navItem {
  width: 2rem;
  position: relative;
  margin-left: 0.5rem;
  min-height: 3px;
  cursor: pointer; }
  @media (min-width: 768px) {
    .HpSlider-navItem {
      width: 10rem;
      margin-left: 1rem; } }
  .HpSlider-navItem:before {
    display: block;
    content: '';
    width: 100%;
    height: 3px;
    background-color: #fff;
    opacity: 0.4; }

.HpSlider-progress {
  height: 3px;
  background-color: #fff;
  position: absolute;
  top: 0;
  width: 100%;
  transform-origin: 0 0;
  transform: scaleX(0); }

.HpSlider-label {
  padding-top: 0.25rem;
  font-weight: 500;
  font-size: calc(1rem - 2px);
  padding-right: 20%; }
  @media (max-width: 767.98px) {
    .HpSlider-label {
      display: none; } }

.Icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  fill: none;
  stroke: currentColor;
  stroke-width: 1.3px;
  width: 1rem;
  height: 1rem; }

.Icon-svg {
  width: 100%;
  height: 100%; }

.Icon--chevron-down {
  width: 0.875rem;
  height: 0.5rem; }

.Icon--play {
  fill: currentColor;
  stroke: none; }

.Image.Image--full {
  margin: 0; }

.Image:not(.Image--full) [data-scroll-speed] {
  transform: none !important; }

[data-scroll-container] .Image-container {
  opacity: 0;
  transform: translateY(2rem);
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  transition-property: transform, opacity; }

[data-scroll-container] .Image-container.is-inview {
  opacity: 1;
  transform: none !important; }

.Image--full .Image-container {
  padding: 0; }

@media (min-width: 768px) {
  .Image--right .Image-col {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-left: auto; } }

.Image-figure {
  background-color: rgba(0, 0, 0, 0.06);
  position: relative;
  overflow: hidden; }
  .Image--full .Image-figure {
    max-height: calc(var(--vh) * 80);
    height: 50vmax; }

.Image-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.Image-image {
  transition: opacity 0.3s, transform 1.8s cubic-bezier(0.23, 1, 0.32, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center/cover no-repeat; }
  .Main .Image-image {
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1.05); }
  .Main .is-inview .Image-image.lazyloaded {
    opacity: 1;
    transform: scale3d(1, 1, 1); }
  .has-scroll-smooth .Image--full .Image-image {
    top: calc(var(--vh) * -10);
    bottom: calc(var(--vh) * -10); }

.Image--full .Image-placeholder {
  display: none; }

.Link {
  display: inline-flex;
  align-items: center;
  color: currentColor !important;
  font-weight: 500; }
  .Link:after {
    content: '';
    width: 1rem;
    height: 1rem;
    background-color: transparent;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition: transform 0.3s;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewbox='0 0 16 16' style='fill: none; stroke: %23000; stroke-width: 1.3px'%3E%3Cpath d='M9.1 3.1L14 8l-4.9 4.9M2 8h12'/%3E%3C/svg%3E"); }
    [data-text-inverted] .Link:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewbox='0 0 16 16' style='fill: none; stroke: %23fff; stroke-width: 1.3px'%3E%3Cpath d='M9.1 3.1L14 8l-4.9 4.9M2 8h12'/%3E%3C/svg%3E"); }

.Link--next:after {
  margin-left: 0.5rem; }

.desktop .Link--next:hover:after {
  transform: translateX(4px); }

.Link--prev:after {
  order: -1;
  margin-right: 0.5rem;
  transform: rotate(180deg); }

.desktop .Link--prev:hover:after {
  transform: rotate(180deg) translateX(4px); }

.Main {
  overflow: hidden;
  pointer-events: none; }
  @media (max-width: 991.98px) {
    .Main [data-scroll-speed] {
      transform: none !important; } }
  .has-scroll-smooth .Main [data-scroll-speed] {
    will-change: transform, opacity; }

.Main-container {
  pointer-events: auto;
  background-color: #fff; }
  .Main-container [data-scroll-section] {
    background-color: inherit; }

.Main-container + .Main-container {
  display: none; }

@media (min-width: 768px) {
  .Map-col {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-left: auto; } }

.Map-wrapper {
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  background-color: #fff; }

.Map-area {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.Mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2030;
  background-color: #fff;
  pointer-events: none;
  opacity: 0; }

.MenuFull {
  min-height: 100vh;
  display: flex; }
  @media (max-width: 767.98px) {
    .MenuFull {
      display: none; } }

.MenuFull-1stCol {
  flex: 0 0 35%;
  width: 35vw;
  display: flex;
  flex-direction: column; }

.MenuFull-1stLevel {
  margin: auto 0; }
  .MenuFull-1stLevel ul {
    padding-top: 1.71875rem ;
    padding-left: 0;
    list-style: none;
    margin: 0;
    font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    font-size: 2.0625rem;
    line-height: 1; }
    @media (min-width: 768px) {
      .MenuFull-1stLevel ul {
        padding-top: 3.4375rem ; } }
  .MenuFull-1stLevel li:first-child {
    display: none; }
  .MenuFull-1stLevel li {
    opacity: 0;
    transform: translateY(1rem);
    transition: transform 0.3s 0.3s, opacity 0.3s; }
    .show-menu .MenuFull-1stLevel li {
      opacity: 1;
      transform: translateY(0);
      transition-duration: 0.6s; }
      .show-menu .MenuFull-1stLevel li:nth-child(1) {
        transition-delay: 0.5s !important; }
      .show-menu .MenuFull-1stLevel li:nth-child(2) {
        transition-delay: 0.55s !important; }
      .show-menu .MenuFull-1stLevel li:nth-child(3) {
        transition-delay: 0.6s !important; }
      .show-menu .MenuFull-1stLevel li:nth-child(4) {
        transition-delay: 0.65s !important; }
      .show-menu .MenuFull-1stLevel li:nth-child(5) {
        transition-delay: 0.7s !important; }
      .show-menu .MenuFull-1stLevel li:nth-child(6) {
        transition-delay: 0.75s !important; }
      .show-menu .MenuFull-1stLevel li:nth-child(7) {
        transition-delay: 0.8s !important; }
      .show-menu .MenuFull-1stLevel li:nth-child(8) {
        transition-delay: 0.85s !important; }
      .show-menu .MenuFull-1stLevel li:nth-child(9) {
        transition-delay: 0.9s !important; }
      .show-menu .MenuFull-1stLevel li:nth-child(10) {
        transition-delay: 0.95s !important; }
  .MenuFull-1stLevel a {
    display: inline-block;
    padding: 0.2em 0;
    text-decoration: none !important;
    color: currentColor !important;
    transition: opacity 0.6s; }
  .MenuFull-1stLevel .has-active a {
    opacity: 0.3; }
  .MenuFull-1stLevel .is-hover a,
  .MenuFull-1stLevel .is-hover .is-active a {
    opacity: 0.3; }
  .MenuFull-1stLevel .is-active a {
    opacity: 1; }
  .MenuFull-1stLevel a:hover {
    opacity: 1 !important; }

.MenuFull-footer {
  height: 2.57812rem ; }
  @media (min-width: 768px) {
    .MenuFull-footer {
      height: 5.15625rem ; } }
  .MenuFull-footer .container {
    display: flex;
    align-items: center; }
    .MenuFull-footer .container > a {
      font-size: 1.375rem;
      margin-right: auto; }
  .MenuFull-footer a {
    text-decoration: none !important;
    color: currentColor !important; }
  .MenuFull-footer ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    font-size: calc(1rem - 2px); }
  .MenuFull-footer li.is-active {
    text-decoration: underline; }
  .MenuFull-footer li:not(.is-active) {
    color: rgba(0, 0, 0, 0.6); }
  .MenuFull-footer li:not(:first-child) {
    margin-left: 0.5rem; }

.MenuFull-2ndCol {
  flex: 0 0 65%;
  width: 65vw;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden; }
  .MenuFull-2ndCol .swiper-container {
    margin: auto 0;
    width: 100% !important; }

.MenuFull-2ndLevel {
  padding-top: 1.71875rem ;
  padding-bottom: 2.57812rem ;
  margin: auto 0;
  display: block; }
  @media (min-width: 768px) {
    .MenuFull-2ndLevel {
      padding-top: 3.4375rem ; } }
  @media (min-width: 768px) {
    .MenuFull-2ndLevel {
      padding-bottom: 5.15625rem ; } }
  .MenuFull-2ndLevel ul {
    padding-left: 0;
    list-style: none;
    margin: auto 0;
    text-transform: uppercase;
    font-size: 2.8125rem;
    line-height: 1; }
  .MenuFull-2ndLevel li:not(:first-child) {
    margin-top: 0.75em; }
  .MenuFull-2ndLevel li {
    opacity: 0;
    transform: translateY(1rem);
    transition: transform 0.3s 0.3s, opacity 0.3s; }
  .MenuFull-2ndLevel a:hover {
    color: currentColor;
    opacity: 1 !important; }
  .MenuFull-2ndLevel a {
    display: inline-block;
    padding: 0.2em 0;
    text-decoration: none !important;
    color: #fff !important;
    transition: transform 0.6s, opacity 0.6s;
    transform-origin: 0 100%;
    transform: translateX(0.001em) skew(-0.01deg); }
  .MenuFull-2ndLevel .swiper-slide {
    height: auto;
    display: flex;
    flex-direction: column; }
  .MenuFull-2ndLevel .has-active a {
    opacity: 0.3; }
  .MenuFull-2ndLevel .is-hover a,
  .MenuFull-2ndLevel .is-hover .is-active a {
    opacity: 0.3;
    transform: translateX(0.001em) skew(-0.01deg); }
  .MenuFull-2ndLevel .is-active a {
    opacity: 1;
    transform: translateX(0.001em) skew(-10deg); }
  .MenuFull-2ndLevel a:hover {
    transform: translateX(0.001em) skew(-10deg) !important;
    opacity: 1 !important; }
  .show-menu .MenuFull-2ndLevel .is-ready .swiper-slide-active li {
    opacity: 1;
    transform: translateY(0);
    transition-duration: 0.6s; }
    .show-menu .MenuFull-2ndLevel .is-ready .swiper-slide-active li:nth-child(1) {
      transition-delay: 0.05s !important; }
    .show-menu .MenuFull-2ndLevel .is-ready .swiper-slide-active li:nth-child(2) {
      transition-delay: 0.05s !important; }
    .show-menu .MenuFull-2ndLevel .is-ready .swiper-slide-active li:nth-child(3) {
      transition-delay: 0.05s !important; }
    .show-menu .MenuFull-2ndLevel .is-ready .swiper-slide-active li:nth-child(4) {
      transition-delay: 0.05s !important; }
    .show-menu .MenuFull-2ndLevel .is-ready .swiper-slide-active li:nth-child(5) {
      transition-delay: 0.05s !important; }
    .show-menu .MenuFull-2ndLevel .is-ready .swiper-slide-active li:nth-child(6) {
      transition-delay: 0.05s !important; }
    .show-menu .MenuFull-2ndLevel .is-ready .swiper-slide-active li:nth-child(7) {
      transition-delay: 0.05s !important; }
    .show-menu .MenuFull-2ndLevel .is-ready .swiper-slide-active li:nth-child(8) {
      transition-delay: 0.05s !important; }
    .show-menu .MenuFull-2ndLevel .is-ready .swiper-slide-active li:nth-child(9) {
      transition-delay: 0.05s !important; }
    .show-menu .MenuFull-2ndLevel .is-ready .swiper-slide-active li:nth-child(10) {
      transition-delay: 0.05s !important; }

.MenuFull-media,
.MenuFull-media:after,
.MenuFull-media .swiper-container,
.MenuFull-media .swiper-wrapper,
.MenuFull-media .swiper-slide,
.MenuFull-image,
.MenuFull-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.MenuFull-media {
  transform: translateY(-100%);
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  overflow: hidden; }
  .show-menu .MenuFull-media {
    transform: translateY(0);
    transition-duration: 1s; }

.MenuFull-media .swiper-container {
  transform: translateY(100%);
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1); }
  .show-menu .MenuFull-media .swiper-container {
    transform: translateY(0);
    transition-duration: 1s; }

.MenuFull-media:after {
  content: '';
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 2; }

.MenuFull-media .swiper-slide.swiper-slide-active {
  z-index: 100; }

.MenuFull-video video {
  position: absolute; }

.MenuFull-media {
  background-color: #000; }

.MenuFull-image {
  background: center/cover no-repeat; }

.MenuFull-video video,
.MenuFull-image {
  opacity: 0;
  transition: opacity 0.6s; }
  .swiper-slide-active .MenuFull-video video.lazyloaded, .swiper-slide-active
  .MenuFull-image.lazyloaded {
    opacity: 1; }

.MenuFull-video.lazyloaded + .MenuFull-image {
  opacity: 0 !important; }

.MenuFull-footer > * {
  opacity: 0;
  transform: translateY(1rem);
  transition: transform 0.1s 0.6s, opacity 0.6s; }
  .show-menu .MenuFull-footer > * {
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.6s 1s, opacity 0.6s 1s; }
    .show-menu .MenuFull-footer > *:last-child {
      transition: transform 0.6s 1.1s, opacity 0.6s 1.1s; }

.MenuSimple {
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .MenuSimple {
      display: none; } }

.MenuSimple-container {
  min-height: 100vh;
  padding-top: 6rem;
  padding-bottom: 8rem;
  display: flex;
  flex-direction: column; }

.MenuSimple-nav a {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none !important; }

.MenuSimple-nav ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.MenuSimple-nav ul ul li:last-child {
  padding-bottom: 10px; }

.MenuSimple-nav > ul > li {
  border-bottom: 1px solid #f2f2f2;
  opacity: 0;
  transform: translateY(1rem);
  transition: transform 0.3s 0.3s, opacity 0.3s; }
  .show-menu .MenuSimple-nav > ul > li {
    opacity: 1;
    transform: translateY(0);
    transition-duration: 0.6s; }
    .show-menu .MenuSimple-nav > ul > li:nth-child(1) {
      transition-delay: 0.3s !important; }
    .show-menu .MenuSimple-nav > ul > li:nth-child(2) {
      transition-delay: 0.35s !important; }
    .show-menu .MenuSimple-nav > ul > li:nth-child(3) {
      transition-delay: 0.4s !important; }
    .show-menu .MenuSimple-nav > ul > li:nth-child(4) {
      transition-delay: 0.45s !important; }
    .show-menu .MenuSimple-nav > ul > li:nth-child(5) {
      transition-delay: 0.5s !important; }
    .show-menu .MenuSimple-nav > ul > li:nth-child(6) {
      transition-delay: 0.55s !important; }
    .show-menu .MenuSimple-nav > ul > li:nth-child(7) {
      transition-delay: 0.6s !important; }
    .show-menu .MenuSimple-nav > ul > li:nth-child(8) {
      transition-delay: 0.65s !important; }
    .show-menu .MenuSimple-nav > ul > li:nth-child(9) {
      transition-delay: 0.7s !important; }
    .show-menu .MenuSimple-nav > ul > li:nth-child(10) {
      transition-delay: 0.75s !important; }
  .MenuSimple-nav > ul > li:first-child {
    display: none; }

.MenuSimple-nav > ul > li > a {
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.375rem;
  font-weight: 700;
  padding: 0.3em 0; }

.MenuSimple-nav ul ul a {
  padding: 0.15em 0; }

.MenuSimple-nav .is-toggle:after {
  content: '';
  width: 2rem;
  height: 2rem;
  transition: transform 0.3s;
  transform: translateZ(0);
  background: url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14px' height='8px' viewBox='0 0 14 8' style='fill: none; stroke: %23000; stroke-width: 1.3px'%3E%3Cpolyline points='1,1 7,7 13,1'%3E%3C/polyline%3E%3C/svg%3E) center no-repeat; }

.MenuSimple-nav .is-toggle.is-open:after {
  transform: rotate(-180deg) translateZ(0); }

.MenuSimple-nav [data-toggle] {
  display: block; }

.Newsletter {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #efefef !important; }
  @media (min-width: 768px) {
    .Newsletter {
      padding-top: 8.33333vw ;
      padding-bottom: 8.33333vw ; } }
  .Newsletter .row {
    align-items: center;
    justify-content: space-between; }
  .Newsletter .alert {
    margin-bottom: 0 !important; }
  .Newsletter .alert + .Newsletter-formGroup {
    margin-top: 1rem; }

@media (min-width: 768px) {
  .Newsletter-headlineCol,
  .Newsletter-formCol {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (min-width: 1440px) {
  .Newsletter-headlineCol,
  .Newsletter-formCol {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; } }

@media (max-width: 767.98px) {
  .Newsletter-headlineCol {
    margin-bottom: 1rem; } }

.Newsletter-title {
  font-size: 23.68933px;
  margin: 0;
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
  @media (min-width: 320px) and (max-width: 768px) {
    .Newsletter-title {
      font-size: calc( 23.68933px + 3.21935 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Newsletter-title {
      font-size: calc( 26.90869px + 1.25174 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .Newsletter-title {
      font-size: calc( 28.16042px + 6.00757 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .Newsletter-title {
      font-size: calc( 34.168px + 1.15832 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Newsletter-title {
      font-size: calc( 35.32632px + 2.27971 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Newsletter-title {
      font-size: 37.60603px; } }

.Newsletter-subtitle {
  font-size: 20.78461px;
  text-transform: uppercase;
  margin: 0;
  font-weight: 300; }
  @media (min-width: 320px) and (max-width: 768px) {
    .Newsletter-subtitle {
      font-size: calc( 20.78461px + 1.84281 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Newsletter-subtitle {
      font-size: calc( 22.62742px + 0.69639 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .Newsletter-subtitle {
      font-size: calc( 23.32381px + 3.20919 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .Newsletter-subtitle {
      font-size: calc( 26.533px + 0.59632 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Newsletter-subtitle {
      font-size: calc( 27.12932px + 1.15495 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Newsletter-subtitle {
      font-size: 28.28427px; } }

.Newsletter-formGroup {
  display: flex; }
  .Newsletter-formGroup .form-field {
    flex-grow: 1; }
  .Newsletter-formGroup .Button, .Newsletter-formGroup .ButtonPrimary, .Newsletter-formGroup .ButtonPrimaryRight, .Newsletter-formGroup .ButtonPrimaryDown, .Newsletter-formGroup .ButtonSecondary, .Newsletter-formGroup .ButtonSecondaryRight, .Newsletter-formGroup .ButtonSecondaryDown, .Newsletter-formGroup .ButtonUnderline, .Newsletter-formGroup .ButtonUnderlineRight, .Newsletter-formGroup .ButtonUnderlineDown, .Newsletter-formGroup .FormBuilder button:not(.dropdown-toggle), .FormBuilder .Newsletter-formGroup button:not(.dropdown-toggle) {
    height: auto; }
    @media (max-width: 991.98px) {
      .Newsletter-formGroup .Button, .Newsletter-formGroup .ButtonPrimary, .Newsletter-formGroup .ButtonPrimaryRight, .Newsletter-formGroup .ButtonPrimaryDown, .Newsletter-formGroup .ButtonSecondary, .Newsletter-formGroup .ButtonSecondaryRight, .Newsletter-formGroup .ButtonSecondaryDown, .Newsletter-formGroup .ButtonUnderline, .Newsletter-formGroup .ButtonUnderlineRight, .Newsletter-formGroup .ButtonUnderlineDown, .Newsletter-formGroup .FormBuilder button:not(.dropdown-toggle), .FormBuilder .Newsletter-formGroup button:not(.dropdown-toggle) {
        min-width: 0; } }

.Newsletter-note {
  margin: 0.5rem 0 0;
  font-size: calc(1rem - 3px);
  opacity: 0.5; }
  .Newsletter-note a {
    text-decoration: underline; }

.PageHeaderEventSimple {
  border-top-width: 3.4375rem ;
  padding-top: 3rem;
  margin-bottom: 6rem;
  border-top-color: transparent;
  border-top-style: solid; }
  @media (min-width: 768px) {
    .PageHeaderEventSimple {
      border-top-width: 6.875rem ; } }
  @media (min-width: 768px) {
    .PageHeaderEventSimple {
      padding-top: 5.55556vw ; } }
  @media (min-width: 768px) {
    .PageHeaderEventSimple {
      margin-bottom: 11.11111vw ; } }

[data-scroll-container] .PageHeaderEventSimple-textCol > * {
  opacity: 0;
  transform: translateY(2rem);
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  transition-property: transform, opacity; }

[data-scroll-container] .PageHeaderEventSimple-textCol.is-inview > * {
  opacity: 1;
  transform: none !important; }

[data-scroll-container] .PageHeaderEventSimple-textCol > * {
  transform: translateX(2rem); }

@media (min-width: 768px) {
  .PageHeaderEventSimple-textCol {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; } }

.PageHeaderEventSimple-textCol > *:nth-child(n+1) {
  transition-delay: 0.08s !important; }

.PageHeaderEventSimple-textCol > *:nth-child(n+2) {
  transition-delay: 0.16s !important; }

.PageHeaderEventSimple-textCol > *:nth-child(n+3) {
  transition-delay: 0.24s !important; }

.PageHeaderEventSimple-textCol > *:nth-child(n+4) {
  transition-delay: 0.32s !important; }

[data-scroll-container] .PageHeaderEventSimple-mediaCol {
  opacity: 0;
  transform: translateY(2rem);
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  transition-property: transform, opacity; }

[data-scroll-container] .PageHeaderEventSimple-mediaCol.is-inview {
  opacity: 1;
  transform: none !important; }

@media (min-width: 768px) {
  .PageHeaderEventSimple-mediaCol {
    margin-left: auto;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 992px) {
  .PageHeaderEventSimple-mediaCol {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

.PageHeaderEventSimple-title {
  font-size: 32.32056px;
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 900;
  margin: 0; }
  @media (min-width: 320px) and (max-width: 768px) {
    .PageHeaderEventSimple-title {
      font-size: calc( 32.32056px + 8.28908 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .PageHeaderEventSimple-title {
      font-size: calc( 40.60963px + 3.44672 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .PageHeaderEventSimple-title {
      font-size: calc( 44.05635px + 18.24153 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .PageHeaderEventSimple-title {
      font-size: calc( 62.29788px + 3.83456 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .PageHeaderEventSimple-title {
      font-size: calc( 66.13245px + 7.8408 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .PageHeaderEventSimple-title {
      font-size: 73.97325px; } }

.PageHeaderEventSimple-subtitle {
  font-size: 27px;
  font-weight: 300;
  margin: 0; }
  @media (min-width: 320px) and (max-width: 768px) {
    .PageHeaderEventSimple-subtitle {
      font-size: calc( 27px + 5 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .PageHeaderEventSimple-subtitle {
      font-size: calc( 32px + 2 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .PageHeaderEventSimple-subtitle {
      font-size: calc( 34px + 10 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .PageHeaderEventSimple-subtitle {
      font-size: calc( 44px + 2 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .PageHeaderEventSimple-subtitle {
      font-size: calc( 46px + 4 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .PageHeaderEventSimple-subtitle {
      font-size: 50px; } }

.PageHeaderEventSimple-canceled {
  margin: 1rem 0 0; }

.PageHeaderEventSimple-date {
  margin: 3rem 0 0; }

.PageHeaderEventSimple-figure {
  margin-top: 1rem;
  background-color: rgba(0, 0, 0, 0.06);
  overflow: hidden;
  position: relative; }

.PageHeaderEventSimple-image {
  transition: opacity 0.3s, transform 1.8s cubic-bezier(0.23, 1, 0.32, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center/cover no-repeat; }
  .Main .PageHeaderEventSimple-image {
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1.05); }
  .Main .is-inview .PageHeaderEventSimple-image.lazyloaded {
    opacity: 1;
    transform: scale3d(1, 1, 1); }

.PageHeaderEventSplit {
  background-color: rgba(0, 0, 0, 0.06); }
  @media (min-width: 992px) {
    .PageHeaderEventSplit {
      display: flex;
      min-height: calc(var(--vh) * 100); } }
  .PageHeaderEventSplit[data-text-inverted] {
    color: #fff; }

.PageHeaderEventSplit-content {
  border-top-width: 3.4375rem ;
  padding-top: 3rem;
  padding-bottom: 4.26667vw ;
  border-top-color: transparent;
  border-top-style: solid; }
  @media (min-width: 768px) {
    .PageHeaderEventSplit-content {
      border-top-width: 6.875rem ; } }
  @media (min-width: 768px) {
    .PageHeaderEventSplit-content {
      padding-top: 5.55556vw ; } }
  @media (min-width: 768px) {
    .PageHeaderEventSplit-content {
      padding-bottom: 1.66667vw ; } }
  @media (min-width: 992px) {
    .PageHeaderEventSplit-content {
      flex: 0 0 50%;
      display: flex; } }

[data-scroll-container] .PageHeaderEventSplit-container {
  opacity: 0;
  transform: translateY(2rem);
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  transition-property: transform, opacity; }

[data-scroll-container] .is-inview .PageHeaderEventSplit-container {
  opacity: 1;
  transform: none !important; }

@media (min-width: 992px) {
  .PageHeaderEventSplit-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column; }
    .PageHeaderEventSplit-container > .row {
      justify-content: center;
      flex-grow: 1; } }

.PageHeaderEventSplit-col {
  display: flex;
  flex-direction: column; }
  @media (min-width: 1440px) {
    .PageHeaderEventSplit-col {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; } }

.PageHeaderEventSplit-category {
  text-transform: uppercase;
  font-weight: 500;
  margin: 0; }

.PageHeaderEventSplit-title {
  font-size: 29.78324px;
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 900;
  margin: 0; }
  @media (min-width: 320px) and (max-width: 768px) {
    .PageHeaderEventSplit-title {
      font-size: calc( 29.78324px + 6.65799 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .PageHeaderEventSplit-title {
      font-size: calc( 36.44124px + 2.72021 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .PageHeaderEventSplit-title {
      font-size: calc( 39.16145px + 14.02826 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .PageHeaderEventSplit-title {
      font-size: calc( 53.1897px + 2.88312 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .PageHeaderEventSplit-title {
      font-size: calc( 56.07282px + 5.83626 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .PageHeaderEventSplit-title {
      font-size: 61.90908px; } }

.PageHeaderEventSplit-subtitle {
  font-size: 27px;
  font-weight: 300;
  margin: 0; }
  @media (min-width: 320px) and (max-width: 768px) {
    .PageHeaderEventSplit-subtitle {
      font-size: calc( 27px + 5 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .PageHeaderEventSplit-subtitle {
      font-size: calc( 32px + 2 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .PageHeaderEventSplit-subtitle {
      font-size: calc( 34px + 10 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .PageHeaderEventSplit-subtitle {
      font-size: calc( 44px + 2 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .PageHeaderEventSplit-subtitle {
      font-size: calc( 46px + 4 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .PageHeaderEventSplit-subtitle {
      font-size: 50px; } }

.PageHeaderEventSplit-canceled {
  margin: 1rem 0; }

.PageHeaderEventSplit-date {
  margin-top: auto;
  padding-top: 4rem; }

.PageHeaderEventSplit-media {
  padding-bottom: 100%;
  position: relative;
  overflow: hidden; }
  @media (min-width: 992px) {
    .PageHeaderEventSplit-media {
      padding: 0;
      flex: 0 0 50%; } }

.PageHeaderEventSplit-figure,
.PageHeaderEventSplit-image,
.PageHeaderEventSplit-figureWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.PageHeaderEventSplit-image {
  transition: opacity 0.3s, transform 1.8s cubic-bezier(0.23, 1, 0.32, 1);
  background: center/cover no-repeat; }
  .Main .PageHeaderEventSplit-image {
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1.05); }
  .Main .is-inview .PageHeaderEventSplit-image.lazyloaded {
    opacity: 1;
    transform: scale3d(1, 1, 1); }

.PageHeaderHero {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column; }
  .PageHeaderHero[data-text-inverted] {
    color: #fff; }
  .PageHeaderHero h1 {
    font-size: 42.6778px;
    font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 900;
    margin: 0; }
    @media (min-width: 320px) and (max-width: 768px) {
      .PageHeaderHero h1 {
        font-size: calc( 42.6778px + 16.01045 * ( ( 100vw - 320px) / 448 )); } }
    @media (min-width: 768px) and (max-width: 992px) {
      .PageHeaderHero h1 {
        font-size: calc( 58.68826px + 7.06511 * ( ( 100vw - 768px) / 224 )); } }
    @media (min-width: 992px) and (max-width: 1440px) {
      .PageHeaderHero h1 {
        font-size: calc( 65.75337px + 40.87403 * ( ( 100vw - 992px) / 448 )); } }
    @media (min-width: 1440px) and (max-width: 1640px) {
      .PageHeaderHero h1 {
        font-size: calc( 106.62739px + 9.26794 * ( ( 100vw - 1440px) / 200 )); } }
    @media (min-width: 1640px) and (max-width: 1920px) {
      .PageHeaderHero h1 {
        font-size: calc( 115.89533px + 19.6123 * ( ( 100vw - 1640px) / 280 )); } }
    @media (min-width: 1920px) {
      .PageHeaderHero h1 {
        font-size: 135.50764px; } }
  .PageHeaderHero p {
    font-size: calc(1rem - 2px);
    font-weight: 400;
    margin: 0;
    padding-top: 1em; }
  .PageHeaderHero [aria-hidden="true"] div {
    display: flex;
    align-items: center;
    flex-direction: column; }
    @media (max-width: 767.98px) {
      .PageHeaderHero [aria-hidden="true"] div:first-child h1 {
        order: 1; }
      .PageHeaderHero [aria-hidden="true"] div:nth-child(odd) h1 {
        align-self: flex-start; }
      .PageHeaderHero [aria-hidden="true"] div:nth-child(odd) p {
        align-self: flex-end;
        text-align: right; }
      .PageHeaderHero [aria-hidden="true"] div:nth-child(even) h1 {
        align-self: flex-start;
        padding-left: 9%; }
      .PageHeaderHero [aria-hidden="true"] div:nth-child(even) p {
        align-self: flex-start; } }
    @media (min-width: 768px) {
      .PageHeaderHero [aria-hidden="true"] div {
        flex-direction: row;
        flex-wrap: wrap; }
        [data-scroll-container] .PageHeaderHero [aria-hidden="true"] div {
          opacity: 0;
          transform: translateY(2rem);
          transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
          transition-property: transform, opacity; }
        [data-scroll-container] .PageHeaderHero [aria-hidden="true"] div.is-inview {
          opacity: 1;
          transform: none !important; }
        [data-scroll-container] .PageHeaderHero [aria-hidden="true"] div:nth-child(odd) {
          transform: translateX(-2rem); }
        .PageHeaderHero [aria-hidden="true"] div:nth-child(odd) h1 {
          margin-right: 1.5rem; }
        .PageHeaderHero [aria-hidden="true"] div:nth-child(even) {
          flex: 0 0 91.66667%;
          max-width: 91.66667%;
          margin-left: 8.33333%; }
          [data-scroll-container] .PageHeaderHero [aria-hidden="true"] div:nth-child(even) {
            transition-delay: 0.25s;
            transform: translateX(2rem); }
          .PageHeaderHero [aria-hidden="true"] div:nth-child(even) h1 {
            order: 2; }
          .PageHeaderHero [aria-hidden="true"] div:nth-child(even) p {
            margin-right: 1.5rem; } }
    @media (min-width: 992px) {
      .PageHeaderHero [aria-hidden="true"] div:nth-child(even) {
        flex: 0 0 90%;
        max-width: 90%;
        margin-left: 10%; } }

.PageHeaderHero:not(.PageHeaderHero--has-media) .ScrollHint {
  display: none; }

.PageHeaderHero-media,
.PageHeaderHero-mediaWrapper,
.PageHeaderHero-mediaWrapper:after,
.PageHeaderHero-image,
.PageHeaderHero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.PageHeaderHero-media {
  background-color: rgba(0, 0, 0, 0.06);
  pointer-events: none;
  display: none; }
  .PageHeaderHero--has-media .PageHeaderHero-media {
    display: block; }

.PageHeaderHero-mediaWrapper {
  transition: opacity 0.3s; }
  .PageHeaderHero-mediaWrapper > * {
    transition: transform 3s cubic-bezier(0.23, 1, 0.32, 1); }
  .Main .PageHeaderHero-mediaWrapper {
    opacity: 0; }
    .Main .PageHeaderHero-mediaWrapper > * {
      transform: scale3d(1.05, 1.05, 1.05); }
  .Main .PageHeaderHero-mediaWrapper.is-inview.lazyloaded {
    opacity: 1; }
    .Main .PageHeaderHero-mediaWrapper.is-inview.lazyloaded > * {
      transform: scale3d(1, 1, 1); }
  .PageHeaderHero-mediaWrapper:after {
    content: '';
    background-color: #000;
    opacity: 0.2; }

.PageHeaderHero-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.PageHeaderHero-video {
  overflow: hidden; }
  .PageHeaderHero-video:not(.lazyloaded) {
    opacity: 0; }
  .PageHeaderHero-video video {
    position: absolute; }

.PageHeaderHero-wrapper {
  border-top-width: 3.4375rem ;
  padding-top: 6rem;
  padding-bottom: 6rem;
  border-top-color: transparent;
  border-top-style: solid;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media (min-width: 768px) {
    .PageHeaderHero-wrapper {
      border-top-width: 6.875rem ; } }
  @media (min-width: 768px) {
    .PageHeaderHero-wrapper {
      padding-top: 5.55556vw ;
      padding-bottom: 5.55556vw ; } }
  .PageHeaderHero--has-media .PageHeaderHero-wrapper {
    min-height: calc(var(--vh) * 100);
    border-bottom-width: 3.4375rem ;
    border-bottom-color: transparent;
    border-bottom-style: solid; }
    @media (min-width: 768px) {
      .PageHeaderHero--has-media .PageHeaderHero-wrapper {
        border-bottom-width: 6.875rem ; } }
  @media (max-width: 767.98px) {
    .PageHeaderHero-wrapper {
      justify-content: flex-end;
      padding-bottom: 2rem; } }

.PageHeaderHero-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 4.26667vw;
  padding-left: 4.26667vw; }
  @media (min-width: 768px) {
    .PageHeaderHero-container {
      padding-right: 3.33333vw;
      padding-left: 3.33333vw; } }

.PageHeaderHero-title {
  margin: 0;
  max-width: 10em; }

.PageHeaderHero-col > h1,
.PageHeaderHero-col > p {
  opacity: 0;
  position: absolute;
  pointer-events: none; }

@media (min-width: 992px) {
  .PageHeaderHero-col {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-left: 8.33333%; } }

[data-scroll-container] .PageHeaderLanding-container {
  opacity: 0;
  transform: translateY(2rem);
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  transition-property: transform, opacity; }

[data-scroll-container] .PageHeaderLanding-container.is-inview {
  opacity: 1;
  transform: none !important; }

.PageHeaderLanding-container:after {
  content: "";
  clear: both;
  display: table; }

.PageHeaderLanding-title {
  font-size: 37.44477px;
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 900;
  margin-bottom: 2rem;
  position: relative;
  z-index: 2;
  width: 91%; }
  @media (min-width: 320px) and (max-width: 768px) {
    .PageHeaderLanding-title {
      font-size: calc( 37.44477px + 11.90598 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .PageHeaderLanding-title {
      font-size: calc( 49.35075px + 5.10933 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .PageHeaderLanding-title {
      font-size: calc( 54.46008px + 28.34093 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .PageHeaderLanding-title {
      font-size: calc( 82.80101px + 6.20237 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .PageHeaderLanding-title {
      font-size: calc( 89.00338px + 12.91471 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .PageHeaderLanding-title {
      font-size: 101.91809px; } }
  .PageHeaderLanding-title:after {
    transform-origin: 0 0;
    content: '';
    display: block;
    height: 6px;
    margin-top: 6rem;
    background-color: currentColor;
    width: 52%; }
    [data-scroll-container] [data-scroll] .PageHeaderLanding-title:after {
      opacity: 0;
      transform: translateY(2rem);
      transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
      transition-property: transform, opacity; }
    [data-scroll-container] [data-scroll].is-inview .PageHeaderLanding-title:after {
      opacity: 1;
      transform: none !important; }
    [data-scroll-container] [data-scroll] .PageHeaderLanding-title:after {
      transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
      transition-delay: 0.25s;
      transform: scaleX(0); }
    @media (min-width: 768px) {
      .PageHeaderLanding-title:after {
        margin-top: 4rem;
        width: 184%; } }
    @media (min-width: 992px) {
      .PageHeaderLanding-title:after {
        width: 141%;
        margin-left: 42.5%; } }
  @media (min-width: 768px) {
    .PageHeaderLanding-title {
      float: left;
      width: 40.5%; } }
  [data-scroll-container] [data-scroll] .PageHeaderLanding-title div {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] [data-scroll].is-inview .PageHeaderLanding-title div {
    opacity: 1;
    transform: none !important; }
  .PageHeaderLanding-title div:first-child {
    margin-left: 10%;
    margin-right: -10%; }
    @media (min-width: 768px) {
      .PageHeaderLanding-title div:first-child {
        margin-left: 15%;
        margin-right: -15%; } }
  [data-scroll-container] .PageHeaderLanding-title div:nth-child(odd) {
    transform: translateY(-2rem) translateX(-2rem); }
  [data-scroll-container] .PageHeaderLanding-title div:nth-child(even) {
    transition-delay: 0.25s;
    transform: translateY(-2rem) translateX(2rem); }

.PageHeaderLanding-media {
  margin-bottom: 2rem;
  margin-top: -6rem;
  padding-left: 8.5%; }
  @media (min-width: 768px) {
    .PageHeaderLanding-media {
      margin-top: 0;
      float: right;
      width: 50%;
      padding-left: 1.11111vw; } }

.PageHeaderLanding-figure {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.06); }

.PageHeaderLanding-image {
  transition: opacity 0.3s, transform 1.8s cubic-bezier(0.23, 1, 0.32, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: center/cover no-repeat; }
  .Main .PageHeaderLanding-image {
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1.05); }
  .Main .is-inview .PageHeaderLanding-image.lazyloaded {
    opacity: 1;
    transform: scale3d(1, 1, 1); }
  .has-scroll-smooth .PageHeaderLanding-image {
    top: calc(var(--vh) * -10);
    bottom: calc(var(--vh) * -10); }

.PageHeaderLanding-body {
  clear: left; }
  @media (min-width: 768px) {
    .PageHeaderLanding-body {
      float: left;
      width: 50%;
      padding-right: 1.11111vw; } }
  @media (min-width: 992px) {
    .PageHeaderLanding-body {
      padding-left: 17.1%; } }
  @media (min-width: 1440px) {
    .PageHeaderLanding-body {
      padding-right: 7%; } }

.PageHeaderText {
  border-top-width: 3.4375rem ;
  padding-top: 3rem;
  margin-bottom: 6rem;
  border-top-color: transparent;
  border-top-style: solid; }
  @media (min-width: 768px) {
    .PageHeaderText {
      border-top-width: 6.875rem ; } }
  @media (min-width: 768px) {
    .PageHeaderText {
      padding-top: 5.55556vw ; } }
  @media (min-width: 768px) {
    .PageHeaderText {
      margin-bottom: 11.11111vw ; } }

.PageHeaderText-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  [data-scroll-container] .PageHeaderText-container > * {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .PageHeaderText-container.is-inview > * {
    opacity: 1;
    transform: none !important; }
  [data-scroll-container] .PageHeaderText-container > * {
    transform: translateX(2rem); }

.PageHeaderText-subtitle {
  font-size: 27px;
  transition-delay: 0.45s !important;
  font-weight: 300;
  margin: 0;
  order: 3; }
  @media (min-width: 320px) and (max-width: 768px) {
    .PageHeaderText-subtitle {
      font-size: calc( 27px + 5 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .PageHeaderText-subtitle {
      font-size: calc( 32px + 2 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .PageHeaderText-subtitle {
      font-size: calc( 34px + 10 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .PageHeaderText-subtitle {
      font-size: calc( 44px + 2 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .PageHeaderText-subtitle {
      font-size: calc( 46px + 4 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .PageHeaderText-subtitle {
      font-size: 50px; } }

.PageHeaderText-title {
  font-size: 37.44477px;
  transition-delay: 0.25s !important;
  width: 100%;
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 900;
  margin: 0; }
  @media (min-width: 320px) and (max-width: 768px) {
    .PageHeaderText-title {
      font-size: calc( 37.44477px + 11.90598 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .PageHeaderText-title {
      font-size: calc( 49.35075px + 5.10933 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .PageHeaderText-title {
      font-size: calc( 54.46008px + 28.34093 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .PageHeaderText-title {
      font-size: calc( 82.80101px + 6.20237 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .PageHeaderText-title {
      font-size: calc( 89.00338px + 12.91471 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .PageHeaderText-title {
      font-size: 101.91809px; } }
  .PageHeaderText-subtitle + .PageHeaderText-title {
    font-size: 32.32056px; }
    @media (min-width: 320px) and (max-width: 768px) {
      .PageHeaderText-subtitle + .PageHeaderText-title {
        font-size: calc( 32.32056px + 8.28908 * ( ( 100vw - 320px) / 448 )); } }
    @media (min-width: 768px) and (max-width: 992px) {
      .PageHeaderText-subtitle + .PageHeaderText-title {
        font-size: calc( 40.60963px + 3.44672 * ( ( 100vw - 768px) / 224 )); } }
    @media (min-width: 992px) and (max-width: 1440px) {
      .PageHeaderText-subtitle + .PageHeaderText-title {
        font-size: calc( 44.05635px + 18.24153 * ( ( 100vw - 992px) / 448 )); } }
    @media (min-width: 1440px) and (max-width: 1640px) {
      .PageHeaderText-subtitle + .PageHeaderText-title {
        font-size: calc( 62.29788px + 3.83456 * ( ( 100vw - 1440px) / 200 )); } }
    @media (min-width: 1640px) and (max-width: 1920px) {
      .PageHeaderText-subtitle + .PageHeaderText-title {
        font-size: calc( 66.13245px + 7.8408 * ( ( 100vw - 1640px) / 280 )); } }
    @media (min-width: 1920px) {
      .PageHeaderText-subtitle + .PageHeaderText-title {
        font-size: 73.97325px; } }

.PageList-headingCol {
  margin-bottom: 3rem; }
  [data-scroll-container] .PageList-headingCol {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .PageList-headingCol.is-inview {
    opacity: 1;
    transform: none !important; }
  @media (min-width: 768px) {
    .PageList-headingCol {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
      margin-left: auto; } }
  @media (min-width: 992px) {
    .PageList-headingCol {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }

[data-scroll-container] .PageList-listCol {
  opacity: 0;
  transform: translateY(2rem);
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  transition-property: transform, opacity; }

[data-scroll-container] .PageList-listCol.is-inview {
  opacity: 1;
  transform: none !important; }

@media (min-width: 768px) {
  .PageList-listCol {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-left: auto; } }

@media (min-width: 992px) {
  .PageList-listCol {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

.PageList-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 4px solid #000; }

.PageList-item {
  padding: 2rem 0 1.6rem;
  border-bottom: 1px solid #000; }
  @media (min-width: 768px) {
    .PageList-item {
      padding: 2rem 0; } }

.PageList-article {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.13333vw;
  margin-left: -2.13333vw; }
  @media (min-width: 768px) {
    .PageList-article {
      margin-right: -1.11111vw;
      margin-left: -1.11111vw; } }

.PageList-link {
  text-decoration: none !important; }

.PageList-media,
.PageList-content {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw;
  flex: 0 0 100%;
  max-width: 100%; }
  @media (min-width: 768px) {
    .PageList-media,
    .PageList-content {
      padding-right: 1.11111vw;
      padding-left: 1.11111vw; } }

@media (max-width: 767.98px) {
  .PageList-media {
    margin-bottom: 1rem; } }

@media (min-width: 768px) {
  .PageList-media {
    order: 1;
    flex: 0 0 40%;
    max-width: 40%; } }

@media (min-width: 992px) {
  .PageList-media {
    flex: 0 0 37.5%;
    max-width: 37.5%; } }

@media (min-width: 768px) {
  .PageList-content {
    flex: 0 0 60%;
    max-width: 60%; } }

@media (min-width: 992px) {
  .PageList-content {
    flex: 0 0 62.5%;
    max-width: 62.5%; } }

.PageList-figure {
  padding-bottom: 75%;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.06); }

.PageList-image {
  transition: opacity 0.3s, transform 1.8s cubic-bezier(0.23, 1, 0.32, 1);
  background: center/cover no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .Main .PageList-image {
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1.05); }
  .Main .is-inview .PageList-image.lazyloaded {
    opacity: 1;
    transform: scale3d(1, 1, 1); }

.PageList-title {
  font-size: 25.08462px;
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 0.3rem;
  transform: opacity 0.3s; }
  @media (min-width: 320px) and (max-width: 768px) {
    .PageList-title {
      font-size: calc( 25.08462px + 3.94341 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .PageList-title {
      font-size: calc( 29.02803px + 1.55243 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .PageList-title {
      font-size: calc( 30.58046px + 7.58509 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .PageList-title {
      font-size: calc( 38.16555px + 1.48616 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .PageList-title {
      font-size: calc( 39.65171px + 2.94556 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .PageList-title {
      font-size: 42.59727px; } }
  .desktop a:hover .PageList-title {
    opacity: 0.7; }

.PageList-subtitle {
  margin: 0;
  text-transform: uppercase;
  text-decoration: none; }
  .PageList-subtitle em,
  .PageList-subtitle strong {
    font-weight: inherit;
    font-style: normal;
    color: #a34e5a; }

.PeopleList[style] {
  padding-top: 6rem;
  padding-bottom: 6rem; }
  @media (min-width: 768px) {
    .PeopleList[style] {
      padding-top: 11.11111vw ; } }
  @media (min-width: 768px) {
    .PeopleList[style] {
      padding-bottom: 11.11111vw ; } }

.PeopleList[data-text-inverted] {
  color: #fff; }

.PeopleList-headingCol {
  margin-bottom: 3rem;
  margin-left: auto; }
  [data-scroll-container] .PeopleList-headingCol {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .PeopleList-headingCol.is-inview {
    opacity: 1;
    transform: none !important; }
  @media (min-width: 768px) {
    .PeopleList-headingCol {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; } }
  @media (min-width: 992px) {
    .PeopleList-headingCol {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }

.PeopleList-listCol {
  margin-left: auto; }
  @media (min-width: 768px) {
    .PeopleList-listCol {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; } }
  @media (min-width: 992px) {
    .PeopleList-listCol {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; } }

.PeopleList-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 4px solid currentColor; }
  [data-scroll-container] .PeopleList-listCol .PeopleList-list {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .PeopleList-listCol.is-inview .PeopleList-list {
    opacity: 1;
    transform: none !important; }

.PeopleList-item {
  padding: 2rem 0 1.6rem;
  border-bottom: 1px solid currentColor; }
  [data-scroll-container] .PeopleList-listCol .PeopleList-item {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .PeopleList-listCol.is-inview .PeopleList-item {
    opacity: 1;
    transform: none !important; }
  .PeopleList-item:nth-child(n+1) {
    transition-delay: 0.1s !important; }
  .PeopleList-item:nth-child(n+2) {
    transition-delay: 0.2s !important; }
  .PeopleList-item:nth-child(n+3) {
    transition-delay: 0.3s !important; }
  .PeopleList-item:nth-child(n+4) {
    transition-delay: 0.4s !important; }
  .PeopleList-item:nth-child(n+5) {
    transition-delay: 0.5s !important; }
  .PeopleList-item:nth-child(n+6) {
    transition-delay: 0.6s !important; }
  .PeopleList-item:nth-child(n+7) {
    transition-delay: 0.7s !important; }
  .PeopleList-item:nth-child(n+8) {
    transition-delay: 0.8s !important; }
  .PeopleList-item:nth-child(n+9) {
    transition-delay: 0.9s !important; }
  .PeopleList-item:nth-child(n+10) {
    transition-delay: 1s !important; }
  @media (min-width: 768px) {
    .PeopleList-item {
      padding: 2rem 0; } }

.PeopleList-article {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.13333vw;
  margin-left: -2.13333vw; }
  @media (min-width: 768px) {
    .PeopleList-article {
      margin-right: -1.11111vw;
      margin-left: -1.11111vw; } }

.PeopleList-media,
.PeopleList-content,
.PeopleList-body {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw; }
  @media (min-width: 768px) {
    .PeopleList-media,
    .PeopleList-content,
    .PeopleList-body {
      padding-right: 1.11111vw;
      padding-left: 1.11111vw; } }

.PeopleList-media {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }
  @media (min-width: 768px) {
    .PeopleList-media {
      flex: 0 0 20%;
      max-width: 20%; } }
  @media (min-width: 992px) {
    .PeopleList-media {
      flex: 0 0 25%;
      max-width: 25%; } }

.PeopleList-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }
  @media (min-width: 768px) {
    .PeopleList-content {
      flex: 0 0 80%;
      max-width: 80%; } }
  @media (min-width: 992px) {
    .PeopleList-content {
      flex: 0 0 75%;
      max-width: 75%; } }

.PeopleList-copyright {
  padding-top: 1rem;
  margin-top: auto; }
  .PeopleList-copyright p {
    margin: 0; }

.PeopleList-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: 1rem;
  margin-top: auto;
  font-weight: 500;
  line-height: 1; }
  .desktop .PeopleList-toggle:hover {
    text-decoration: underline; }
  .PeopleList-toggle .Icon {
    margin-left: 0.25rem;
    transform: translateY(2px); }
  .PeopleList-toggle svg {
    transition: transform 0.4s;
    transform: rotate(1deg); }
  .PeopleList-toggle.is-open svg {
    transform: rotate(135deg); }

.PeopleList-title {
  font-size: 25.08462px;
  font-weight: 900;
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 0.3rem; }
  @media (min-width: 320px) and (max-width: 768px) {
    .PeopleList-title {
      font-size: calc( 25.08462px + 3.94341 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .PeopleList-title {
      font-size: calc( 29.02803px + 1.55243 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .PeopleList-title {
      font-size: calc( 30.58046px + 7.58509 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .PeopleList-title {
      font-size: calc( 38.16555px + 1.48616 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .PeopleList-title {
      font-size: calc( 39.65171px + 2.94556 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .PeopleList-title {
      font-size: 42.59727px; } }
  .PeopleList-title a {
    color: currentColor;
    text-decoration: none !important;
    transition: opacity 0.3s; }
  .PeopleList-title a:hover {
    opacity: 0.7; }

.PeopleList-subtitle {
  font-size: 18.99697px;
  text-transform: uppercase;
  line-height: 1.15;
  margin: 0; }
  @media (min-width: 320px) and (max-width: 768px) {
    .PeopleList-subtitle {
      font-size: calc( 18.99697px + 1.08912 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .PeopleList-subtitle {
      font-size: calc( 20.08609px + 0.40356 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .PeopleList-subtitle {
      font-size: calc( 20.48965px + 1.80886 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .PeopleList-subtitle {
      font-size: calc( 22.29852px + 0.32762 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .PeopleList-subtitle {
      font-size: calc( 22.62614px + 0.62759 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .PeopleList-subtitle {
      font-size: 23.25373px; } }

.PeopleList-figure {
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.06); }

.PeopleList-image {
  transition: opacity 0.3s, transform 1.8s cubic-bezier(0.23, 1, 0.32, 1);
  background: center/cover no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .Main .PeopleList-image {
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1.05); }
  .Main .is-inview .PeopleList-image.lazyloaded {
    opacity: 1;
    transform: scale3d(1, 1, 1); }

.PeopleList-body {
  margin-left: auto; }
  @media (min-width: 768px) {
    .PeopleList-body {
      flex: 0 0 80%;
      max-width: 80%; } }
  @media (min-width: 992px) {
    .PeopleList-body {
      flex: 0 0 75%;
      max-width: 75%; } }
  .PeopleList-body .Prose {
    padding-top: 1rem; }

.Prose h1,
.Prose h2,
.Prose h3,
.Prose h4,
.Prose h5,
.Prose h6 {
  margin-bottom: 1.5rem; }

.Prose p + h1,
.Prose p + h2,
.Prose p + h3,
.Prose p + h4,
.Prose p + h5,
.Prose p + h6, .Prose ul + h1,
.Prose ul + h2,
.Prose ul + h3,
.Prose ul + h4,
.Prose ul + h5,
.Prose ul + h6 {
  margin-top: 3rem; }

.Prose h2 {
  font-size: 27px; }
  @media (min-width: 320px) and (max-width: 768px) {
    .Prose h2 {
      font-size: calc( 27px + 5 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Prose h2 {
      font-size: calc( 32px + 2 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .Prose h2 {
      font-size: calc( 34px + 10 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .Prose h2 {
      font-size: calc( 44px + 2 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Prose h2 {
      font-size: calc( 46px + 4 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Prose h2 {
      font-size: 50px; } }

.Prose h3 {
  font-size: 25.08462px; }
  @media (min-width: 320px) and (max-width: 768px) {
    .Prose h3 {
      font-size: calc( 25.08462px + 3.94341 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Prose h3 {
      font-size: calc( 29.02803px + 1.55243 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .Prose h3 {
      font-size: calc( 30.58046px + 7.58509 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .Prose h3 {
      font-size: calc( 38.16555px + 1.48616 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Prose h3 {
      font-size: calc( 39.65171px + 2.94556 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Prose h3 {
      font-size: 42.59727px; } }

.Prose h4 {
  font-size: 22.92715px; }
  @media (min-width: 320px) and (max-width: 768px) {
    .Prose h4 {
      font-size: calc( 22.92715px + 2.84069 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Prose h4 {
      font-size: calc( 25.76785px + 1.09668 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .Prose h4 {
      font-size: calc( 26.86453px + 5.21007 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .Prose h4 {
      font-size: calc( 32.0746px + 0.99535 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Prose h4 {
      font-size: calc( 33.06995px + 1.95112 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Prose h4 {
      font-size: 35.02107px; } }

.Prose h5 {
  font-size: 20.61537px;
  line-height: 1.265; }
  @media (min-width: 320px) and (max-width: 768px) {
    .Prose h5 {
      font-size: calc( 20.61537px + 1.7683 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Prose h5 {
      font-size: calc( 22.38367px + 0.66704 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .Prose h5 {
      font-size: calc( 23.05072px + 3.06619 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .Prose h5 {
      font-size: calc( 26.11691px + 0.56843 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Prose h5 {
      font-size: calc( 26.68534px + 1.09983 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Prose h5 {
      font-size: 27.78516px; } }

.Prose h6 {
  font-size: 18.99697px;
  line-height: 1.3225; }
  @media (min-width: 320px) and (max-width: 768px) {
    .Prose h6 {
      font-size: calc( 18.99697px + 1.08912 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Prose h6 {
      font-size: calc( 20.08609px + 0.40356 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .Prose h6 {
      font-size: calc( 20.48965px + 1.80886 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .Prose h6 {
      font-size: calc( 22.29852px + 0.32762 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Prose h6 {
      font-size: calc( 22.62614px + 0.62759 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Prose h6 {
      font-size: 23.25373px; } }

.Prose h2,
.Prose h5 {
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

.Prose h3,
.Prose h6 {
  font-weight: 400; }

.Prose h4 {
  font-weight: 300; }

.Prose > *:first-child {
  margin-top: 0 !important; }

.Prose > *:last-child {
  margin-bottom: 0 !important; }

.Prose table {
  width: 100% !important;
  table-layout: fixed;
  border: 0;
  border-top: 3px solid currentColor;
  border-bottom: 1px solid #cccccc;
  color: #000;
  margin: 2rem 0; }

.Prose th,
.Prose td {
  padding: 0.75rem;
  vertical-align: top;
  border: 1px solid #cccccc;
  border-bottom: 0;
  border-right: 0;
  width: 50%; }
  .Prose th:first-child,
  .Prose td:first-child {
    padding-left: 0 !important;
    border-left: 0;
    width: 25%; }
    @media (min-width: 768px) {
      .Prose th:first-child,
      .Prose td:first-child {
        width: 20%; } }
    @media (min-width: 992px) {
      .Prose th:first-child,
      .Prose td:first-child {
        width: 18%; } }
  .Prose th:last-child,
  .Prose td:last-child {
    padding-right: 0 !important; }
  .Prose th > *:last-child,
  .Prose td > *:last-child {
    margin-bottom: 0; }

.Prose thead th {
  vertical-align: bottom; }

.Prose table.table-large td, .Prose table.table-large th {
  padding: 1rem; }
  @media (min-width: 768px) {
    .Prose table.table-large td, .Prose table.table-large th {
      padding: 1.5rem; } }

@media (max-width: 767.98px) {
  .Prose table.table-collapse {
    display: block; }
    .Prose table.table-collapse tbody, .Prose table.table-collapse tr, .Prose table.table-collapse td, .Prose table.table-collapse th {
      display: block; }
    .Prose table.table-collapse td {
      width: 100%;
      padding-left: 0;
      padding-right: 0; }
      .Prose table.table-collapse td:not(:first-child) {
        padding-top: 0; }
      .Prose table.table-collapse td:not(:last-child) {
        border: none; } }

.Prose img {
  display: block;
  max-width: 100%;
  height: auto !important; }
  .Prose img.img-w100 {
    width: 100% !important; }

@media (min-width: 768px) {
  .Prose figcaption {
    width: 50%; } }

.Prose > iframe {
  margin: 1.5rem 0; }

.Prose blockquote {
  margin: 2rem 0; }
  .Prose blockquote:before {
    content: '';
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100px' height='100px' viewBox='0 0 100 100' style='fill: %23000;'%3E%3Cpath d='M20.634 91.027C7.272 91.027 0 80.337 0 67.363c0-17.93 12.617-42.356 39.714-58.39l4.195 6.106C29.77 27.292 22.53 41.788 19.484 52.867c12.213.761 19.826 9.151 19.826 19.453 0 10.317-8.39 18.707-18.676 18.707zm56.091 0c-13.362 0-20.603-10.69-20.603-23.664 0-17.93 12.585-42.356 39.683-58.39L100 15.079C85.892 27.292 78.62 41.788 75.575 52.867c12.213.761 19.857 9.152 19.857 19.453 0 10.317-8.39 18.707-18.707 18.707z'/%3E%3C/svg%3E");
    width: 1em;
    height: 1em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 1rem; }
  .Prose blockquote .Signature {
    flex-direction: row-reverse; }
    .Prose blockquote .Signature:before {
      margin-right: 0;
      margin-left: 0.5em; }
  .Prose blockquote p:not(.Signature):not(.QuoteBig) {
    font-size: 18.99697px;
    font-weight: 700;
    line-height: 1.15; }
    @media (min-width: 320px) and (max-width: 768px) {
      .Prose blockquote p:not(.Signature):not(.QuoteBig) {
        font-size: calc( 18.99697px + 1.08912 * ( ( 100vw - 320px) / 448 )); } }
    @media (min-width: 768px) and (max-width: 992px) {
      .Prose blockquote p:not(.Signature):not(.QuoteBig) {
        font-size: calc( 20.08609px + 0.40356 * ( ( 100vw - 768px) / 224 )); } }
    @media (min-width: 992px) and (max-width: 1440px) {
      .Prose blockquote p:not(.Signature):not(.QuoteBig) {
        font-size: calc( 20.48965px + 1.80886 * ( ( 100vw - 992px) / 448 )); } }
    @media (min-width: 1440px) and (max-width: 1640px) {
      .Prose blockquote p:not(.Signature):not(.QuoteBig) {
        font-size: calc( 22.29852px + 0.32762 * ( ( 100vw - 1440px) / 200 )); } }
    @media (min-width: 1640px) and (max-width: 1920px) {
      .Prose blockquote p:not(.Signature):not(.QuoteBig) {
        font-size: calc( 22.62614px + 0.62759 * ( ( 100vw - 1640px) / 280 )); } }
    @media (min-width: 1920px) {
      .Prose blockquote p:not(.Signature):not(.QuoteBig) {
        font-size: 23.25373px; } }
  .Prose blockquote > * {
    margin-bottom: 0; }
  .Prose blockquote p:not(.Signature):not(:last-child) {
    margin-bottom: 1rem; }

@media (min-width: 992px) {
  .Prose--indent > *:not(table):not(hr) {
    margin-right: 26%; } }

.QuoteBig {
  font-size: 22.92715px;
  font-weight: 700;
  line-height: 1.15; }
  @media (min-width: 320px) and (max-width: 768px) {
    .QuoteBig {
      font-size: calc( 22.92715px + 2.84069 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .QuoteBig {
      font-size: calc( 25.76785px + 1.09668 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .QuoteBig {
      font-size: calc( 26.86453px + 5.21007 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .QuoteBig {
      font-size: calc( 32.0746px + 0.99535 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .QuoteBig {
      font-size: calc( 33.06995px + 1.95112 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .QuoteBig {
      font-size: 35.02107px; } }

.ScrollHint {
  left: 4.26667vw ;
  bottom: 2.13333vw ;
  position: absolute;
  width: 2rem;
  height: 2rem;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  overflow: hidden;
  transform: rotate(90deg);
  transition: opacity 0.3s;
  pointer-events: none;
  z-index: 10; }
  @media (min-width: 768px) {
    .ScrollHint {
      left: 3.33333vw ; } }
  @media (min-width: 768px) {
    .ScrollHint {
      bottom: 1.66667vw ; } }
  @media (max-width: 767.98px) {
    .ScrollHint {
      display: none; } }
  .ScrollHint:after, .ScrollHint:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.8;
    top: 0;
    left: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewbox='0 0 16 16' style='fill: none; stroke: %23fff; stroke-width: 1.3px'%3E%3Cpath d='M9.1 3.1L14 8l-4.9 4.9M2 8h12'/%3E%3C/svg%3E");
    background-size: 1rem 1rem;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    animation: scroll-hint;
    animation: 3s cubic-bezier(0.77, 0, 0.175, 1) 3s infinite scroll-hint; }
  .ScrollHint:after {
    left: -100%; }
  .has-scrolled .ScrollHint {
    opacity: 0; }

@keyframes scroll-hint {
  0% {
    transform: translateX(0); }
  33% {
    transform: translateX(100%); }
  100% {
    transform: translateX(100%); } }

.Section {
  margin-top: 6rem;
  margin-bottom: 6rem;
  position: relative; }
  @media (min-width: 768px) {
    .Section {
      margin-top: 11.11111vw ;
      margin-bottom: 11.11111vw ; } }
  .Main-container > .Section:first-child {
    border-top-width: 3.4375rem ;
    padding-top: 6rem;
    margin-top: 0 !important;
    border-top-color: transparent;
    border-top-style: solid; }
    @media (min-width: 768px) {
      .Main-container > .Section:first-child {
        border-top-width: 6.875rem ; } }
    @media (min-width: 768px) {
      .Main-container > .Section:first-child {
        padding-top: 5.55556vw ; } }
  .Section:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: inherit;
    transform: translateY(1px); }
  .PageHeaderEventSimple + .Section {
    margin-top: -3rem; }
    @media (min-width: 768px) {
      .PageHeaderEventSimple + .Section {
        margin-top: -5.55556vw ; } }

.Signature {
  display: flex;
  align-items: center;
  margin-top: 1rem; }
  .Signature:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 2rem;
    height: 3px;
    transform: translateY(1px);
    background-color: currentColor;
    margin-right: 0.5em; }

.SiteFooter {
  margin-top: 6rem; }
  @media (min-width: 768px) {
    .SiteFooter {
      margin-top: 11.11111vw ; } }
  .SiteFooter ul {
    list-style-type: none;
    margin-left: 0;
    padding: 0;
    display: flex; }
  .SiteFooter a {
    color: currentColor; }

.SiteFooter-content {
  padding: 2rem 0 0.5rem;
  border-top: 1px solid currentColor; }
  @media (min-width: 768px) {
    .SiteFooter-content {
      display: flex; } }

.SiteFooter-colophon {
  display: inline-block;
  white-space: no-wrap; }
  @media (min-width: 768px) {
    .SiteFooter-colophon {
      margin-right: 3rem; } }

.SiteFooter-lists {
  display: flex;
  justify-content: space-between;
  margin-left: auto; }
  @media (max-width: 767.98px) {
    .SiteFooter-lists {
      flex-direction: column; } }

@media (min-width: 768px) {
  .SiteFooter-social {
    margin-right: 3rem; } }

.SiteFooter-social {
  white-space: nowrap; }
  .SiteFooter-social li:not(:first-child):before {
    content: "-";
    padding-left: 0.3em; }

.SiteFooter-languages li.is-active {
  text-decoration: underline; }

.SiteFooter-languages li:not(.is-active) {
  color: rgba(0, 0, 0, 0.6); }

.SiteFooter-languages li:not(:first-child) {
  margin-left: 0.5rem; }

.SiteHeader {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1040; }
  .SiteHeader:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1041;
    background-color: #fff;
    transform: scaleY(0);
    transform-origin: 0 0;
    transition: transform 0.8s cubic-bezier(0.77, 0, 0.175, 1); }
    @media (max-width: 767.98px) {
      .SiteHeader:before {
        z-index: 1043; } }
  .mobile .SiteHeader,
  .tablet .SiteHeader {
    position: fixed;
    transition: transform 0.4s;
    transform: translate3D(0, 0, 0); }
  .page-transitioning--fade .SiteHeader {
    transition: none !important; }
    .page-transitioning--fade .SiteHeader:after, .page-transitioning--fade .SiteHeader:before {
      transition: none !important; }
    .page-transitioning--fade .SiteHeader *,
    .page-transitioning--fade .SiteHeader *:after,
    .page-transitioning--fade .SiteHeader *:before {
      transition: none !important; }

.SiteHeader--not-top:before,
.show-menu .SiteHeader:before {
  transform: scaleY(1); }

.mobile .SiteHeader--unpinned,
.tablet .SiteHeader--unpinned {
  transform: translate3D(0, -100%, 0);
  pointer-events: none; }

.SiteHeader-bar {
  height: 3.4375rem ;
  z-index: 1050;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none; }
  @media (min-width: 768px) {
    .SiteHeader-bar {
      height: 6.875rem ; } }
  .SiteHeader-bar > * {
    pointer-events: auto; }

.SiteHeader-brand,
.SiteHeader-menuToggle {
  color: #000;
  transition: color 0.15s linear 0.35s; }
  .has-inverted-header .SiteHeader-brand, .has-inverted-header
  .SiteHeader-menuToggle {
    color: #fff; }

.SiteHeader--not-top .SiteHeader-brand,
.show-menu .SiteHeader-brand {
  color: #000; }

.show-menu .SiteHeader-brand {
  transition-delay: 0.1s; }

a.SiteHeader-logo {
  color: currentColor !important;
  transition: none; }
  a.SiteHeader-logo svg {
    height: 2.0625rem ;
    width: auto;
    fill: currentColor;
    shape-rendering: geometricPrecision;
    stroke: currentColor;
    stroke-width: 0.1px; }
    @media (min-width: 768px) {
      a.SiteHeader-logo svg {
        height: 3.4375rem ; } }
    a.SiteHeader-logo svg .Logo-art {
      stroke-width: 0.5px; }

.SiteHeader-menuToggle {
  appearance: none;
  border: 0;
  background: none;
  padding: 0;
  height: 1rem;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  align-items: center; }
  .SiteHeader--not-top .SiteHeader-menuToggle,
  .has-inverted-header .SiteHeader-menuToggle {
    transition-delay: 0.3s; }
  .SiteHeader--not-top .SiteHeader-menuToggle {
    color: #000; }
  @media (max-width: 767.98px) {
    .show-menu .SiteHeader-menuToggle {
      color: #000; } }
  @media (min-width: 768px) {
    .show-menu .SiteHeader-menuToggle {
      color: #fff; } }

.SiteHeader-menuIcon {
  width: 2rem;
  height: 2px;
  position: relative; }
  .SiteHeader-menuIcon:after, .SiteHeader-menuIcon:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    transition: transform 0.3s, background 0.15s linear 0.3s; }
    .has-inverted-header .SiteHeader-menuIcon:after, .has-inverted-header .SiteHeader-menuIcon:before {
      background-color: #fff; }
    .SiteHeader--not-top .SiteHeader-menuIcon:after, .SiteHeader--not-top .SiteHeader-menuIcon:before {
      background-color: #000; }
    .show-menu .SiteHeader-menuIcon:after, .show-menu .SiteHeader-menuIcon:before {
      transform: translateY(0); }
      @media (max-width: 767.98px) {
        .show-menu .SiteHeader-menuIcon:after, .show-menu .SiteHeader-menuIcon:before {
          background-color: #000; } }
      @media (min-width: 768px) {
        .show-menu .SiteHeader-menuIcon:after, .show-menu .SiteHeader-menuIcon:before {
          background-color: #fff; } }
  .SiteHeader-menuIcon:after {
    transform: translateY(-4px); }
  .SiteHeader-menuIcon:before {
    transform: translateY(4px); }

.SiteHeader-menuLabel {
  margin-right: 1rem;
  overflow: hidden;
  width: 4em;
  text-align: right;
  position: relative; }
  .SiteHeader-menuLabel span {
    display: block;
    transition: transform .6s; }
  .SiteHeader-menuLabel [data-close] {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-120%); }
  [aria-pressed="true"] .SiteHeader-menuLabel [data-close] {
    transform: translateY(0); }
  [aria-pressed="true"] .SiteHeader-menuLabel [data-open] {
    transform: translateY(120%); }
  @media (max-width: 767.98px) {
    .SiteHeader-menuLabel {
      display: none; } }

.SiteHeader-overlay {
  position: absolute;
  z-index: 1042;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh; }
  html:not(.show-menu) .SiteHeader-overlay {
    pointer-events: none; }
    html:not(.show-menu) .SiteHeader-overlay * {
      pointer-events: none !important; }

.SiteHeader-overlay-bg {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform-origin: 0 0;
  transform: scaleY(0);
  transition: transform 0.8s cubic-bezier(0.77, 0, 0.175, 1); }
  .SiteHeader--not-top .SiteHeader-overlay-bg {
    top: calc(3.4375rem - 1px); }
  @media (min-width: 768px) {
    .SiteHeader--not-top .SiteHeader-overlay-bg {
      top: calc(6.875rem - 1px); } }
  .show-menu .SiteHeader-overlay-bg {
    transform: scaleY(1); }

.SiteHeader-overlay-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.SiteHeader-overlay-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f2f2;
  padding-top: 1rem;
  padding-bottom: 1rem;
  opacity: 0;
  transform: translateY(110%);
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s, opacity 0.1s 0.3s; }
  .show-menu .SiteHeader-overlay-footer {
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.6s, opacity 0.1s 0s; }
  @media (min-width: 768px) {
    .SiteHeader-overlay-footer {
      display: none !important; } }
  .SiteHeader-overlay-footer .container {
    display: flex;
    align-items: center; }
  .SiteHeader-overlay-footer a {
    text-decoration: none !important;
    color: currentColor !important; }
  .SiteHeader-overlay-footer > a {
    font-size: 1.125rem; }
  .SiteHeader-overlay-footer ul {
    margin: 0 0 0 auto;
    padding: 0;
    list-style-type: none;
    display: flex;
    font-size: calc(1rem - 2px); }
  .SiteHeader-overlay-footer li.is-active {
    text-decoration: underline; }
  .SiteHeader-overlay-footer li:not(.is-active) {
    color: rgba(0, 0, 0, 0.5); }
  .SiteHeader-overlay-footer li:not(:first-child) {
    margin-left: 0.5rem; }

.Status {
  font-size: calc(1rem - 2px);
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  padding: 0.2em 0.5rem 0.3em;
  background-color: #000;
  color: #fff; }
  .Status .Icon {
    display: inline-block;
    margin-right: 0.2rem;
    position: relative;
    top: -0.075rem; }

.Status--error {
  background-color: #a34e5a; }

.Story {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1230;
  overflow: hidden; }
  .Story[data-text-inverted] {
    color: #fff; }
  [data-scroll-container] .Story {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; }
    [data-scroll-container] .Story.is-ready {
      pointer-events: auto; }
  .Story .swiper-container {
    overflow: visible; }
  .Story .swiper-pagination {
    bottom: -1rem; }
    @media (min-width: 768px) {
      .Story .swiper-pagination {
        display: none; } }
  .Story .swiper-pagination-bullet {
    background: currentColor !important; }

.Story-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transform: scaleY(0);
  transform-origin: 0 0;
  transition: transform 0.8s cubic-bezier(0.77, 0, 0.175, 1); }
  .Story.is-ready .Story-bg {
    transform: scaleY(1); }

.Story-header {
  height: 3.4375rem ;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  opacity: 0; }
  @media (min-width: 768px) {
    .Story-header {
      height: 6.875rem ; } }
  .Story.is-ready .Story-header {
    opacity: 1;
    transition: opacity 0.3s;
    transition-delay: 0.4s; }

.Story-title {
  margin: 0 0 0 0.75rem;
  font-weight: 400;
  font-size: 1.25rem; }

.Story-close {
  width: 2rem;
  height: 2rem;
  padding: 0;
  font-size: 0;
  color: currentColor;
  background: none;
  apparance: none;
  border: none;
  margin-left: auto;
  position: relative; }
  .Story-close .Icon {
    width: 1.5rem;
    height: 1.5rem;
    stroke-width: 0.86667px; }

.Story-gallery {
  width: 100%;
  position: relative;
  z-index: 3;
  opacity: 0;
  transform: translateX(3rem); }
  .Story.is-ready .Story-gallery {
    opacity: 1;
    transition: opacity 0.3s, transform 0.4s;
    transform: none;
    transition-delay: 0.5s; }

.Story-slide {
  width: auto;
  padding-left: 2.13333vw ;
  padding-right: 2.13333vw ; }
  @media (min-width: 768px) {
    .Story-slide {
      padding-left: 2.22222vw ;
      padding-right: 2.22222vw ; } }

.Story-content {
  position: relative;
  max-width: 85vw;
  opacity: 0.5;
  transition: opacity 0.3s, transform 0.6s;
  padding-top: 1rem;
  height: calc(100vh - 6.875rem); }
  @media (min-width: 768px) {
    .Story-content {
      height: calc(100vh - 10.3125rem); } }
  .swiper-slide-active .Story-content {
    opacity: 1;
    transform: none !important; }
  .desktop .Story-content {
    transform: scale(0.9); }

.Story-placeholder {
  height: 100%;
  max-width: none;
  width: auto; }

.Story-image {
  position: absolute;
  background: center/contain no-repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.Story-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.Story-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  apparance: none;
  background: none;
  border: none;
  padding: 0;
  color: #fff; }
  .Story-play .Icon {
    width: 4rem;
    height: 4rem; }
  .swiper-slide-active .Story-play {
    display: block; }

.Story-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  fill: none;
  width: 3rem;
  height: 3rem; }
  @media (max-width: 767.98px) {
    .Story-progress {
      transform: translate(-50%, -50%) rotate(-90deg) scale(0.8); } }
  .Story-progress circle {
    transition: stroke-dashoffset 0.35s;
    stroke-width: 3px;
    stroke: currentColor;
    opacity: 0.3; }

.StoryPreview {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  color: #fff;
  display: inline-block; }
  .StoryPreview:after, .StoryPreview:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .StoryPreview:before {
    background: rgba(0, 0, 0, 0.2); }
  .StoryPreview:after {
    background-color: #fff;
    transform: scale(0.95);
    z-index: 2; }
  @media (max-width: 767.98px) {
    .StoryPreview {
      width: 3rem;
      height: 3rem; }
      .Story .StoryPreview {
        width: 2rem;
        height: 2rem; } }
  .StoryPreview .Icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    width: 28.57143%;
    height: 350%;
    z-index: 4; }

.StoryPreview-image {
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 3;
  transform: scale(0.9);
  background-color: inherit;
  display: block; }
  .StoryPreview-image:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2); }
  .StoryPreview-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .3s; }
    .StoryPreview-image img.lazyloaded {
      opacity: 1; }

.SvgLibrary {
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  border: none;
  overflow: hidden; }

@media (min-width: 768px) {
  .TextCols-row {
    justify-content: flex-end; } }

.TextCols-col {
  margin-top: 2rem; }
  @media (min-width: 768px) {
    .TextCols-col {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; } }
  @media (min-width: 992px) {
    .TextCols-col {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
      .TextCols-col:nth-child(2) {
        margin-top: 0 !important; }
      .TextCols-col:nth-child(odd):not(:only-child):not(:last-child) {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
        margin-right: auto; }
        .TextCols-col:nth-child(odd):not(:only-child):not(:last-child) .TextCols-prose > * {
          margin-right: 0; } }
  .TextCols-col:nth-child(1) {
    margin-top: 0 !important; }

[data-scroll-container] .TextCols-prose {
  opacity: 0;
  transform: translateY(2rem);
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  transition-property: transform, opacity; }

[data-scroll-container] .TextCols-prose.is-inview {
  opacity: 1;
  transform: none !important; }

.TextHighlight {
  text-align: center; }

@media (min-width: 768px) {
  .TextHighlight-row {
    justify-content: center; } }

@media (min-width: 768px) {
  .TextHighlight-col {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 992px) {
  .TextHighlight-col {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

.TextHighlight-text {
  font-size: 30.37341px;
  font-family: "NoeDisplay", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin: 0;
  font-weight: 700;
  line-height: 1.2; }
  [data-scroll-container] .TextHighlight-col .TextHighlight-text {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .TextHighlight-col.is-inview .TextHighlight-text {
    opacity: 1;
    transform: none !important; }
  @media (min-width: 320px) and (max-width: 768px) {
    .TextHighlight-text {
      font-size: calc( 30.37341px + 7.02746 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .TextHighlight-text {
      font-size: calc( 37.40087px + 2.88332 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .TextHighlight-text {
      font-size: calc( 40.28419px + 14.96202 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .TextHighlight-text {
      font-size: calc( 55.24622px + 3.09176 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .TextHighlight-text {
      font-size: calc( 58.33797px + 6.27374 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .TextHighlight-text {
      font-size: 64.61171px; } }
  .TextHighlight--bigText .TextHighlight-text {
    font-size: 36.23409px; }
    @media (min-width: 320px) and (max-width: 768px) {
      .TextHighlight--bigText .TextHighlight-text {
        font-size: calc( 36.23409px + 11.01411 * ( ( 100vw - 320px) / 448 )); } }
    @media (min-width: 768px) and (max-width: 992px) {
      .TextHighlight--bigText .TextHighlight-text {
        font-size: calc( 47.2482px + 4.69348 * ( ( 100vw - 768px) / 224 )); } }
    @media (min-width: 992px) and (max-width: 1440px) {
      .TextHighlight--bigText .TextHighlight-text {
        font-size: calc( 51.94168px + 25.76172 * ( ( 100vw - 992px) / 448 )); } }
    @media (min-width: 1440px) and (max-width: 1640px) {
      .TextHighlight--bigText .TextHighlight-text {
        font-size: calc( 77.7034px + 5.58764 * ( ( 100vw - 1440px) / 200 )); } }
    @media (min-width: 1640px) and (max-width: 1920px) {
      .TextHighlight--bigText .TextHighlight-text {
        font-size: calc( 83.29104px + 11.58761 * ( ( 100vw - 1640px) / 280 )); } }
    @media (min-width: 1920px) {
      .TextHighlight--bigText .TextHighlight-text {
        font-size: 94.87865px; } }

.TextHighlight-caption {
  transition-delay: 0.3s !important;
  font-size: calc(1rem + 2px);
  justify-content: center; }
  [data-scroll-container] .TextHighlight-col .TextHighlight-caption {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .TextHighlight-col.is-inview .TextHighlight-caption {
    opacity: 1;
    transform: none !important; }

.TextImage:not(.TextImage--items-center) [data-scroll-speed] {
  transform: none !important; }

.TextImage-item {
  justify-content: space-between; }
  .TextImage--items-center .TextImage-item {
    align-items: center; }
  .TextImage-item:not(:last-child) {
    margin-bottom: 6rem; }
    @media (min-width: 768px) {
      .TextImage-item:not(:last-child) {
        margin-bottom: 11.11111vw ; } }

.TextImage-media {
  transition-delay: 0.05s !important; }
  [data-scroll-container] .TextImage-media {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .TextImage-media.is-inview {
    opacity: 1;
    transform: none !important; }
  @media (min-width: 768px) {
    .TextImage-media {
      flex: 0 0 50%;
      max-width: 50%; }
      .TextImage:not(.TextImage--inverted) .TextImage-item:nth-child(even) .TextImage-media {
        order: 2;
        transition-delay: 0.3s !important; }
      .TextImage--inverted .TextImage-item:nth-child(odd) .TextImage-media {
        order: 2; } }

@media (min-width: 768px) {
  .TextImage-content {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (min-width: 992px) {
  .TextImage-content {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; } }

.TextImage-figure {
  background-color: rgba(0, 0, 0, 0.06);
  position: relative;
  overflow: hidden; }
  @media (max-width: 767.98px) {
    .TextImage-figure {
      margin-bottom: 2rem; } }
  @media (min-width: 768px) {
    .TextImage-item:nth-child(odd) .TextImage-figure {
      margin-right: 0; }
    .TextImage-item:nth-child(even) .TextImage-figure {
      margin-left: 0; } }

.TextImage-image {
  transition: opacity 0.3s, transform 1.8s cubic-bezier(0.23, 1, 0.32, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: center/cover no-repeat; }
  .Main .TextImage-image {
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1.05); }
  .Main .is-inview .TextImage-image.lazyloaded {
    opacity: 1;
    transform: scale3d(1, 1, 1); }

.TextImage-image-placeholder {
  visibility: hidden; }

[data-scroll-container] .TextImage-content .TextImage-prose {
  opacity: 0;
  transform: translateY(2rem);
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  transition-property: transform, opacity; }

[data-scroll-container] .TextImage-content.is-inview .TextImage-prose {
  opacity: 1;
  transform: none !important; }

@media (min-width: 768px) {
  .TextIntro-row {
    justify-content: center; } }

@media (min-width: 768px) {
  .TextIntro-col {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 992px) {
  .TextIntro-col {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

.TextIntro-title {
  font-size: 24.75862px;
  margin: 0;
  font-weight: 400;
  line-height: 1.3; }
  [data-scroll-container] .TextIntro-col .TextIntro-title {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .TextIntro-col.is-inview .TextIntro-title {
    opacity: 1;
    transform: none !important; }
  @media (min-width: 320px) and (max-width: 768px) {
    .TextIntro-title {
      font-size: calc( 24.75862px + 3.77073 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .TextIntro-title {
      font-size: calc( 28.52935px + 1.48024 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .TextIntro-title {
      font-size: calc( 30.00959px + 7.20285 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .TextIntro-title {
      font-size: calc( 37.21244px + 1.4061 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .TextIntro-title {
      font-size: calc( 38.61855px + 2.78242 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .TextIntro-title {
      font-size: 41.40097px; } }

.TextIntro-caption {
  font-size: calc(1rem - 2px);
  line-height: 1.5;
  vertical-align: middle;
  display: inline-block;
  padding-bottom: 0.25em;
  padding-right: 1.5em; }
  [data-scroll-container] .TextIntro-col .TextIntro-caption {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .TextIntro-col.is-inview .TextIntro-caption {
    opacity: 1;
    transform: none !important; }
  [data-scroll-container] .TextIntro-col .TextIntro-caption {
    transform: translateX(-1.5rem); }
  [data-scroll-container] .TextIntro-col.is-inview .TextIntro-caption {
    transform: none; }

@media (min-width: 768px) {
  .TextStory-row {
    justify-content: center; } }

@media (min-width: 768px) {
  .TextStory-col {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 992px) {
  .TextStory-col {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

.TextStory-title {
  font-size: 30.77335px;
  font-weight: 400;
  line-height: 1.3; }
  [data-scroll-container] .TextStory-col .TextStory-title {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .TextStory-col.is-inview .TextStory-title {
    opacity: 1;
    transform: none !important; }
  @media (min-width: 320px) and (max-width: 768px) {
    .TextStory-title {
      font-size: calc( 30.77335px + 7.28128 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .TextStory-title {
      font-size: calc( 38.05463px + 2.99589 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .TextStory-title {
      font-size: calc( 41.05052px + 15.61069 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .TextStory-title {
      font-size: calc( 56.66121px + 3.23748 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .TextStory-title {
      font-size: calc( 59.89869px + 6.58001 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .TextStory-title {
      font-size: 66.4787px; } }

.TextStory-caption {
  font-size: calc(1rem - 2px);
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 0.25em;
  padding-right: 1.5em; }
  [data-scroll-container] .TextStory-col .TextStory-caption {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .TextStory-col.is-inview .TextStory-caption {
    opacity: 1;
    transform: none !important; }
  [data-scroll-container] .TextStory-col .TextStory-caption {
    transform: translateX(-1.5rem); }
  [data-scroll-container] .TextStory-col.is-inview .TextStory-caption {
    transform: none; }

.TextStory-text {
  display: inline; }
  .TextStory-text p {
    display: inline; }
  .TextStory-text p + p {
    display: inline-block;
    margin-top: .75em; }

.TextStory-trigger {
  display: inline-flex;
  align-items: center;
  cursor: pointer; }
  .TextStory-trigger .StoryPreview {
    margin-left: 1rem; }

.TextStory-label {
  text-shadow: -2px -2px 0 #fff, 0 -2px 0 #fff, 2px -2px 0 #fff, 2px 0 0 #fff, 2px 2px 0 #fff, 0 2px 0 #fff, -2px 2px 0 #fff, -2px 0 0 #fff;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 93%;
  background-repeat: no-repeat;
  background-size: 100% 0.06em;
  display: inline-block; }

.Video.Video--full {
  margin: 0; }

.Video.Video--right [data-scroll-speed] {
  transform: none !important;
  will-transform: none; }

.Video--full .Video-container {
  padding: 0; }

[data-scroll-container] .Video-col {
  opacity: 0;
  transform: translateY(2rem);
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  transition-property: transform, opacity; }

[data-scroll-container] .Video-col.is-inview {
  opacity: 1;
  transform: none !important; }

@media (min-width: 768px) {
  .Video--right .Video-col {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-left: auto; } }

.Video-media {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.06); }

.Video-figure,
.Video-video,
.Video-image,
.Video-wrapper,
.Video-wrapper:after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.Video-wrapper {
  transition: opacity 0.3s; }
  .Video-wrapper > * {
    transition: transform 3s cubic-bezier(0.23, 1, 0.32, 1); }
  .Main .Video-wrapper {
    opacity: 0; }
    .Main .Video-wrapper > * {
      transform: scale3d(1.05, 1.05, 1.05); }
  .Main .Video-wrapper.is-inview.lazyloaded {
    opacity: 1; }
    .Main .Video-wrapper.is-inview.lazyloaded > * {
      transform: scale3d(1, 1, 1); }
  .has-scroll-smooth .Video-wrapper {
    top: calc(var(--vh) * -10);
    bottom: calc(var(--vh) * -10); }
  .Video-wrapper:after {
    content: '';
    background-color: #000;
    opacity: 0.2; }

.Video-image {
  background: center/cover no-repeat; }
  .VideoList .Video-image {
    transition: opacity 0.3s, transform 1.8s cubic-bezier(0.23, 1, 0.32, 1);
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1.05); }
    .VideoList .Video-image.lazyloaded {
      opacity: 1;
      transform: scale3d(1, 1, 1); }

.Video-video:not(.lazyloaded) {
  opacity: 0; }

.Video-video video {
  position: absolute; }

.Video-link {
  display: block;
  position: relative; }
  .Video-link:after {
    color: #fff;
    content: attr(title);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(1rem - 2px);
    font-weight: 500;
    text-transform: uppercase;
    z-index: 2; }
  .Video-link:before {
    color: #fff;
    z-index: 3;
    content: '';
    position: absolute;
    width: 20%;
    height: 0;
    padding-bottom: 20%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    border: 1px solid #fff;
    border-radius: 50%; }
    @media (min-width: 768px) {
      .Video-link:before {
        width: 10%;
        padding-bottom: 10%; }
        .VideoList .Video-link:before {
          width: 20%;
          padding-bottom: 20%; } }
  .desktop .Video-link:hover:before {
    transform: translate(-50%, -50%) scale(1.1); }

.Video-title {
  margin-top: 1rem;
  font-size: 20.44751px;
  font-weight: 400;
  margin-bottom: 0.5rem; }
  @media (min-width: 320px) and (max-width: 768px) {
    .Video-title {
      font-size: calc( 20.44751px + 1.69504 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Video-title {
      font-size: calc( 22.14256px + 0.63827 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .Video-title {
      font-size: calc( 22.78083px + 2.92652 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .Video-title {
      font-size: calc( 25.70734px + 0.54128 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Video-title {
      font-size: calc( 26.24862px + 1.04624 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Video-title {
      font-size: 27.29486px; } }
  .Video-title a {
    transition: opacity .3s;
    text-decoration: none; }
    .desktop .Video-title a:hover {
      opacity: 0.7; }

.Video-abstract {
  line-height: 1.5;
  color: #999999;
  margin-bottom: 1.5rem; }

.VideoList-container > .row {
  justify-content: center; }

.VideoList-sectionTitle {
  font-size: 20.44751px;
  margin-bottom: 3rem; }
  [data-scroll-container] .VideoList-sectionTitle {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .VideoList-sectionTitle.is-inview {
    opacity: 1;
    transform: none !important; }
  @media (min-width: 320px) and (max-width: 768px) {
    .VideoList-sectionTitle {
      font-size: calc( 20.44751px + 1.69504 * ( ( 100vw - 320px) / 448 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .VideoList-sectionTitle {
      font-size: calc( 22.14256px + 0.63827 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1440px) {
    .VideoList-sectionTitle {
      font-size: calc( 22.78083px + 2.92652 * ( ( 100vw - 992px) / 448 )); } }
  @media (min-width: 1440px) and (max-width: 1640px) {
    .VideoList-sectionTitle {
      font-size: calc( 25.70734px + 0.54128 * ( ( 100vw - 1440px) / 200 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .VideoList-sectionTitle {
      font-size: calc( 26.24862px + 1.04624 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .VideoList-sectionTitle {
      font-size: 27.29486px; } }
  @media (min-width: 768px) {
    .VideoList-sectionTitle {
      margin-bottom: 5.55556vw ; } }
  .VideoList-sectionTitle:nth-child(1) {
    margin-top: 0; }

@media (min-width: 992px) {
  .VideoList-list {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; } }

.VideoList-list > .row {
  justify-content: space-between; }

.VideoList-item {
  margin-top: 3rem; }
  [data-scroll-container] .VideoList-item {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transition-property: transform, opacity; }
  [data-scroll-container] .VideoList-item.is-inview {
    opacity: 1;
    transform: none !important; }
  @media (min-width: 768px) {
    .VideoList-item {
      margin-top: 5.55556vw ; } }
  @media (min-width: 768px) {
    .VideoList-item {
      flex: 0 0 50%;
      max-width: 50%; }
      .VideoList-item:nth-child(2) {
        margin-top: 0; }
      .VideoList-item:nth-child(even) {
        transition-delay: 0.35s !important; } }
  @media (min-width: 992px) {
    .VideoList-item:nth-child(even) {
      margin-right: -2.22222vw; }
    .VideoList-item:nth-child(odd) {
      margin-left: -2.22222vw; } }
  .VideoList-item:nth-child(1) {
    margin-top: 0; }
