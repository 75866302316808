.MenuSimple {
  display: flex;
  flex-direction: column;
  @include breakpoint-menu-up() {
    display: none;
  }
}

.MenuSimple-container {
  min-height: 100vh;
  padding-top: 6rem;
  padding-bottom: 8rem;
  display: flex;
  flex-direction: column;
}

.MenuSimple-nav {
  a {
    color: $color-text;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none !important;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul ul li:last-child {
    padding-bottom: 10px;
  }

  > ul > li {
    border-bottom: 1px solid color("gray-95");
    opacity: 0;
    transform: translateY(1rem);
    transition: transform 0.3s 0.3s, opacity 0.3s;
    .show-menu & {
      opacity: 1;
      transform: translateY(0);
      transition-duration: 0.6s;
      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          @include transition-delay($i - 1, 0.3s, 0.05s);
        }
      }
    }
    &:first-child {
      display: none;
    }
  }

  > ul > li > a {
    font-family: $font-family-serif;
    font-size: rem(22px);
    font-weight: 700;
    padding: 0.3em 0;
  }

  ul ul a {
    padding: 0.15em 0;
  }

  .is-toggle {
    &:after {
      content: '';
      width: 2rem;
      height: 2rem;
      transition: transform 0.3s;
      transform: translateZ(0);
      background: url(#{svg("chevron-down", "fill: none; stroke: #000; stroke-width: #{$icon-stroke-width}")}) center no-repeat;
    }
    &.is-open:after {
      transform: rotate(-180deg) translateZ(0);
    }
  }

  [data-toggle] {
    display: block;
  }
  ul ul [data-toggle] {
    // background-image: url(svg("plus", "fill: none; stroke: #000; stroke-width: 1"));
    // &.is-open {
    //   transform: rotate(135deg) translateZ(0);
    // }
  }
}
