.SiteHeader {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: $zindex-site-header;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $zindex-site-header-bg;
    background-color: #fff;
    transform: scaleY(0);
    transform-origin: 0 0;
    transition: transform 0.8s cubic-bezier("easeInOutQuart");
    @include media-breakpoint-down(xs) {
      z-index: $zindex-site-header-bg-xs;
    }
  }
  .mobile &,
  .tablet & {
    position: fixed;
    transition: transform 0.4s;
    transform: translate3D(0, 0, 0);
  }

  .page-transitioning--fade & {
    transition: none !important;
    &:after,
    &:before {
      transition: none !important;
    }
    *,
    *:after,
    *:before {
      transition: none !important;
    }
  }
}

.SiteHeader--not-top,
.show-menu .SiteHeader {
  &:before {
    transform: scaleY(1);
  }
}

.SiteHeader--unpinned {
  .mobile &,
  .tablet & {
    transform: translate3D(0, -100%, 0);
    pointer-events: none;
  }
}

.SiteHeader-bar {
  @include res-prop("height", $site-header-heights);
  z-index: $zindex-site-header-bar;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  > * {
    pointer-events: auto;
  }
}

.SiteHeader-brand,
.SiteHeader-menuToggle {
  color: $color-text;
  transition: color 0.15s linear 0.35s;
  .has-inverted-header & {
    color: $color-text-inverted;
  }
}

.SiteHeader-brand {
  .SiteHeader--not-top &,
  .show-menu & {
    color: $color-text;
  }
  .show-menu & {
    transition-delay: 0.1s;
  }
}

a.SiteHeader-logo {
  color: currentColor !important;
  transition: none;
  svg {
    @include res-prop("height", $site-header-logo-heights);
    width: auto;
    fill: currentColor;
    shape-rendering: geometricPrecision;
    stroke: currentColor;
    stroke-width: 0.1px;

    .Logo-art {
      stroke-width: 0.5px;
    }
  }
}

.SiteHeader-menuToggle {
  appearance: none;
  border: 0;
  background: none;
  padding: 0;
  height: 1rem;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  .SiteHeader--not-top &,
  .has-inverted-header & {
    transition-delay: 0.3s;
  }
  .SiteHeader--not-top & {
    color: $color-text;
  }
  .show-menu & {
    @include breakpoint-menu-down() {
      color: $color-text;
    }
    @include breakpoint-menu-up() {
      color: $color-text-inverted;
    }
  }
}

.SiteHeader-menuIcon {
  width: 2rem;
  height: 2px;
  position: relative;
  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $color-text;
    transition: transform 0.3s, background 0.15s linear 0.3s;
    .has-inverted-header & {
      background-color: $color-text-inverted;
    }
    .SiteHeader--not-top & {
      background-color: $color-text;
    }
    .show-menu & {
      transform: translateY(0);
      @include breakpoint-menu-down() {
        background-color: $color-text;
      }
      @include breakpoint-menu-up() {
        background-color: $color-text-inverted;
      }
    }
  }
  &:after {
    transform: translateY(-4px);
  }
  &:before {
    transform: translateY(4px);
  }
}

.SiteHeader-menuLabel {
  margin-right: 1rem;
  overflow: hidden;
  width: 4em;
  text-align: right;
  position: relative;
  span {
    display: block;
    transition: transform .6s;
  }
  [data-close] {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-120%);
  }
  [aria-pressed="true"] & {
    [data-close] {
      transform: translateY(0);
    }
    [data-open] {
      transform: translateY(120%);
    }
  }
  @include breakpoint-menu-down() {
    display: none;
  }
}

.SiteHeader-overlay {
  position: absolute;
  z-index: $zindex-site-header-overlay;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  html:not(.show-menu) & {
    pointer-events: none;
    * {
      pointer-events: none !important;
    }
  }
}

.SiteHeader-overlay-bg {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform-origin: 0 0;
  transform: scaleY(0);
  transition: transform 0.8s cubic-bezier("easeInOutQuart");
  @each $breakpoint in map-keys($site-header-heights) {
    @include media-breakpoint-up($breakpoint) {
      .SiteHeader--not-top & {
        $value: map-get($site-header-heights, $breakpoint);
        top: calc(#{$value} - 1px);
      }
    }
  }
  // .SiteHeader--not-top &,
  // .header-solid & {
  //   transform: translateY(0) scaleY(0);
  // }
  .show-menu & {
    // transition-duration: 0.6s;
    transform: scaleY(1);
  }
}

.SiteHeader-overlay-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.SiteHeader-overlay-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: color("gray-95");
  padding-top: 1rem;
  padding-bottom: 1rem;
  opacity: 0;
  transform: translateY(110%);
  transition: transform 0.3s cubic-bezier("easeOutQuart") 0s, opacity 0.1s 0.3s;
  .show-menu & {
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.6s cubic-bezier("easeOutQuart") 0.6s, opacity 0.1s 0s;
  }
  @include breakpoint-menu-up() {
    display: none !important;
  }
  .container {
    display: flex;
    align-items: center;
  }
  a {
    text-decoration: none !important;
    color: currentColor !important;
  }
  > a {
    font-size: 18/16 * 1rem;
  }
  ul {
    margin: 0 0 0 auto;
    padding: 0;
    list-style-type: none;
    display: flex;
    font-size: calc(1rem - 2px);
  }
  li.is-active {
    text-decoration: underline;
  }
  li:not(.is-active) {
    color: rgba(#000, 0.5);
  }
  li:not(:first-child) {
    margin-left: 0.5rem;
  }
}
