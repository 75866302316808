.BannerSlides-containerPoster {
  display: none;
  height: calc(var(--vh) * 90);
  position: relative;
  .desktop & {
    display: block;
  }
}

.BannerSlides-linkWrapper {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


.BannerSlides-link {
  color: $color-text-inverted !important;
  text-decoration: none !important;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  border-right: 1px solid currentColor;

  &:nth-child(1) {
    align-items: flex-end;
    justify-content: flex-start;

    .BannerSlides-title {
      text-indent: 1em;
      transform: translateY(2em);
      max-width: 90%;
      &:first-line {
        text-align: right;
      }
    }
  }

  &:nth-child(2) {
    justify-content: flex-end;
    .BannerSlides-title {
      text-indent: 1em;
      transform: translateY(-2em);
    }
  }

  &:nth-child(3) {
    border-right: 0;
    justify-content: center;

    .BannerSlides-title {
      text-indent: -1em;
      padding-left: 1em;
      transform: translateY(-2em);
    }
  }
}

.BannerSlides-title {
  @include ms-respond(font-size, 37);
  font-family: $font-family-serif;
  opacity: 0.6;
  color: currentColor;
  transition: 0.3s opacity;
  margin: 0;
  will-change: opacity;
  a:hover & {
    opacity: 1;
  }
}

.BannerSlides-image,
.BannerSlides-figure {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.BannerSlides-figure {
  background-color: $color-placeholder;
  overflow: hidden;
  opacity: 0;
  transition: 0.6s opacity;
  pointer-events: none;
  will-change: opacity;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
  &.is-active {
    opacity: 1;
  }
}

.BannerSlides-image {
  @include lazyloaded();
  background: center/cover no-repeat;
}



















//card
.BannerSlides-containerCards {
  @include reveal();
  overflow: hidden;
  .desktop & {
    display: none;
  }
  .swiper-container {
    overflow: visible;
  }
  .swiper-wrapper {
    align-items: center;
  }
  .swiper-slide {
    @include res-prop("margin-right", $grid-container-paddings);
    width: 80%;
    @include media-breakpoint-up(sm) {
      width: 40%;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.BannerSlides-card {
  text-decoration: none !important;
}

.BannerSlides-cardFigure {
  display: block;
  position: relative;
  background-color: $color-placeholder;
  padding-bottom: 3/4 * 100%;
  overflow: hidden;
}

.BannerSlides-cardImage {
  @include lazyloaded();
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: center/cover no-repeat;
}

.BannerSlides-cardTitle {
  margin: 1rem 0;
  @include ms-respond(font-size, 30);
  font-family: $font-family-serif;
  a {
    text-decoration: none;
  }
}
