.TextStory-row {
  @include media-breakpoint-up(sm) {
    justify-content: center;
  }
}

.TextStory-col {
  @include media-breakpoint-up(sm) {
    @include make-col(10);
  }
  @include media-breakpoint-up(md) {
    @include make-col(8);
  }
}

.TextStory-title {
  @include reveal('.TextStory-col');
  @include ms-respond(font-size, 40); // 56px vs 61 in design
  font-weight: 400;
  line-height: 1.3;
}

.TextStory-caption {
  @include reveal('.TextStory-col');
  @include reveal-from('.TextStory-col') {
    transform: translateX(-1.5rem);
  };
  @include reveal-to('.TextStory-col') {
    transform: none;
  };
  @include font-size-small();
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 0.25em;
  padding-right: 1.5em;
}

.TextStory-text {
  display: inline;
  p {
    display: inline;
  }
  p + p {
    display: inline-block;
    margin-top: .75em;
  }
}

.TextStory-trigger {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  .StoryPreview {
    margin-left: 1rem;
  }
}

.TextStory-label {
  @include text-shadow($color-background, 2px);
  @include line-bg(currentColor, 93%, 0.06em);
  display: inline-block;
}
