.StoryPreview {
  width: rem(56px);
  height: rem(56px);
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  color: #fff;
  display: inline-block;
  &:after,
  &:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &:before {
    background: rgba(#000, 0.2);
  }
  &:after {
    background-color: #fff;
    transform: scale(0.95);
    z-index: 2;
  }
  @include media-breakpoint-down(xs) {
    width: rem(48px);
    height: rem(48px);
    .Story & {
      width: rem(32px);
      height: rem(32px);
    }
  }
  .Icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    width: 16/56* 100%;
    height: 56/16 * 100%;
    z-index: 4;
  }
}

.StoryPreview-image {
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 3;
  transform: scale(0.9);
  background-color: inherit;
  display: block;
  &:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, 0.2);
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .3s;
    &.lazyloaded {
      opacity: 1;
    }
  }
}


