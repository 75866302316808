@mixin fluid-root-font-size() {
  @include poly-fluid-sizing("font-size", join-map(map-remove($root-font-size, xs),  $grid-breakpoints));
  @include media-breakpoint-down(xs) {
    font-size: map_get($root-font-size, xs);
  }
}

@mixin breakpoint-menu-up() {
  @include media-breakpoint-up(sm) {
    @content;
  }
}

@mixin breakpoint-menu-down() {
  @include media-breakpoint-down(xs) {
    @content;
  }
}

// @mixin heading() {
//   margin-bottom: $headings-margin-bottom;
//   font-weight: $headings-font-weight;
//   line-height: $headings-line-height;
//   color: currentColor;
// }

// @mixin heading-type($type) {

//   $types: (
//     a: 47.5,   // 92
//     b: 40.85,  // 72
//     c: 32,     // 52
//     d: 26.21,  // 42
//     e: 17.1,   // 30
//     f: 13.2,   // 26
//     g: 9.9,    // 23
//     h: 0     // 21
//   );

//   @if map-has-key($types, $type) {
//     letter-spacing: -0.02em;
//     @include ms-respond(font-size, map-get($types, $type));
//   }

//   @if $type == "g" {
//     font-weight: 700;
//     letter-spacing: 0;
//   }

//   @if $type == "h" {
//     font-weight: 700;
//     letter-spacing: 0;
//   }
// }

@mixin lazyloaded($reveal: true, $transition: #{'opacity 0.3s, transform 1.8s ' + cubic-bezier("easeOutQuint")}) {
  @if($transition != false) {
    transition: $transition;
  }
  @if($reveal == true) {
    .Main & {
      opacity: 0;
      transform: scale3d($reveal-scale, $reveal-scale, $reveal-scale);
    }
    .Main .is-inview &.lazyloaded {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  } @else {
    opacity: 0;
    transform: scale3d($reveal-scale, $reveal-scale, $reveal-scale);
    &.lazyloaded {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }
}

@mixin lazyloaded-wrapper() {
  transition: opacity 0.3s;
  > * {
    transition: #{'transform ' + $reveal-duration + ' ' + cubic-bezier("easeOutQuint")};
  }
  .Main & {
    opacity: 0;
    > * {
      transform: scale3d($reveal-scale, $reveal-scale, $reveal-scale);
    }

  }
  .Main &.is-inview.lazyloaded {
    opacity: 1;
    > * {
      transform: scale3d(1, 1, 1);
    }
  }
}


@mixin lazyloaded-parent-zoom($transition: #{'opacity 0.3s, transform ' + $reveal-duration + ' ' + cubic-bezier("easeOutQuint")}) {
  @if($transition != false) {
    transition: $transition;
  }
  .Main & {
    opacity: 0;
    transform: scale3d($reveal-scale, $reveal-scale, $reveal-scale);
  }
  .Main .is-inview.lazyloaded & {
    transform: scale3d(1, 1, 1);
  }
  .Main &.lazyloaded {
    opacity: 1;
  }
}

@mixin hover-zoom($transition: #{'transform .8s ' + cubic-bezier("easeInOutQuint")}) {
  @if($transition != false) {
    transition: $transition;
  }
  .desktop a:hover & {
    transition-duration: 6s;
    transform: scale(1.1) !important;
  }
}

@mixin reveal-from($parent: '', $child: '') {
  [data-scroll-container] #{$parent} & #{$child} {
    @content;
  }
}

@mixin reveal-to($parent: '', $child: '') {
  @if($parent != '') {
    [data-scroll-container] #{$parent}.is-inview & #{$child} {
      @content;
    }
  } @else {
    [data-scroll-container] &.is-inview #{$child} {
      @content;
    }
  }
}

@mixin reveal($parent: '', $child: '') {
  @include reveal-from($parent, $child) {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s cubic-bezier("easeOutQuad") 0.2s;
    transition-property: transform, opacity;
  }
  @include reveal-to($parent, $child) {
    opacity: 1;
    transform: none !important;
  }
}

@mixin hover-text($selector: #{'a:hover ' + &}, $transition: #{'opacity .3s'}) {
  @if($transition != false) {
    transition: $transition;
  }
  .desktop #{$selector} {
    opacity: $hover-text-opacity;
  }
}


// @mixin reveal-image($parent: '', $child: '') {
//   @include reveal-from($parent, $child) {
//     opacity: 0;
//     transform: translateY(1rem);
//     transition: transform 0.1s cubic-bezier("easeOutCubic") 0.8s, opacity 0.8s;
//   }
//   @include reveal-to($parent, $child) {
//     opacity: 1;
//     transform: none;
//     transition: transform 0.8s cubic-bezier("easeOutCubic"), opacity 0.8s;
//   }
// }

@mixin transition-delay-nthchild($childs: 5, $delay: 0s, $stagger: 0.1s) {
  @for $i from 1 through $childs {
    &:nth-child(n+#{$i}) {
      @include transition-delay($i, $delay, $stagger);
    }
  }
}

@mixin transition-delay($n: 1, $delay: 0s, $stagger: 0.1s) {
  $n-delay: $stagger * $n;
  transition-delay: #{$delay + $n-delay} !important;
}

@mixin content-spacer($property, $factor: 1) {
  @each $prop_single in $property {
    #{$prop_single} : 3rem * $factor;
  }
  @include res-prop($property, $grid-gutter-widths, (sm: $factor * 2.5));
}

@mixin section-spacer($property, $factor: 1, $factor-xs: 1) {
  @each $prop_single in $property {
    #{$prop_single} : 6rem * $factor-xs;
  }
  @include res-prop($property, $grid-gutter-widths, (sm: $factor * 5));
}

@mixin first-main-child() {
  .Main-container > &:first-child {
    @include res-prop("border-top-width", $site-header-heights);
    // use padding instead of margin due to a locomotive scroll bug
    @include section-spacer("padding-top", 0.5);
    margin-top: 0 !important;
    border-top-color: transparent;
    border-top-style: solid;
  }
}

@mixin section() {
  @include section-spacer(("margin-top","margin-bottom"));
  @include first-main-child();
  @include add-safe-border-bottom();
  // @if ($preventCollapse != true) {
  //   @include resetIfAdjacentBg("padding-top");
  // }
}

@mixin add-safe-border-bottom() {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: inherit;
    transform: translateY(1px);
  }
}

@mixin text-shadow($color: #000, $size: 1px) {
  text-shadow:
    -#{$size} -#{$size} 0 $color,
     0        -#{$size} 0 $color,
     #{$size} -#{$size} 0 $color,
     #{$size}  0        0 $color,
     #{$size}  #{$size} 0 $color,
     0         #{$size} 0 $color,
    -#{$size}  #{$size} 0 $color,
    -#{$size}  0        0 $color;
}

@mixin line-bg($line-color: currentColor, $position: 93%, $size: 2px) {
  background-image: linear-gradient($line-color, $line-color);
  background-position: 0% $position;
  background-repeat: no-repeat;
  background-size: 100% $size;
}

@mixin font-size-small() {
  font-size: $small-font-size;
  line-height: $line-height-sm;
}

@mixin when-scroll-smooth($parent: '') {
  .has-scroll-smooth #{$parent} & {
    @content;
  }
}

@mixin label() {
  // color: $color-text-muted;
  font-size: $input-font-size;
  text-transform: none;
  letter-spacing: 0;
}

@mixin label-heading() {
  font-size: 14/16 * 1rem;
  // @include font-size-small();
  margin: 0;
  // font-weight: 700;
}

/* overwrite bootstrap caret */

// @mixin caret-down() {
// }

@mixin caret-up() {
  transform: rotate(-180deg);
}

@mixin caret-right() {
  transform: rotate(90deg);
}

@mixin caret-left() {
  transform: rotate(-90deg);
}

@mixin caret($direction: down, $color: $color-text, $stroke-width: 1.5) {
  @if $enable-caret {
    &::after {
      flex-shrink: 0;
      display: inline-block;
      margin-left: $caret-spacing;
      position: relative;
      top: 0.1em;
      // transition: transform .3s;
      vertical-align: $caret-vertical-align;
      content: "";
      width: rem(14px);
      height: 0.8em;
      background-image: url(svg("chevron-down", "fill: none; stroke: #{$color}; stroke-width: #{$stroke-width}"));
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      @if $direction == down {
        // @include caret-down();
      } @else if $direction == up {
        @include caret-up();
      } @else if $direction == right {
        @include caret-right();
      }
    }

    @if $direction == left {
      &::after {
        display: none;
      }

      &::before {
        flex-shrink: 0;
        display: inline-block;
        margin-right: $caret-spacing;
        // vertical-align: $caret-vertical-align;
        content: "";
        width: rem(14px);
        height: 0.8em;
        background: url(svg("chevron-down", "fill: none; stroke: #{$color-text}; stroke-width: #{$stroke-width}"));
        @include caret-left();
      }
    }

    &:empty::after {
      margin-left: 0;
    }
  }
}

