.PageHeaderEventSimple {
  @include res-prop("border-top-width", $site-header-heights);
  @include section-spacer("padding-top", 0.5, 0.5);
  @include section-spacer("margin-bottom");
  border-top-color: transparent;
  border-top-style: solid;
}

.PageHeaderEventSimple-container {

}

.PageHeaderEventSimple-textCol {
  @include reveal('', '> *');
  @include reveal-from('', '> *') {
    transform: translateX(2rem);
  }
  @include media-breakpoint-up(sm) {
    @include make-col(10);
  }
  > * {
    @include transition-delay-nthchild(4, 0s, 0.08s);
  }
}

.PageHeaderEventSimple-mediaCol {
  @include reveal();
  @include media-breakpoint-up(sm) {
    margin-left: auto;
    @include make-col(10);
  }
  @include media-breakpoint-up(md) {
    @include make-col(8);
  }
}

.PageHeaderEventSimple-title {
  @include ms-respond(font-size, 43); //62px
  font-family: $font-family-serif;
  font-weight: 900;
  margin: 0;
}

.PageHeaderEventSimple-subtitle {
  @include ms-respond(font-size, 32); // 44px
  // text-transform: uppercase;
  font-weight: 300;
  margin: 0;
}

.PageHeaderEventSimple-canceled {
  margin: 1rem 0 0;
}

.PageHeaderEventSimple-date {
  margin: 3rem 0 0;
}

.PageHeaderEventSimple-figure {
  margin-top: 1rem;
  background-color: $color-placeholder;
  overflow: hidden;
  position: relative;
}

.PageHeaderEventSimple-image {
  @include lazyloaded();
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center/cover no-repeat;
}
