.CardList-container {
  > .row {
    justify-content: center;
  }
}

.CardList-nav {
  @include reveal();
  @include ms-respond(font-size, 12);
  margin-bottom: 3rem;
  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
  }
  li {
    &.is-active {
      position: relative;
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 0.7em;
        height: 2px;
        background-color: currentColor;
        left: 0;
      }
      &:before {
        top: 0.1em;
      }
      &:after {
        bottom: 0em;
      }
    }
  }
  a:not(.dropdown-item) {
    text-decoration: none;
    opacity: 0.5;
  }
}

.CardList-select {
  display: inline-block;
  width: auto !important;
  vertical-align: baseline;
  &:not(.bootstrap-select) {
    display: none;
  }
  .dropdown-menu {
    margin-left: -1.5em;
    max-height: 5.6em * 1.3;
    overflow-y: auto;
  }
  .dropdown-item {
    // color: $color-text-muted !important;
    padding: 0.5em 1.5em;
    display: flex;
    line-height: 1.3;
    &.active {
      // color: $color-text !important;
    }
  }
}

.CardList-sectionTitle {
  @include reveal();
  @include ms-respond(font-size, 15);
  @include section-spacer("margin-top");
  margin-bottom: 2rem;
  &:nth-child(1) {
    margin-top: 0;
  }
}

.CardList-list {
  @include media-breakpoint-up(md) {
    @include make-col(10);
  }
  > .row {
    justify-content: space-between;
  }
}

.CardList-item {
  @include reveal();
  @include section-spacer("margin-top");
  display: flex;
  @include media-breakpoint-up(sm) {
    @include make-col(6);
    &:nth-child(2) {
      margin-top: 0;
    }
    &:nth-child(even) {
      @include transition-delay(1, 0, 0.35s);
    }
  }
  @include media-breakpoint-up(md) {
    &:nth-child(even) {
      margin-right: map_get($grid-gutter-widths, sm) * -1;
    }
    &:nth-child(odd) {
      margin-left: map_get($grid-gutter-widths, sm) * -1;
    }
  }
  &:nth-child(1) {
    margin-top: 0;
  }
}
