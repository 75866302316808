.text-uppercase {
  text-transform: uppercase;
}

p.Lead {
  @include ms-respond(font-size, 15.5); // 26px
  font-weight: 700;
  font-family: $font-family-serif;
  line-height: $headings-line-height * 1.1;
}

// h1, h2, h3, h4, h5, h6,
// .h1, .h2, .h3, .h4, .h5, .h6,
// .display-1, .display-2 {
//   @include heading();
// }

// .display-1 {
//   @include heading-type("a");
// }

// .display-2 {
//   @include heading-type("b");
// }

// h1,
// .h1 {
//   @include heading-type("c");
// }

// h2,
// .h2 {
//   @include heading-type("d");
// }

// h3,
// .h3 {
//   @include heading-type("e");
// }

// h4,
// .h4 {
//   @include heading-type("f");
// }

// h5,
// .h5 {
//   @include heading-type("g");
// }

// h6,
// .h6 {
//   @include heading-type("h");
// }

// // hr {
// //   margin-top: $hr-margin-y;
// //   margin-bottom: $hr-margin-y;
// //   border: 0;
// //   border-top: $hr-border-width solid rgba($color-text, .2);
// //   @include if-dark-background {
// //     border-top: $hr-border-width solid rgba($color-text-inverted, .3);
// //   }
// // }

// .text-small,
// small {
//   @include font-size-small();
// }

// .text-muted {
//   color: $color-text-muted;
// }

// // .badge {
// //   position: relative;
// //   z-index: 1;
// //   display: inline-block;
// //   a {
// //     line-height: 1;
// //     text-transform: uppercase;
// //     letter-spacing: 0.02em;
// //     display: inline-block;
// //     padding: .75rem 1rem;
// //     font-weight: 500;
// //     font-size: calc(1rem - 2px);
// //   }
// //   a:hover {
// //     text-decoration: none;
// //   }
// //   &:before,
// //   &:after {
// //     content: '';
// //     position: absolute;
// //     top: 0;
// //     left: 0;
// //     right: 0;
// //     bottom: 0;
// //     opacity: .3;
// //     border-radius: 50px;
// //     z-index: -2;
// //     transition: opacity .2s;
// //   }
// //   &:before {
// //     background-color: currentColor;
// //   }
// //   &:after {
// //     z-index: -1;
// //     background-color: #000;
// //     opacity: 0;
// //     .inverted-header & {
// //       background-color: #fff;
// //     }
// //   }
// //   .desktop &:hover:after {
// //     opacity: .15;
// //   }
// // }

// // .badge--primary {
// //   color: color("white") !important;
// //   &:before {
// //     background-color: color("orange-2");
// //     opacity: 1;
// //   }
// // }

// // %reset-badge {
// //   a {
// //     padding: 0;
// //     font-size: unset;
// //     line-height: unset;
// //     text-transform: unset;
// //     letter-spacing: unset;
// //     text-decoration: unset;
// //     font-weight: inherit;
// //   }
// //   &:before,
// //   &:after {
// //     display: none;
// //   }
// // }
