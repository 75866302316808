.js-formBuilder {
  margin-bottom: $form-group-margin-bottom * 2;
}

.FormBuilder {

  .Inputfield_referral {
    // custom field used in 028
    display: none !important;
  }
  // reset because .Prose is overwriting these values
  // font-family: $font-family-sans-serif;
  // font-family: $font-family-base;
  // line-height: $line-height-base;

  // custom style
  button:not(.dropdown-toggle) {
    @extend .Button;
    @extend .Button--primary;
    @extend .Button--right;
  }

  input[type="text"],
  input[type="email"],
  input[type="file"],
  textarea,
  select {
    @extend .form-control;
    box-shadow: none  !important;
  }

  textarea {
    height: auto;
  }

  input[type="file"] {
    opacity: 0;
  }

  .InputfieldHidden {
    display: none;
  }

  .InputfieldStateError {
    input[type="text"],
    input[type="email"],
    textarea,
    select,
    .dropdown-toggle {
      $color: $color-error;
      border-color: $color;
      &:focus {
        border-color: $color;
        box-shadow: 0 0 0 $input-btn-focus-width rgba($color, .3);
      }
    }
    .InputfieldContent label {
      color: $color-error;
    }
  }

  input[type="file"] {
    @extend .form-control-file;
  }

  .input-error {
    @extend .form-text;
    @include font-size-small();
    font-weight: 700;
    color: $color-error;
  }

  .notes {
    @include font-size-small();
    @extend .form-text;
    color: $text-muted;
    margin-bottom: 0;
  }

  .Inputfields {
    display: flex;
    flex-wrap: wrap;
    @include grid-spacer("m", "x", -0.5);
    > *:last-child {
      margin-bottom: 0;
    }
  }

  .Inputfield,
  fieldset {
    @extend .form-group;
    @include grid-spacer("p", "x", 0.5);
    @include make-col-ready();
    @include media-breakpoint-down(xs) {
      width: 100% !important;
    }
  }

  fieldset {
    margin-bottom: 1rem !important;
  }

  .InputfieldSelect {
    .dropdown-toggle:after {
      top: -0.3rem;
    }
  }

  .InputfieldStateRequired {
    label.InputfieldHeader,
    label .pw-no-select {
      &:after {
        content: ' *';
        @include label();
        color: inherit;
      }
    }
  }

  .InputfieldCheckbox {
    .InputfieldHeader {
      display: none;
    }
  }

  .InputfieldCheckbox,
  .InputfieldCheckboxes,
  .InputfieldRadios {
    .InputfieldContent {
      label {
        @extend .form-check-label;
        &:not(.custom-control-label) {
          @extend .form-check;
        }
      }
      input {
        @extend .custom-control-input;
      }
    }
  }

  .InputfieldRadios {
    .InputfieldContent {
      input {
        // margin-top: 0.25em;
      }
    }
  }

  .InputfieldCheckbox,
  .InputfieldCheckboxes {
    .InputfieldContent {
      input {
        // margin-top: 0.3em;
      }
    }
  }

  .Inputfield_file {
    li + li {
      margin-top: $form-group-margin-bottom;
    }
  }

  .InputfieldContent {
    position: relative;
    .description {
      display: none;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  .InputfieldHeader {
    z-index: 2;
    position: absolute;
    display: block;
    transform: translate($input-padding-x, 0.8rem);
    transition: transform 0.1s;
    transform-origin: 0 0;
    // margin: 0;
    pointer-events: none;
  }

  .Inputfield_radio_buttons,
  .InputfieldCheckboxes {
    .InputfieldHeader {
      position: static;
      transform: none;
      pointer-events: auto;
    }
  }

  .Inputfield {
    &.Inputfield_text_with_placeholder,
    &.has-focus,
    &.has-value {
      .InputfieldHeader {
        transform: translate($input-padding-x, 0.35rem) scale(0.75);
      }
    }
  }

  .InputfieldSubmit {
    margin: $form-group-margin-bottom 0 $form-group-margin-bottom * 2;
    display: flex;
    justify-content: center;
  }
}
