.Link {
  display: inline-flex;
  align-items: center;
  color: currentColor !important;
  font-weight: 500;
  &:after {
    content: '';
    width: 1rem;
    height: 1rem;
    background-color: transparent;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition: transform 0.3s;
    background-image: url(svg("arrow", "fill: none; stroke: #{$color-text}; stroke-width: #{$icon-stroke-width}"));
    [data-text-inverted] & {
      background-image: url(svg("arrow", "fill: none; stroke: #{$color-text-inverted}; stroke-width: #{$icon-stroke-width}"));
    }
  }
}

.Link--next {
  &:after {
    margin-left: 0.5rem;
  }
  .desktop &:hover:after {
    transform: translateX(4px);
  }
}

.Link--prev {
  &:after {
    order: -1;
    margin-right: 0.5rem;
    transform: rotate(180deg);
  }
  .desktop &:hover:after {
    transform: rotate(180deg) translateX(4px);
  }
}
