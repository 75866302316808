:root {
  --vh: 1vh;
}

html {
  @include font-smoothing();
  @include fluid-root-font-size();
  -webkit-tap-highlight-color: rgba($color-text, 0.2);
  // padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);

  &.page-transitioning-out {
    overflow: hidden;
  }

  &.page-transitioning {
    * {
      pointer-events: none;
      cursor: progress !important;
    }
  }

  &.has-scroll-smooth {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    [data-scroll-speed] {
      will-change: transform;
    }
  }
}

body {
  overscroll-behavior-y: none;
  html:not(.is-first-run) & {
    opacity: 0;
  }
}

figure {
  margin: 0;
}

img {
  width: 100%;
  max-width: 100%;
}

a {
  transition: color 0.3s;
}

a[href^="tel:"] {
  text-decoration: none !important;
}

legend {
  margin-bottom: 1.5rem;
  @include ms-respond(font-size, 15.5); // 26px
  line-height: $headings-line-height * 1.1;
}

label {
  font-size: 14/16 * 1rem;
  &:not(.custom-control-label) {
    font-weight: 700;
  }
  a {
    font-weight: 500;
    text-decoration: underline;
    color: currentColor;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: $headings-line-height;
  sup {
    font-family: $font-family-sans-serif;
    font-weight: 300;
    font-size: 65%;
    transform: translateY(-20%);
  }
}

hr {
  margin: 4rem 0 0.5rem;
  border: 0;
  border-top: 4px solid currentColor;

  @include media-breakpoint-up(sm) {
    margin: 4rem 0 1rem;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// label {
//   &.custom-file-label,
//   &.form-check-label,
//   .InputfieldRadiosStacked &,
//   .InputfieldCheckbox &:not(.InputfieldHeader) {
//     @include label();
//   }
// }
