.TextCols-row {
  @include media-breakpoint-up(sm) {
    justify-content: flex-end;
  }
}

.TextCols-col {
  margin-top: 2rem;
  @include media-breakpoint-up(sm) {
    @include make-col(10);
  }
  @include media-breakpoint-up(md) {
    @include make-col(8);
    &:nth-child(2) {
      margin-top: 0 !important;
    }
    &:nth-child(odd):not(:only-child):not(:last-child) {
      @include make-col(4);
      margin-right: auto;
      .TextCols-prose {
        // remove Prose--indent
        > * {
          margin-right: 0;
        }
      }
    }
  }
  &:nth-child(1) {
    margin-top: 0 !important;
  }
}

.TextCols-prose {
  @include reveal();
}
