.PeopleList {
  &[style] {
    @include section-spacer("padding-top");
    @include section-spacer("padding-bottom");
  }
  &[data-text-inverted] {
    color: $color-text-inverted;
  }
}

.PeopleList-headingCol {
  @include reveal();
  margin-bottom: 3rem;
  margin-left: auto;
  @include media-breakpoint-up(sm) {
    @include make-col(10);
  }
  @include media-breakpoint-up(md) {
    @include make-col(4);
  }
}

.PeopleList-listCol {
  margin-left: auto;
  @include media-breakpoint-up(sm) {
    @include make-col(10);
  }
  @include media-breakpoint-up(md) {
    @include make-col(8);
  }
}

.PeopleList-list {
  @include reveal('.PeopleList-listCol');
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 4px solid currentColor;
}

.PeopleList-item {
  @include reveal('.PeopleList-listCol');
  @include transition-delay-nthchild(10);
  padding: 2rem 0 1.6rem;
  border-bottom: 1px solid currentColor;
  @include media-breakpoint-up(sm) {
    padding: 2rem 0;
  }
}

.PeopleList-article {
  @include make-row();
}

.PeopleList-media,
.PeopleList-content,
.PeopleList-body {
  @include make-col-ready();
}

.PeopleList-media {
  @include make-col(4);
  @include media-breakpoint-up(sm) {
    @include make-col(2, 10);
  }
  @include media-breakpoint-up(md) {
    @include make-col(2, 8);
  }
}

.PeopleList-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include make-col(8);
  @include media-breakpoint-up(sm) {
    @include make-col(8, 10);
  }
  @include media-breakpoint-up(md) {
    @include make-col(6, 8);
  }
}

.PeopleList-copyright {
  padding-top: 1rem;
  margin-top: auto;
  p {
    margin: 0;
  }
}

.PeopleList-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: 1rem;
  margin-top: auto;
  font-weight: 500;
  line-height: 1;
  .desktop &:hover {
    text-decoration: underline;
  }
  .Icon {
    margin-left: 0.25rem;
    transform: translateY(2px);
  }
  svg {
    transition: transform 0.4s;
    transform: rotate(1deg);
  }
  &.is-open svg {
    transform: rotate(135deg);
  }
}

.PeopleList-title {
  @include ms-respond(font-size, 27.5); // 38px vs 40 in design
  font-weight: 900;
  font-family: $font-family-serif;
  margin-bottom: 0.3rem;
  a {
    color: currentColor;
    text-decoration: none !important;
    transition: opacity $hover-text-duration;
  }
  a:hover {
    opacity: $hover-text-opacity;
  }
}

.PeopleList-subtitle {
  @include ms-respond(font-size, 10.5); // 22px
  text-transform: uppercase;
  line-height: $headings-line-height;
  margin: 0;
}

.PeopleList-figure {
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  background-color: $color-placeholder;
}

.PeopleList-image {
  @include lazyloaded();
  background: center/cover no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.PeopleList-body {
  margin-left: auto;
  @include media-breakpoint-up(sm) {
    @include make-col(8, 10);
  }
  @include media-breakpoint-up(md) {
    @include make-col(6, 8);
  }
  .Prose {
    padding-top: 1rem;
  }
}
