.custom-control-label {
  &:before,
  &:after {
    top: .2rem;
  }
}

.custom-control-input:checked {
  ~ .custom-control-label:after {
    transform: scale(0.5);
    background: #000 !important;
  }
}

.custom-radio {
  .custom-control-label:after {
    border-radius: 50%;
  }
}

.custom-file-input {
  cursor: pointer;
}

.custom-file,
.custom-file-label {
  height: add($input-line-height * 1em, add(add($input-padding-y * 2, $input-label-required-space, false), $input-height-border, false));
}

.custom-file-label {
  padding-top: $input-label-required-space + $input-padding-y;
  font-size: 14/16 * 1rem;
  font-weight: 400 !important;
  &:after {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.custom-file-input:focus + .custom-file-label {
  border-bottom: 1px solid color("text");
}
