.PageHeaderEventSplit {
  background-color: $color-placeholder;
  @include media-breakpoint-up(md) {
    display: flex;
    min-height: calc(var(--vh) * 100);
  }
  &[data-text-inverted] {
    color: $color-text-inverted;
  }
}
.PageHeaderEventSplit-content {
  @include res-prop("border-top-width", $site-header-heights);
  @include section-spacer("padding-top", 0.5, 0.5);
  @include res-prop("padding-bottom", $grid-container-paddings, (xs: 1, sm: 0.5));
  border-top-color: transparent;
  border-top-style: solid;
  @include media-breakpoint-up(md) {
    flex: 0 0 50%;
    display: flex;
  }
}

.PageHeaderEventSplit-container {
  @include reveal(' ');
  @include media-breakpoint-up(md) {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    > .row {
      justify-content: center;
      flex-grow: 1;
    }
  }
}

.PageHeaderEventSplit-col {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(lg) {
    @include make-col(10);
  }
}


.PageHeaderEventSplit-category {
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
}

.PageHeaderEventSplit-title {
  @include ms-respond(font-size, 38); // 54px
  font-family: $font-family-serif;
  font-weight: 900;
  margin: 0;
}

.PageHeaderEventSplit-subtitle {
  @include ms-respond(font-size, 32); // 44px
  // text-transform: uppercase;
  font-weight: 300;
  margin: 0;
}

.PageHeaderEventSplit-canceled {
  margin: 1rem 0;
}

.PageHeaderEventSplit-date {
  margin-top: auto;
  padding-top: 4rem;
}

.PageHeaderEventSplit-media {
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  @include media-breakpoint-up(md) {
    padding: 0;
    flex: 0 0 50%;
  }
}

.PageHeaderEventSplit-figure,
.PageHeaderEventSplit-image,
.PageHeaderEventSplit-figureWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.PageHeaderEventSplit-image {
  @include lazyloaded();
  background: center/cover no-repeat;
}
