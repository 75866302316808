.Signature {
  display: flex;
  align-items: center;
  // font-size: calc(1rem + 2px);
  margin-top: 1rem;
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 2rem;
    height: 3px;
    transform: translateY(1px);
    background-color: currentColor;
    margin-right: 0.5em;
  }
}
