.VideoList-container {
  > .row {
    justify-content: center;
  }
}

.VideoList-sectionTitle {
  @include reveal();
  @include ms-respond(font-size, 15);
  @include content-spacer("margin-bottom", 1);
  &:nth-child(1) {
    margin-top: 0;
  }
}

.VideoList-list {
  @include media-breakpoint-up(md) {
    @include make-col(10);
  }
  > .row {
    justify-content: space-between;
  }
}

.VideoList-item {
  @include reveal();
  @include content-spacer("margin-top", 1);
  @include media-breakpoint-up(sm) {
    @include make-col(6);
    &:nth-child(2) {
      margin-top: 0;
    }
    &:nth-child(even) {
      @include transition-delay(1, 0, 0.35s);
    }
  }
  @include media-breakpoint-up(md) {
    &:nth-child(even) {
      margin-right: map_get($grid-gutter-widths, sm) * -1;
    }
    &:nth-child(odd) {
      margin-left: map_get($grid-gutter-widths, sm) * -1;
    }
  }
  &:nth-child(1) {
    margin-top: 0;
  }
}
