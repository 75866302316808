.PageHeaderText {
  @include res-prop("border-top-width", $site-header-heights);
  @include section-spacer("padding-top", 0.5, 0.5);
  @include section-spacer("margin-bottom");
  border-top-color: transparent;
  border-top-style: solid;
}

.PageHeaderText-container {
  // @include reveal();
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include reveal('', '> *');
  @include reveal-from('', '> *') {
    transform: translateX(2rem);
  }

}

.PageHeaderText-subtitle {
  @include ms-respond(font-size, 32); // 44px vs 50 in design
  transition-delay: 0.45s !important;
  font-weight: 300;
  margin: 0;
  order: 3;
}

.PageHeaderText-title {
  @include ms-respond(font-size, 52); //82px
  transition-delay: 0.25s !important;
  width: 100%;
  font-family: $font-family-serif;
  font-weight: 900;
  margin: 0;
  .PageHeaderText-subtitle + & {
    @include ms-respond(font-size, 43); //62px
  }
}
