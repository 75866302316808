.Image {
  &.Image--full {
    margin: 0;
  }
  &:not(.Image--full) [data-scroll-speed] {
    transform: none !important;
  }
}

.Image-container {
  @include reveal();
  .Image--full & {
    padding: 0;
  }
}

.Image-col {
  .Image--right & {
    @include media-breakpoint-up(sm) {
      @include make-col(10);
      margin-left: auto;
    }
  }
}

.Image-figure {
  background-color: $color-placeholder;
  position: relative;
  overflow: hidden;
  .Image--full & {
    max-height: calc(var(--vh) * 80);
    height: 50vmax;
  }
}

.Image-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.Image-image {
  @include lazyloaded();
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center/cover no-repeat;
  @include when-scroll-smooth(".Image--full") {
    top: calc(var(--vh) * -10);
    bottom: calc(var(--vh) * -10);
  }
}

.Image-placeholder {
  .Image--full & {
    display: none;
  }
}
