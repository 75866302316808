.fancybox-bg {
  background: $color-text;
  .fancybox-is-open & {
    opacity: 1;
  }
}

.fancybox-slide--image {
  @include res-prop("padding", $grid-container-paddings, 2);
}

.fancybox-caption {
  @include res-prop("padding", $grid-container-paddings);
  padding-bottom: 1rem !important;
  font-size: 1rem;
}

.fancybox-button {
  width: 2rem;
  height: 2rem;
  padding: 0;
  font-size: 0;
  color: $color-text-inverted;
  background-color: transparent;
  .Icon {
    $scale: 1.5;
    width: 1rem * $scale;
    height: 1rem * $scale;
    stroke-width: $icon-stroke-width * 1 / $scale;
  }
}

.fancybox-button--close {
  @include res-prop("right", $grid-container-paddings, (xs: 0.5, sm: 1));
  @include res-prop("top", $grid-container-paddings, 0.5);
  position: absolute;
}

.fancybox-navigation {
  display: none;
  .desktop & {
    display: block;
  }
}

.fancybox-navigation .fancybox-button--arrow_left {
  @include res-prop("left", $grid-container-paddings);
  svg {
    transform: rotate(180deg);
  }
}

.fancybox-navigation .fancybox-button--arrow_right {
  @include res-prop("right", $grid-container-paddings);
}

.fancybox-button[disabled] {
  opacity: 0 !important;
}
