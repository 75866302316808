$calendar-colors: (
  "intensive" : #d6a288,
  "mw-detail" : tint(color('4755c'), 40%),
  "residence" : #c3d5cb,
  "teacher-training" : #ffc380,
  "event" : #bdd0db
);

.Calendar {
  --fc-small-font-size: calc(1rem - 2px);
  --fc-event-text-color: #000; // evento block
  --fc-event-bg-color: #ddd;
  --fc-button-border-color: #000;
  --fc-button-bg-color: #000;
  --fc-button-hover-bg-color: #333;
  --fc-button-hover-border-color: #333;

  @each $type in map-keys($calendar-colors) {
    #{"--calendar-" + $type}: map-get($calendar-colors, $type);
  }

  > .container {
    @include reveal();
  }

  // --calendar-intensive: color('4755c');
  // --calendar-masterclass: tint(color('4755c'), 40%);
  // --calendar-residence: color('621c');
  // --calendar-gravity: color('7514c');
  // --calendar-events: color('5655c');

}

.Calendar-aside {
  @include media-breakpoint-up(sm) {
    @include make-col(3);
  }
  @include media-breakpoint-down(xs) {
    order: 2;
    margin-top: 2rem;
  }
}

.Calendar-main {
  @include media-breakpoint-up(sm) {
    @include make-col(9);
  }
}

.Calendar-legendList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Calendar-legend {
  display: flex;
  align-items: center;
  &:before {
    content: '';
    width: 0.75em;
    height: 0.75em;
    border-radius: 50%;
    background: #000;
    margin-right: 0.5em;
  }
  @each $type in map-keys($calendar-colors) {
    &#{".--" + $type}:before {
      background: var(#{"--calendar-" + $type});
    }
  }
}

.fc-toolbar-title:first-letter {
  text-transform: uppercase;
}
.fc-daygrid-day-number {
  @include font-size-small();
}

.fc-daygrid-event {
  border-radius: 0;
  border: 0;
}

.fc-daygrid-block-event {
  .fc-event-time {
    font-weight: 400;
  }
  .fc-event-title {
    font-weight: 700;
    &:first-child {
      padding-left: 0.2em;
    }
  }
}

.fc .fc-daygrid-day.fc-day-today {
  background: color('gray-95');
}

.fc-list-event-dot {
  @each $type in map-keys($calendar-colors) {
    #{".tpl-" + $type} & {
      border-color: var(#{"--calendar-" + $type});
    }
  }
}

.fc-daygrid-dot-event:hover {
  background: none;
}

.fc-h-event {
  padding: 2px 0;
  @each $type in map-keys($calendar-colors) {
    &#{".tpl-" + $type} {
      background-color: var(#{"--calendar-" + $type});
    }
  }
}

.fc .fc-toolbar-title {
  @include ms-respond(font-size, 22);
}

.fc .fc-today-button {
  @include ms-respond(font-size, 12);
  text-transform: lowercase;
  color: $color-text !important;
  background: none !important;
  border: 0 !important;
  padding: 0;
  border-radius: 0;
  box-shadow: none !important;
}

.fc .fc-button {
  border-radius: 0;
}

.fc-toolbar-chunk {
  // display: flex;
  // align-items: center;
}

.fc .fc-button-group {
  // display: flex;
  // align-items: center;
}

.fc .fc-button.fc-prev-button,
.fc .fc-button.fc-next-button {
  padding: 0;
  font-size: 0;
  width: 2.5rem;
  height: 2.5rem;
  box-shadow: none !important;
  // background: none;
  // padding: 0;
  // border: 0;
  // display: flex;
  // align-items: center;
}

.fc .fc-button .fc-icon-chevron-right,
.fc .fc-button .fc-icon-chevron-left {
  width: 1rem;
  height: 1rem;
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    // background-color: red;
    background-color: transparent;
    background-image: url(svg("arrow", "fill: none; stroke: #{$color-text-inverted}; stroke-width: 1.3px"));
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  // &:before {
  //   content: '';
  //   width: 100%;
  //   height: 100%;
  //   display: block;
  //   transition: filter 0.1s linear 0.1s;
  // }
}

.fc .fc-button .fc-icon-chevron-left:before {
  transform: rotate(180deg);
}

a.fc-col-header-cell-cushion,
a.fc-daygrid-day-number {
  text-decoration: none !important;
}
