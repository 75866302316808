.ActionBar {
  border-top: 1px solid #000;
  background-color: #fff;
  z-index: 10;
  transition: 0.3s transform;
  [data-scroll-container] & {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);
    &.is-active {
      transform: translateY(0);
    }
  }
  .show-menu & {
    transform: translateY(100%) !important;
  }
}

.ActionBar-wrapper {
  padding: .75rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ActionBar-content {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  flex-grow: 0;
}

.ActionBar-title {
  font-weight: 700;
  margin-right: 1rem;
}

.ActionBar-subtitle {
  margin-right: 2rem;
}

.ActionBar-button.Button {
  white-space: nowrap;
  @include media-breakpoint-down(xs) {
    min-width: 100px;
    justify-content: center;
    &::after {
      display: none;
    }
  }
}

