.PageList-headingCol {
  @include reveal();
  margin-bottom: 3rem;
  @include media-breakpoint-up(sm) {
    @include make-col(10);
    margin-left: auto;
  }
  @include media-breakpoint-up(md) {
    @include make-col(4);
  }
}

.PageList-listCol {
  @include reveal();
  @include media-breakpoint-up(sm) {
    @include make-col(10);
    margin-left: auto;
  }
  @include media-breakpoint-up(md) {
    @include make-col(8);
  }
}

.PageList-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 4px solid $black;
}

.PageList-item {
  padding: 2rem 0 1.6rem;
  border-bottom: 1px solid $black;
  @include media-breakpoint-up(sm) {
    padding: 2rem 0;
  }
}

.PageList-article {
  @include make-row();
}

.PageList-link {
  text-decoration: none !important;
}

.PageList-media,
.PageList-content {
  @include make-col-ready();
  @include make-col(12);
}

.PageList-media {
  @include media-breakpoint-down(xs) {
    margin-bottom: 1rem;
  }
  @include media-breakpoint-up(sm) {
    order: 1;
    @include make-col(4,10);
  }
  @include media-breakpoint-up(md) {
    @include make-col(3,8);
  }
}

.PageList-content {
  @include media-breakpoint-up(sm) {
    @include make-col(6,10);
  }
  @include media-breakpoint-up(md) {
    @include make-col(5,8);
  }
}

.PageList-figure {
  padding-bottom: 3/4 * 100%;
  position: relative;
  overflow: hidden;
  background-color: $color-placeholder;
}

.PageList-image {
  @include lazyloaded();
  background: center/cover no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.PageList-title {
  @include ms-respond(font-size, 27.5);
  font-family: $font-family-serif;
  margin-bottom: 0.3rem;
  transform: opacity $hover-text-duration;
  .desktop a:hover & {
    opacity: $hover-text-opacity;
  }
}

.PageList-subtitle {
  margin: 0;
  text-transform: uppercase;
  text-decoration: none;
  em,
  strong {
    font-weight: inherit;
    font-style: normal;
    color: $color-error;
  }
}
