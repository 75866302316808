.TextHighlight {
  text-align: center;
}

.TextHighlight-row {
  @include media-breakpoint-up(sm) {
    justify-content: center;
  }
}

.TextHighlight-col {
  @include media-breakpoint-up(sm) {
    @include make-col(10);
  }
  @include media-breakpoint-up(md) {
    @include make-col(8);
  }
}

.TextHighlight-text {
  @include reveal('.TextHighlight-col');
  @include ms-respond(font-size, 39.2); // 52px
  font-family: $font-family-serif;
  margin: 0;
  font-weight: 700;
  line-height: 1.2;
  .TextHighlight--bigText & {
    @include ms-respond(font-size, 49.99); // 72px
  }
}


.TextHighlight-caption {
  @include reveal('.TextHighlight-col');
  transition-delay: 0.3s !important;
  font-size: calc(1rem + 2px);
  justify-content: center;
}
