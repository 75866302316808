.ScrollHint {
  @include res-prop("left", $grid-container-paddings);
  @include res-prop("bottom", $grid-container-paddings, 0.5);
  @include media-breakpoint-down(xs) {
    display: none;
  }
  position: absolute;
  width: 2rem;
  height: 2rem;
  border: 2px solid rgba($color-text-inverted, 0.2);
  border-radius: 50%;
  overflow: hidden;
  transform: rotate(90deg);
  transition: opacity 0.3s;
  pointer-events: none;
  z-index: 10;
  &:after,
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.8;
    top: 0;
    left: 0;
    background-image: url(svg("arrow", "fill: none; stroke: #{$color-text-inverted}; stroke-width: 1.3px"));
    background-size: 1rem 1rem;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    animation: scroll-hint;
    animation: 3s cubic-bezier("easeInOutQuart") 3s infinite scroll-hint;
  }
  &:after {
    left: -100%;
  }
  .has-scrolled & {
    opacity: 0;
  }
}

@keyframes scroll-hint {
  0% {
    transform: translateX(0);
  }
  33% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}
