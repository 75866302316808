.PageHeaderHero {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  &[data-text-inverted] {
    color: $color-text-inverted;
  }

  h1 {
    @include ms-respond(font-size, 60);
    font-family: $font-family-serif;
    font-weight: 900;
    margin: 0;
  }
  p {
    font-size: calc(1rem - 2px);
    font-weight: 400;
    margin: 0;
    padding-top: 1em;
  }

  [aria-hidden="true"] {
    div {
      display: flex;
      align-items: center;
      flex-direction: column;
      @include media-breakpoint-down(xs) {
        &:first-child {
          h1 {
            order: 1;
          }
        }
        &:nth-child(odd) {
          h1 {
            align-self: flex-start;
          }
          p {
            align-self: flex-end;
            text-align: right;
          }
        }
        &:nth-child(even) {
          h1 {
            align-self: flex-start;
            padding-left: 9%;
          }
          p {
            align-self: flex-start;
          }
        }
      }
      @include media-breakpoint-up(sm) {
        @include reveal();
        flex-direction: row;
        flex-wrap: wrap;
        &:nth-child(odd) {
          @include reveal-from() {
            transform: translateX(-2rem);
          }
          h1 {
            margin-right: 1.5rem;
          }
        }
        &:nth-child(even) {
          @include reveal-from() {
            transition-delay: 0.25s;
            transform: translateX(2rem);
          }
          @include make-col(11);
          @include make-col-offset(1);
          h1 {
            order: 2;
          }
          p {
            margin-right: 1.5rem;
          }
        }
      }
      @include media-breakpoint-up(md) {
        &:nth-child(even) {
          @include make-col(9,10);
          @include make-col-offset(1, 10);
        }
      }
    }
  }
}

.PageHeaderHero:not(.PageHeaderHero--has-media) .ScrollHint {
  display: none;
}

.PageHeaderHero-media,
.PageHeaderHero-mediaWrapper,
.PageHeaderHero-mediaWrapper:after,
.PageHeaderHero-image,
.PageHeaderHero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.PageHeaderHero-media {
  background-color: $color-placeholder;
  pointer-events: none;
  display: none;
  .PageHeaderHero--has-media & {
    display: block;
  }
}

.PageHeaderHero-mediaWrapper {
  @include lazyloaded-wrapper();
  &:after {
    content: '';
    background-color: #000;
    opacity: 0.2;
  }
}

.PageHeaderHero-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.PageHeaderHero-video {
  overflow: hidden;
  &:not(.lazyloaded) {
    opacity: 0;
  }
  video {
    position: absolute;
  }
}

.PageHeaderHero-wrapper {
  @include res-prop("border-top-width", $site-header-heights);
  @include section-spacer(("padding-top","padding-bottom"), 0.5);
  border-top-color: transparent;
  border-top-style: solid;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .PageHeaderHero--has-media & {
    min-height: calc(var(--vh) * 100);
    @include res-prop("border-bottom-width", $site-header-heights);
    border-bottom-color: transparent;
    border-bottom-style: solid;
  }
  @include media-breakpoint-down(xs) {
    justify-content: flex-end;
    padding-bottom: 2rem;
  }
}

.PageHeaderHero-container {
  @include make-container();
}

.PageHeaderHero-title {
  // @include reveal('.PageHeaderHero-container');
  margin: 0;
  max-width: 10em;
}




.PageHeaderHero-col {
  > h1,
  > p {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }
  @include media-breakpoint-up(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }
}
