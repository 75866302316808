.FeaturedList-item {
  @include reveal();
  @include section-spacer("margin-bottom");
  justify-content: space-between;

  &:last-child() {
    margin-bottom: 0;
  }


  @include media-breakpoint-up(sm) {
    &:nth-child(odd) {
      flex-direction: row-reverse;
    }
    &:nth-child(even) {
      justify-content: flex-start;
    }
  }

  .FeaturedList--reverse & {
    flex-direction: row-reverse;
    @include media-breakpoint-up(sm) {
      &:nth-child(odd) {
        flex-direction: row;
        justify-content: flex-start;
      }
      &:nth-child(even) {
        flex-direction: row-reverse;
        justify-content: space-between;
      }
    }
  }
}

.FeaturedList-contentCol {
  @include make-col-ready();
  @include make-col(12);
  margin-top: 1rem;

  @include media-breakpoint-up(sm) {
    margin-top: 0;
    @include make-col(5);
  }
  @include media-breakpoint-up(md) {
    @include make-col(4);
  }

  // .FeaturedList-item:nth-child(odd) & {
  //   @include media-breakpoint-up(sm) {
  //     order: -1;
  //   }
  // }

  // .FeaturedList-item:nth-child(even) & {
  //   @include media-breakpoint-up(md) {
  //     .FeaturedList-text {
  //       margin-left: 21%;
  //     }
  //   }
  // }
}

.FeaturedList-mediaCol {
  @include make-col-ready();
  @include make-col(12);

  @include media-breakpoint-up(sm) {
    @include make-col(7);
  }
}

.FeaturedList-content {
}

.FeaturedList-title {
  @include ms-respond(font-size, 45);
  font-weight: 900;
  font-family: $font-family-serif;
  text-align: left;
  margin-bottom: 1.5rem;
  @include media-breakpoint-down(xs) {
    margin-bottom: 1rem;
    br {
      content: ' ';
    }
    br:after {
      content: ' ';
    }
  }
}

.FeaturedList-text,
.FeaturedList-link {
  @include media-breakpoint-up(sm) {
    margin-left: 21%;
  }
  @include media-breakpoint-up(md) {
    margin-left: 27%;
  }
}

.FeaturedList-figure {
  position: relative;
  padding-bottom: 3/4 * 100%;
  overflow: hidden;
  background-color: $color-placeholder;
  overflow: hidden;
}

.FeaturedList-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.FeaturedList-image {
  @include lazyloaded();
  @include hover-zoom(false);
  background: center/cover no-repeat;
}
