.MenuFull {
  min-height: 100vh;
  display: flex;
  @include breakpoint-menu-down() {
    display: none;
  }
}

.MenuFull-1stCol {
  flex: 0 0 35%;
  width: 35vw;
  display: flex;
  flex-direction: column;
}

.MenuFull-1stLevel {
  margin: auto 0;
  ul {
    @include res-prop("padding-top", $site-header-heights, 0.5);
    // @include res-prop("padding-bottom", $site-header-heights, 0.5);
    padding-left: 0;
    list-style: none;
    margin: 0;
    font-family: $font-family-serif;
    font-weight: 700;
    font-size: rem(33px);
    line-height: 1;
  }
  li:first-child {
    display: none;
  }
  li {
    opacity: 0;
    transform: translateY(1rem);
    transition: transform 0.3s 0.3s, opacity 0.3s;
    .show-menu & {
      opacity: 1;
      transform: translateY(0);
      transition-duration: 0.6s;
      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          @include transition-delay($i - 1, 0.5s, 0.05s);
        }
      }
    }
  }
  a {
    display: inline-block;
    padding: 0.2em 0;
    text-decoration: none !important;
    color: currentColor !important;
    transition: opacity 0.6s;
  }
  .has-active a {
    opacity: 0.3;
  }
  .is-hover a,
  .is-hover .is-active a {
    opacity: 0.3;
  }
  .is-active a {
    opacity: 1;
  }
  a:hover {
    opacity: 1 !important;
  }
}

.MenuFull-footer {
  @include res-prop("height", $site-header-heights, 0.75);
  .container {
    display: flex;
    align-items: center;
    > a {
      font-size: rem(22px);
      margin-right: auto;
    }
  }
  a {
    text-decoration: none !important;
    color: currentColor !important;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    font-size: calc(1rem - 2px);
  }
  li.is-active {
    text-decoration: underline;
  }
  li:not(.is-active) {
    color: rgba(#000, 0.6);
  }
  li:not(:first-child) {
    margin-left: 0.5rem;
  }
}

.MenuFull-2ndCol {
  flex: 0 0 65%;
  width: 65vw;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  .swiper-container {
    margin: auto 0;
    width: 100% !important;
  }
  .swiper-slide {
    // width: 100% !important;
    // flex-shrink: 1;
  }
}

.MenuFull-2ndLevel {
  @include res-prop("padding-top", $site-header-heights, 0.5);
  @include res-prop("padding-bottom", $site-header-heights, 0.75);
  margin: auto 0;
  display: block;
  // display: flex;
  // flex-direction: column;
  ul {
    // @include res-prop("padding-top", $site-header-heights, 0.5);
    // @include res-prop("padding-bottom", $site-header-heights, 0.75);
    padding-left: 0;
    list-style: none;
    margin: auto 0;
    text-transform: uppercase;
    font-size: rem(45px);
    line-height: 1;
  }
  li:not(:first-child) {
    margin-top: 0.75em;
  }
  li {
    opacity: 0;
    transform: translateY(1rem);
    transition: transform 0.3s 0.3s, opacity 0.3s;
  }
  a:hover {
    color: currentColor;
    opacity: 1 !important;
  }
  a {
    display: inline-block;
    padding: 0.2em 0;
    text-decoration: none !important;
    color: #fff !important;
    transition: transform 0.6s, opacity 0.6s;
    transform-origin: 0 100%;
    transform: translateX(0.001em) skew(-0.01deg);
  }
  .swiper-slide {
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .has-active a {
    opacity: 0.3;
  }
  .is-hover a,
  .is-hover .is-active a {
    opacity: 0.3;
    transform: translateX(0.001em) skew(-0.01deg);
  }
  .is-active a {
    opacity: 1;
    transform: translateX(0.001em) skew(-10deg);
  }
  a:hover {
    transform: translateX(0.001em) skew(-10deg) !important;
    opacity: 1 !important;
  }
  .show-menu & .is-ready .swiper-slide-active li {
    opacity: 1;
    transform: translateY(0);
    transition-duration: 0.6s;
    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        @include transition-delay($i - 1, 0.05s, 0s);
      }
    }
  }
}

.MenuFull-media,
.MenuFull-media:after,
.MenuFull-media .swiper-container,
.MenuFull-media .swiper-wrapper,
.MenuFull-media .swiper-slide,
.MenuFull-image,
.MenuFull-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.MenuFull-media {
  transform: translateY(-100%);
  transition: transform 0.6s cubic-bezier("easeInOutQuart");
  overflow: hidden;
  .show-menu & {
    transform: translateY(0);
    transition-duration: 1s;
  }
}

.MenuFull-media .swiper-container {
  transform: translateY(100%);
  transition: transform 0.6s cubic-bezier("easeInOutQuart");
  .show-menu & {
    transform: translateY(0);
    transition-duration: 1s;
  }
}

.MenuFull-media:after {
  content: '';
  background-color: rgba(#000, 0.35);
  z-index: 2;
}

.MenuFull-media .swiper-slide {
  &.swiper-slide-active {
    z-index: 100;
  }
}

.MenuFull-video video {
  position: absolute;
}

.MenuFull-media {
  background-color: #000;
  // clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  // transition: clip-path 0.6s cubic-bezier("easeInOutQuart");
  // .show-menu & {
  //   clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  //   // transition-delay: 0.2s;
  //   transition-duration: 1s;
  // }
}

.MenuFull-image {
  background: center/cover no-repeat;
}

.MenuFull-video video,
.MenuFull-image {
  opacity: 0;
  transition: opacity 0.6s;
  .swiper-slide-active &.lazyloaded {
    opacity: 1;
  }
}

.MenuFull-video.lazyloaded + .MenuFull-image {
  opacity: 0 !important;
}

.MenuFull-footer > * {
  opacity: 0;
  transform: translateY(1rem);
  transition: transform 0.1s 0.6s, opacity 0.6s;
  .show-menu & {
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.6s 1s, opacity 0.6s 1s;
    &:last-child {
      transition: transform 0.6s 1.1s, opacity 0.6s 1.1s;
    }
  }
}
