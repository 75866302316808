.Icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  fill: none;
  stroke: currentColor;
  stroke-width: $icon-stroke-width;
  width: 1rem;
  height: 1rem;
  // stroke-linecap: round;
  // stroke-linejoin: round;
}

.Icon-svg {
  width: 100%;
  height: 100%;
}

.Icon--chevron-down {
  width: rem(14px);
  height: rem(8px);
}

.Icon--play {
  fill: currentColor;
  stroke: none;
}
