.TextIntro-row {
  @include media-breakpoint-up(sm) {
    justify-content: center;
  }
}

.TextIntro-col {
  @include media-breakpoint-up(sm) {
    @include make-col(10);
  }
  @include media-breakpoint-up(md) {
    @include make-col(8);
  }
}

.TextIntro-title {
  @include reveal('.TextIntro-col');
  @include ms-respond(font-size, 26.7); // 38px
  margin: 0;
  font-weight: 400;
  line-height: 1.3;
}

.TextIntro-caption {
  @include reveal('.TextIntro-col');
  @include font-size-small();
  vertical-align: middle;
  display: inline-block;
  padding-bottom: 0.25em;
  padding-right: 1.5em;
  @include reveal-from('.TextIntro-col') {
    transform: translateX(-1.5rem);
  };
  @include reveal-to('.TextIntro-col') {
    transform: none;
  };
}
