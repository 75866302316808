.CardSlider {
  overflow: hidden;

  .swiper-container {
    overflow: visible;
  }

  .swiper-slide {
    --width: 90%;
    --padding-right: #{map_get($grid-container-paddings, xs)};

    box-sizing: content-box;
    width: var(--width);
    padding-right: var(--padding-right);
    height: auto;
    display: flex;

    @include media-breakpoint-up(sm) {
      --width: calc(45.8% - #{map_get($grid-gutter-widths, sm) * 0.5});
      --padding-right: #{map_get($grid-gutter-widths, sm)};
    }

    @include media-breakpoint-up(md) {
      $w: 5/12 * 100%;

      --width: calc(#{$w} - #{map_get($grid-gutter-widths, sm) * 0.5});
      --padding-right: #{map_get($grid-gutter-widths, sm) * 3};
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.CardSlider-heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 3rem;
}

.CardSlider-cta {
  margin: 0 0 0 1rem;
  flex-shrink: 0;

  a {
    text-decoration: underline;
  }
}

.CardSlider-title {
  @include ms-respond(font-size, 32);
  font-weight: 500;
  margin: 0;
}

.CardSlider-item {
  width: 100%;
  display: flex;
}
