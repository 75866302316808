.Video {
  &.Video--full {
    margin: 0;
  }
  &.Video--right [data-scroll-speed] {
    transform: none !important;
    will-transform: none;
  }
}

.Video-container {
  .Video--full & {
    padding: 0;
  }
}

.Video-col {
  @include reveal();

  .Video--right & {
    @include media-breakpoint-up(sm) {
      @include make-col(10);
      margin-left: auto;
    }
  }
}

.Video-media {
  position: relative;
  padding-bottom: 9/16 * 100%;
  overflow: hidden;
  background-color: $color-placeholder;
}

.Video-figure,
.Video-video,
.Video-image,
.Video-wrapper,
.Video-wrapper:after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.Video-wrapper {
  @include lazyloaded-wrapper();
  @include when-scroll-smooth() {
    top: calc(var(--vh) * -10);
    bottom: calc(var(--vh) * -10);
  }
  &:after {
    content: '';
    background-color: #000;
    opacity: 0.2;
  }
}

.Video-image {
  background: center/cover no-repeat;

  .VideoList & {
    @include lazyloaded(false);
  }
}

.Video-video {
  &:not(.lazyloaded) {
    opacity: 0;
  }
  video {
    position: absolute;
  }
}

.Video-link {
  display: block;
  position: relative;
  &:after {
    color: #fff;
    content: attr(title);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: $small-font-size;
    font-weight: 500;
    text-transform: uppercase;
    z-index: 2;
  }
  &:before {
    color: #fff;
    z-index: 3;
    content: '';
    position: absolute;
    width: 20%;
    height: 0;
    padding-bottom: 20%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.6s cubic-bezier("easeOutQuart");
    border: 1px solid #fff;
    border-radius: 50%;

    @include media-breakpoint-up(sm) {
      width: 10%;
      padding-bottom: 10%;

      .VideoList & {
        width: 20%;
        padding-bottom: 20%;
      }
    }

  }
  .desktop &:hover:before {
    transform: translate(-50%, -50%) scale(1.1);
  }
}

.Video-title {
  margin-top: 1rem;
  @include ms-respond(font-size, 15);
  font-weight: 400;
  margin-bottom: 0.5rem;

  a {
    @include hover-text('&:hover');
    text-decoration: none;
  }
}

.Video-abstract {
  line-height: 1.5;
  color: tint(#000, 60%);
  margin-bottom: 1.5rem;
}
