.bootstrap-select {
  > .dropdown-toggle {
    &:after {
      margin-top: 0; // reset
    }
    &.bs-placeholder {
      color: currentColor !important;
    }
  }
  &:not(.dropdown--flat) > .dropdown-toggle {
    @extend .form-control;
  }


  > select.mobile-device:focus + .dropdown-toggle,
  .dropdown-toggle:focus {
    outline: none !important;
    &.focus-visible {
      @extend .focus-visible;
    }
  }

  .dropdown-menu {
    margin: -1px 0 0;
    border-top-color: $color-text;
  }

  .dropdown-menu.inner {
    // transition: none;
  }

  .dropdown-menu.inner.show {
    // pointer-events: none;
  }
  .dropdown-menu.show .dropdown-menu.inner {
    // pointer-events: auto !important;
  }
}
